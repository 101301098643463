import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import Home from "../views/Home.vue";
import ProductDetail from '../views/ProductDetail.vue'
import OperatorProduct from '../views/OperatorProduct.vue'
/* import ProductForm from '../views/ProductForm' */
import SubmitSuccess from '../views/SubmitSuccess.vue'

const routes = [{
        path: "/home",
        name: "Home",
        component: Home,
        meta: { title: "选择你要了解的运营商" },
    },
    {
        path: "/",
        name: "ProductDetail",
        component: ProductDetail,
        meta: { title: "套餐详情" },
    },
    {
        path: "/operatorProduct",
        name: "OperatorProduct",
        component: OperatorProduct,
        meta: { title: "套餐申请" },
    },
    /*     {
            path: "/productForm",
            name: "ProductForm",
            component: ProductForm,
            meta: { title: "套餐申请" },
        }, */
    {
        path: "/submitSuccess",
        name: "SubmitSuccess",
        component: SubmitSuccess,
        meta: { title: "办理成功" }
    }
];

const router = new VueRouter({
    routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

export default router