<template>
  <div style="background: linear-gradient(180deg, #e7f3f7, #f5fafc, #f5fafc, #f5fafc);height: 100vh;">
    <svg class="icon">
      <use xlink:href="#icon-success"/>
    </svg>
    <div class="word">物流到家敬请期待...</div>
    <router-link to="/">
      <van-button class="button" type="info" block>返回首页</van-button>
    </router-link>
  </div>
</template>
<script>
import '../icons/success.svg'

export default {
  components: {},
  data() {
    return {}
  },
  methods: {},
  activated: {},
  deactivated: {}
}
</script>
<style lang="less" scoped>
.icon {
  position: relative;
  left: 50%;
  top: 10%;
  transform: translate(-50%, 0);
}

.word {
  font-size: 16px;
  top: 15%;
  position: relative;
  display: flex;
  justify-content: center;
  transform: translate(0, -50%);
}

.button {
  width: 80%;
  top: 35%;
  left: 50%;
  justify-content: center;
  transform: translate(-50%, -50%);
}
</style>
