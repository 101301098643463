<template>
  <div class="more">
    <div class="topImg">
      <img class="img" :src="productData.fullImgUrl" />
    </div>
    <div class="productFormContainer" style="background-size: 100%;">
      <ProductForm></ProductForm>
    </div>
    <div style="font-size: 0%">
      <img class="img" :src="productData.fullDescImgUrl" />
    </div>
  </div>
</template>


<script>
import getBase64Params from '../utils/getBase64Params.js'
import ProductForm from './ProductForm/index.vue'

export default {
  data() {
    return {
      productData: {},
      noticeShow: false,
    };
  },
  components: {
    ProductForm
  },
  mounted() {
    this.$axios.get('/api/product/personalProductInfo', {
      params: {
        userId: getBase64Params(this.$route.query.data).userId,
        productId: getBase64Params(this.$route.query.data).productId
      }
    }).then((res) => {
      this.productData = res.data.data
      document.querySelector('.productFormContainer').style.backgroundImage = "url(" + this.productData.fullBackgroundImgUrl + ")"
    })
  }
};
</script>

<style lang="less" scoped>
.productFormContainer {
  padding: 20px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 120px;
  height: 120px;
  background-color: #fff;
}

.topImg {
  overflow: hidden;
  font-size: 0;
  width: 100%;
}

.img {
  width: 100%;
  margin: 0;
}

.button {
  position: fixed;
  bottom: 10px;
  width: 95%;
  left: 50%;
  transform: translateX(-50%);
}

@media screen and (min-width: 992px) {
  .button {
    width: 480px;
  }
}
</style>