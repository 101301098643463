<template>
    <div>
        <van-field name="checkbox" :rules="[{ required: true, message: '请阅读后提交' }]">
            <template #input>
                <van-checkbox v-model="checkbox" shape="square">
                    我已阅读并同意
                    <span v-for="item in markdownArrar" :key="item.name" style="color: chocolate;" @click.stop="(() => {
                        markdownText = item.markdownText
                        markdownName = item.name
                        isReadmePopupShow = true
                    })">{{ item.name }}</span>
                </van-checkbox>
            </template>
        </van-field>
        <van-popup class="readmePopup" style="width: 80vw; border-radius: 10px;height: 60vh;padding: 20px 0;"
            v-model="isReadmePopupShow">
            <div style="position: relative;">
                <div
                    style="height: 8%; position: sticky; top: 0; left: 0; width: 100%; background: #fff; z-index: 100; display: flex;align-items: center;">
                    <van-icon @click.stop="(() => isReadmePopupShow = false)" name="close" size="28"
                        style="position: absolute; right: 5px;" />
                </div>
                <div style="height: 89%; padding: 0 5% 5% 5% ; box-sizing: border-box; position: relative;"
                    v-html="markdownText"></div>
                <img v-show="markdownName == '《重要提示》'" class="img" :src="policePhoto" />
            </div>
            <div style="clear: both; display: block;"></div>
        </van-popup>
    </div>
</template>

<script>
import policePhoto from '../img/police.jpg'
import moblieMustRead from '../assets/data/ChinaMoblieMustRead.md'
import ChinaUnicomCustomerAccessServiceAgreement from '../assets/data/ChinaUnicomCustomerAccessServiceAgreement.md'
import ChinaUnicomResponsibilityNotice from '../assets/data/ChinaUnicomResponsibilityNotice.md'
import ChinaUnicomUserPrivacyPolicy from '../assets/data/ChinaUnicomUserPrivacyPolicy.md'
import ChinaMobliePersonal from '../assets/data/ChinaMobileProtectionStatement.md'
import getBase64Params from '../utils/getBase64Params';
export default {
    data() {
        return {
            markdownName: '',
            markdownText: ChinaUnicomCustomerAccessServiceAgreement,
            policePhoto: policePhoto,
            checkbox: false,
            isReadmePopupShow: false,
            markdownArrar: {
                1: [
                    { name: '《重要提示》', markdownText: moblieMustRead },
                    { name: '《个人信息授权及保护声明》', markdownText: ChinaMobliePersonal },
                ], 3: [
                    { name: '《重要提示》', markdownText: null },
                    { name: '《中国联通用户隐私政策》', markdownText: ChinaUnicomUserPrivacyPolicy },
                    { name: '《中国联通客户入网服务协议》', markdownText: ChinaUnicomCustomerAccessServiceAgreement },
                    { name: '《申请使用通信服务涉实名登记及电信网络新型违法犯罪的防范提示及责任告知书》', markdownText: ChinaUnicomResponsibilityNotice }
                ]
            }[getBase64Params(this.$route.query.data).type]
        }
    },
    methods: {
    },
    watch: {
        checkbox(val) {
            this.$emit("handleCheckBoxStatus", val)
        },
        isReadmePopupShow(val) {
            if (val) {
                this.$nextTick(function () {
                    document.querySelector('.readmePopup').scrollTo({
                        top: 0
                    })
                });
            }
        }
    }
}
</script>

<style scoped>
.img {
    width: 100%;
    margin: 0;
}
</style>