<template>
  <div class="home">
    <svg class="backgroundIcon">
      <use xlink:href="#icon-spaceMan" />
    </svg>
    <div class="brandIcon">
      <svg class="icon">
        <use xlink:href="#icon-1" />
      </svg>
      <!-- <svg class="icon">
        <use xlink:href="#icon-2" />
      </svg> -->
      <svg class="icon">
        <use xlink:href="#icon-3" />
      </svg>
    </div>
    <img class="topBanner" src="../img/xuezi.png" />
    <LoadingVue v-show="isLoading" />
    <div v-show="!isLoading">
      <van-button class="button" v-for="item in operatorInfo" :key="item.id" block size="large"
        @click="item.event(item.id)" color="linear-gradient(to right, #0092FF, #00E8FF)">{{ item.name }}</van-button>
    </div>
    <div class="icpUrl">
      <a style="color: #acacac;" target="_blank" href="https://beian.miit.gov.cn">粤ICP备2021179892号</a>
    </div>
  </div>
</template>

<script>
import LoadingVue from '../components/Loading.vue';
import getBase64Params from '../utils/getBase64Params.js'
import { Notify } from 'vant';
import "../icons/spaceMan.svg";
import "../icons/1.svg";
import "../icons/2.svg";
import "../icons/3.svg";
export default {
  name: "Home",
  data() {
    return {
      isLoading: true,
      operatorInfo:{}
    }
  },
  components: {
    LoadingVue
  },
  mounted() {
    this.preload()
    this.$axios.get('/api/user/userInfo', {
      params: {
        userId: getBase64Params(this.$route.query.data).userId
      }
    }).then((res) => {
      this.userInfo = res.data.data
      this.operatorInfo = res.data.data.authList.map((item) => {
        let obj = {
          "移动": {
            id: 1,
            name: "中国移动",
            event: this.routerOperatorProduct
          },
          "电信": {
            id: 2,
            name: "中国电信",
            event: this.routerOperatorProductTelecom
          },
          "联通": {
            id: 3,
            name: "中国联通",
            event: this.routerOperatorProduct
          }
        }
        return obj[item]
      })
      this.isLoading = false
    })
  },
  methods: {
    preload() {
      let img = '../img/xuezi.png'
      let image = new Image()
      image.src = img
    },
    routerOperatorProduct(e) {
      this.$router.push({
        name: "OperatorProduct",
        params: {
          type: e,
        },
      });
    },
    routerOperatorProductTelecom() {
      if (this.userInfo.ctccAgentUrl) {
        window.location.href = this.userInfo.ctccAgentUrl;
      } else {
        Notify('暂未开启');
      }
    }
  },
};
</script>

<style lang="less" scoped>
.topBanner {
  position: relative;
  left: 50%;
  width: 90%;
  transform: translate(-50%, 0);
  margin-bottom: 50px;
}

.home {
  position: absolute;
  top: 0;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background: linear-gradient(180deg, #e7f3f7, #f5fafc, #f5fafc, #f5fafc);
  width: 100%;
  height: 100vh;
}

.button {
  width: 80%;
  margin: 0 auto 40px auto;
  box-shadow: 15px 10px 35px -15px #00e8ff;
  font-weight: 550;
  font-size: 18px;
}

.logo {
  width: 30%;
  height: auto;
}

.brandIcon {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.icon {
  width: 30%;
}

.backgroundIcon {
  bottom: 40px;
  right: 0;
  position: absolute;
  height: auto;
  transform: scale(100%, 100%);
}

.icpUrl {
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #acacac;
}
</style>
