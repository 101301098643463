export default [
	{
		"id": "110000",
		"text": "北京",
		"children": [
			{
				"text": "北京市",
				"id": "110100",
				"children": [
					{
						"text": "东城区",
						"id": "110101"
					},
					{
						"text": "西城区",
						"id": "110102"
					},
					{
						"text": "朝阳区",
						"id": "110105"
					},
					{
						"text": "丰台区",
						"id": "110106"
					},
					{
						"text": "石景山区",
						"id": "110107"
					},
					{
						"text": "海淀区",
						"id": "110108"
					},
					{
						"text": "门头沟区",
						"id": "110109"
					},
					{
						"text": "房山区",
						"id": "110111"
					},
					{
						"text": "通州区",
						"id": "110112"
					},
					{
						"text": "顺义区",
						"id": "110113"
					},
					{
						"text": "昌平区",
						"id": "110114"
					},
					{
						"text": "大兴区",
						"id": "110115"
					},
					{
						"text": "怀柔区",
						"id": "110116"
					},
					{
						"text": "平谷区",
						"id": "110117"
					},
					{
						"text": "密云县",
						"id": "110228"
					},
					{
						"text": "延庆县",
						"id": "110229"
					}
				]
			}
		]
	},
	{
		"id": "120000",
		"text": "天津",
		"children": [
			{
				"text": "天津市",
				"id": "120100",
				"children": [
					{
						"text": "和平区",
						"id": "120101"
					},
					{
						"text": "河东区",
						"id": "120102"
					},
					{
						"text": "河西区",
						"id": "120103"
					},
					{
						"text": "南开区",
						"id": "120104"
					},
					{
						"text": "河北区",
						"id": "120105"
					},
					{
						"text": "红桥区",
						"id": "120106"
					},
					{
						"text": "东丽区",
						"id": "120110"
					},
					{
						"text": "西青区",
						"id": "120111"
					},
					{
						"text": "津南区",
						"id": "120112"
					},
					{
						"text": "北辰区",
						"id": "120113"
					},
					{
						"text": "武清区",
						"id": "120114"
					},
					{
						"text": "宝坻区",
						"id": "120115"
					},
					{
						"text": "汉沽区",
						"id": "120108"
					},
					{
						"text": "塘沽区",
						"id": "120107"
					},
					{
						"text": "大港区",
						"id": "120109"
					},
					{
						"text": "宁河县",
						"id": "120221"
					},
					{
						"text": "静海县",
						"id": "120223"
					},
					{
						"text": "蓟县",
						"id": "120225"
					}
				]
			}
		]
	},
	{
		"id": "130000",
		"text": "河北",
		"children": [
			{
				"text": "石家庄市",
				"id": "130100",
				"children": [
					{
						"text": "长安区",
						"id": "130102"
					},
					{
						"text": "桥西区",
						"id": "130104"
					},
					{
						"text": "新华区",
						"id": "130105"
					},
					{
						"text": "井陉矿区",
						"id": "130107"
					},
					{
						"text": "裕华区",
						"id": "130108"
					},
					{
						"text": "井陉县",
						"id": "130121"
					},
					{
						"text": "正定县",
						"id": "130123"
					},
					{
						"text": "栾城县",
						"id": "130124"
					},
					{
						"text": "行唐县",
						"id": "130125"
					},
					{
						"text": "灵寿县",
						"id": "130126"
					},
					{
						"text": "高邑县",
						"id": "130127"
					},
					{
						"text": "深泽县",
						"id": "130128"
					},
					{
						"text": "赞皇县",
						"id": "130129"
					},
					{
						"text": "无极县",
						"id": "130130"
					},
					{
						"text": "平山县",
						"id": "130131"
					},
					{
						"text": "开发区",
						"id": "130109"
					},
					{
						"text": "元氏县",
						"id": "130132"
					},
					{
						"text": "赵县",
						"id": "130133"
					},
					{
						"text": "辛集市",
						"id": "130181"
					},
					{
						"text": "藁城市",
						"id": "130182"
					},
					{
						"text": "晋州市",
						"id": "130183"
					},
					{
						"text": "新乐市",
						"id": "130184"
					},
					{
						"text": "鹿泉市",
						"id": "130185"
					}
				]
			},
			{
				"text": "唐山市",
				"id": "130200",
				"children": [
					{
						"text": "路南区",
						"id": "130202"
					},
					{
						"text": "路北区",
						"id": "130203"
					},
					{
						"text": "古冶区",
						"id": "130204"
					},
					{
						"text": "开平区",
						"id": "130205"
					},
					{
						"text": "丰南区",
						"id": "130207"
					},
					{
						"text": "丰润区",
						"id": "130208"
					},
					{
						"text": "滦州市",
						"id": "130223"
					},
					{
						"text": "滦南县",
						"id": "130224"
					},
					{
						"text": "乐亭县",
						"id": "130225"
					},
					{
						"text": "迁西县",
						"id": "130227"
					},
					{
						"text": "玉田县",
						"id": "130229"
					},
					{
						"text": "曹妃甸",
						"id": "130230"
					},
					{
						"text": "遵化市",
						"id": "130281"
					},
					{
						"text": "迁安市",
						"id": "130283"
					},
					{
						"text": "高新技术产业园区",
						"id": "130284"
					}
				]
			},
			{
				"text": "秦皇岛市",
				"id": "130300",
				"children": [
					{
						"text": "海港区",
						"id": "130302"
					},
					{
						"text": "山海关区",
						"id": "130303"
					},
					{
						"text": "北戴河区",
						"id": "130304"
					},
					{
						"text": "青龙满族自治县",
						"id": "130321"
					},
					{
						"text": "昌黎县",
						"id": "130322"
					},
					{
						"text": "抚宁县",
						"id": "130323"
					},
					{
						"text": "卢龙县",
						"id": "130324"
					}
				]
			},
			{
				"text": "邯郸市",
				"id": "130400",
				"children": [
					{
						"text": "邯山区",
						"id": "130402"
					},
					{
						"text": "丛台区",
						"id": "130403"
					},
					{
						"text": "复兴区",
						"id": "130404"
					},
					{
						"text": "峰峰矿区",
						"id": "130406"
					},
					{
						"text": "邯郸县",
						"id": "130421"
					},
					{
						"text": "临漳县",
						"id": "130423"
					},
					{
						"text": "成安县",
						"id": "130424"
					},
					{
						"text": "大名县",
						"id": "130425"
					},
					{
						"text": "涉县",
						"id": "130426"
					},
					{
						"text": "磁县",
						"id": "130427"
					},
					{
						"text": "肥乡县",
						"id": "130428"
					},
					{
						"text": "永年县",
						"id": "130429"
					},
					{
						"text": "邱县",
						"id": "130430"
					},
					{
						"text": "鸡泽县",
						"id": "130431"
					},
					{
						"text": "广平县",
						"id": "130432"
					},
					{
						"text": "馆陶县",
						"id": "130433"
					},
					{
						"text": "魏县",
						"id": "130434"
					},
					{
						"text": "曲周县",
						"id": "130435"
					},
					{
						"text": "武安市",
						"id": "130481"
					}
				]
			},
			{
				"text": "邢台市",
				"id": "130500",
				"children": [
					{
						"text": "桥东区",
						"id": "130502"
					},
					{
						"text": "桥西区",
						"id": "130503"
					},
					{
						"text": "邢台县",
						"id": "130521"
					},
					{
						"text": "临城县",
						"id": "130522"
					},
					{
						"text": "内丘县",
						"id": "130523"
					},
					{
						"text": "柏乡县",
						"id": "130524"
					},
					{
						"text": "隆尧县",
						"id": "130525"
					},
					{
						"text": "任县",
						"id": "130526"
					},
					{
						"text": "南和县",
						"id": "130527"
					},
					{
						"text": "宁晋县",
						"id": "130528"
					},
					{
						"text": "巨鹿县",
						"id": "130529"
					},
					{
						"text": "新河县",
						"id": "130530"
					},
					{
						"text": "广宗县",
						"id": "130531"
					},
					{
						"text": "平乡县",
						"id": "130532"
					},
					{
						"text": "威县",
						"id": "130533"
					},
					{
						"text": "清河县",
						"id": "130534"
					},
					{
						"text": "临西县",
						"id": "130535"
					},
					{
						"text": "南宫市",
						"id": "130581"
					},
					{
						"text": "沙河市",
						"id": "130582"
					}
				]
			},
			{
				"text": "保定市",
				"id": "130600",
				"children": [
					{
						"text": "竞秀区",
						"id": "130602"
					},
					{
						"text": "莲池区",
						"id": "130603"
					},
					{
						"text": "白沟新城",
						"id": "130604"
					},
					{
						"text": "满城区",
						"id": "130621"
					},
					{
						"text": "清苑区",
						"id": "130622"
					},
					{
						"text": "涞水县",
						"id": "130623"
					},
					{
						"text": "阜平县",
						"id": "130624"
					},
					{
						"text": "徐水区",
						"id": "130625"
					},
					{
						"text": "定兴县",
						"id": "130626"
					},
					{
						"text": "唐县",
						"id": "130627"
					},
					{
						"text": "高阳县",
						"id": "130628"
					},
					{
						"text": "涞源县",
						"id": "130630"
					},
					{
						"text": "望都县",
						"id": "130631"
					},
					{
						"text": "易县",
						"id": "130633"
					},
					{
						"text": "曲阳县",
						"id": "130634"
					},
					{
						"text": "蠡县",
						"id": "130635"
					},
					{
						"text": "顺平县",
						"id": "130636"
					},
					{
						"text": "博野县",
						"id": "130637"
					},
					{
						"text": "涿州市",
						"id": "130681"
					},
					{
						"text": "定州市",
						"id": "130682"
					},
					{
						"text": "安国市",
						"id": "130683"
					},
					{
						"text": "高碑店市",
						"id": "130684"
					}
				]
			},
			{
				"text": "张家口市",
				"id": "130700",
				"children": [
					{
						"text": "桥东区",
						"id": "130702"
					},
					{
						"text": "桥西区",
						"id": "130703"
					},
					{
						"text": "宣化区",
						"id": "130705"
					},
					{
						"text": "下花园区",
						"id": "130706"
					},
					{
						"text": "张北县",
						"id": "130722"
					},
					{
						"text": "康保县",
						"id": "130723"
					},
					{
						"text": "沽源县",
						"id": "130724"
					},
					{
						"text": "尚义县",
						"id": "130725"
					},
					{
						"text": "蔚县",
						"id": "130726"
					},
					{
						"text": "阳原县",
						"id": "130727"
					},
					{
						"text": "怀安县",
						"id": "130728"
					},
					{
						"text": "万全县",
						"id": "130729"
					},
					{
						"text": "怀来县",
						"id": "130730"
					},
					{
						"text": "涿鹿县",
						"id": "130731"
					},
					{
						"text": "赤城县",
						"id": "130732"
					},
					{
						"text": "崇礼县",
						"id": "130733"
					}
				]
			},
			{
				"text": "承德市",
				"id": "130800",
				"children": [
					{
						"text": "双桥区",
						"id": "130802"
					},
					{
						"text": "双滦区",
						"id": "130803"
					},
					{
						"text": "鹰手营子矿区",
						"id": "130804"
					},
					{
						"text": "承德县",
						"id": "130821"
					},
					{
						"text": "兴隆县",
						"id": "130822"
					},
					{
						"text": "平泉县",
						"id": "130823"
					},
					{
						"text": "滦平县",
						"id": "130824"
					},
					{
						"text": "隆化县",
						"id": "130825"
					},
					{
						"text": "丰宁满族自治县",
						"id": "130826"
					},
					{
						"text": "宽城满族自治县",
						"id": "130827"
					},
					{
						"text": "围场满族蒙古族自治县",
						"id": "130828"
					}
				]
			},
			{
				"text": "沧州市",
				"id": "130900",
				"children": [
					{
						"text": "新华区",
						"id": "130902"
					},
					{
						"text": "运河区",
						"id": "130903"
					},
					{
						"text": "沧县",
						"id": "130921"
					},
					{
						"text": "青县",
						"id": "130922"
					},
					{
						"text": "东光县",
						"id": "130923"
					},
					{
						"text": "海兴县",
						"id": "130924"
					},
					{
						"text": "盐山县",
						"id": "130925"
					},
					{
						"text": "肃宁县",
						"id": "130926"
					},
					{
						"text": "南皮县",
						"id": "130927"
					},
					{
						"text": "吴桥县",
						"id": "130928"
					},
					{
						"text": "献县",
						"id": "130929"
					},
					{
						"text": "孟村回族自治县",
						"id": "130930"
					},
					{
						"text": "泊头市",
						"id": "130981"
					},
					{
						"text": "任丘市",
						"id": "130982"
					},
					{
						"text": "黄骅市",
						"id": "130983"
					},
					{
						"text": "河间市",
						"id": "130984"
					},
					{
						"text": "渤海新区",
						"id": "130904"
					}
				]
			},
			{
				"text": "廊坊市",
				"id": "131000",
				"children": [
					{
						"text": "安次区",
						"id": "131002"
					},
					{
						"text": "广阳区",
						"id": "131003"
					},
					{
						"text": "固安县",
						"id": "131022"
					},
					{
						"text": "永清县",
						"id": "131023"
					},
					{
						"text": "香河县",
						"id": "131024"
					},
					{
						"text": "大城县",
						"id": "131025"
					},
					{
						"text": "文安县",
						"id": "131026"
					},
					{
						"text": "大厂回族自治县",
						"id": "131028"
					},
					{
						"text": "霸州市",
						"id": "131081"
					},
					{
						"text": "三河市",
						"id": "131082"
					},
					{
						"text": "开发区",
						"id": "131083"
					},
					{
						"text": "燕郊县",
						"id": "131084"
					},
					{
						"text": "胜芳县",
						"id": "131085"
					}
				]
			},
			{
				"text": "衡水市",
				"id": "131100",
				"children": [
					{
						"text": "桃城区",
						"id": "131102"
					},
					{
						"text": "枣强县",
						"id": "131121"
					},
					{
						"text": "武邑县",
						"id": "131122"
					},
					{
						"text": "武强县",
						"id": "131123"
					},
					{
						"text": "饶阳县",
						"id": "131124"
					},
					{
						"text": "安平县",
						"id": "131125"
					},
					{
						"text": "故城县",
						"id": "131126"
					},
					{
						"text": "景县",
						"id": "131127"
					},
					{
						"text": "阜城县",
						"id": "131128"
					},
					{
						"text": "冀州市",
						"id": "131181"
					},
					{
						"text": "深州市",
						"id": "131182"
					}
				]
			},
			{
				"text": "雄安新区",
				"id": "131200",
				"children": [
					{
						"text": "容城县",
						"id": "131201"
					},
					{
						"text": "安新县",
						"id": "131202"
					},
					{
						"text": "雄县",
						"id": "131203"
					}
				]
			}
		]
	},
	{
		"id": "140000",
		"text": "山西",
		"children": [
			{
				"text": "太原市",
				"id": "140100",
				"children": [
					{
						"text": "小店区",
						"id": "140105"
					},
					{
						"text": "迎泽区",
						"id": "140106"
					},
					{
						"text": "杏花岭区",
						"id": "140107"
					},
					{
						"text": "尖草坪区",
						"id": "140108"
					},
					{
						"text": "万柏林区",
						"id": "140109"
					},
					{
						"text": "晋源区",
						"id": "140110"
					},
					{
						"text": "清徐县",
						"id": "140121"
					},
					{
						"text": "阳曲县",
						"id": "140122"
					},
					{
						"text": "娄烦县",
						"id": "140123"
					},
					{
						"text": "古交市",
						"id": "140181"
					}
				]
			},
			{
				"text": "大同市",
				"id": "140200",
				"children": [
					{
						"text": "新荣区",
						"id": "140212"
					},
					{
						"text": "阳高县",
						"id": "140221"
					},
					{
						"text": "天镇县",
						"id": "140222"
					},
					{
						"text": "广灵县",
						"id": "140223"
					},
					{
						"text": "灵丘县",
						"id": "140224"
					},
					{
						"text": "浑源县",
						"id": "140225"
					},
					{
						"text": "左云县",
						"id": "140226"
					},
					{
						"text": "云州区",
						"id": "140227"
					},
					{
						"text": "平城区",
						"id": "140228"
					},
					{
						"text": "开发区",
						"id": "140229"
					},
					{
						"text": "云冈区",
						"id": "140230"
					}
				]
			},
			{
				"text": "阳泉市",
				"id": "140300",
				"children": [
					{
						"text": "城区",
						"id": "140302"
					},
					{
						"text": "矿区",
						"id": "140303"
					},
					{
						"text": "郊区",
						"id": "140311"
					},
					{
						"text": "平定县",
						"id": "140321"
					},
					{
						"text": "盂县",
						"id": "140322"
					}
				]
			},
			{
				"text": "长治市",
				"id": "140400",
				"children": [
					{
						"text": "城区",
						"id": "140402"
					},
					{
						"text": "郊区",
						"id": "140411"
					},
					{
						"text": "长治县",
						"id": "140421"
					},
					{
						"text": "襄垣县",
						"id": "140423"
					},
					{
						"text": "屯留县",
						"id": "140424"
					},
					{
						"text": "平顺县",
						"id": "140425"
					},
					{
						"text": "黎城县",
						"id": "140426"
					},
					{
						"text": "壶关县",
						"id": "140427"
					},
					{
						"text": "长子县",
						"id": "140428"
					},
					{
						"text": "武乡县",
						"id": "140429"
					},
					{
						"text": "沁县",
						"id": "140430"
					},
					{
						"text": "沁源县",
						"id": "140431"
					},
					{
						"text": "潞城市",
						"id": "140481"
					}
				]
			},
			{
				"text": "晋城市",
				"id": "140500",
				"children": [
					{
						"text": "城区",
						"id": "140502"
					},
					{
						"text": "沁水县",
						"id": "140521"
					},
					{
						"text": "阳城县",
						"id": "140522"
					},
					{
						"text": "陵川县",
						"id": "140524"
					},
					{
						"text": "泽州县",
						"id": "140525"
					},
					{
						"text": "高平市",
						"id": "140581"
					}
				]
			},
			{
				"text": "朔州市",
				"id": "140600",
				"children": [
					{
						"text": "朔城区",
						"id": "140602"
					},
					{
						"text": "平鲁区",
						"id": "140603"
					},
					{
						"text": "山阴县",
						"id": "140621"
					},
					{
						"text": "应县",
						"id": "140622"
					},
					{
						"text": "右玉县",
						"id": "140623"
					},
					{
						"text": "怀仁县",
						"id": "140624"
					}
				]
			},
			{
				"text": "晋中市",
				"id": "140700",
				"children": [
					{
						"text": "榆次区",
						"id": "140702"
					},
					{
						"text": "榆社县",
						"id": "140721"
					},
					{
						"text": "左权县",
						"id": "140722"
					},
					{
						"text": "和顺县",
						"id": "140723"
					},
					{
						"text": "昔阳县",
						"id": "140724"
					},
					{
						"text": "寿阳县",
						"id": "140725"
					},
					{
						"text": "太谷县",
						"id": "140726"
					},
					{
						"text": "祁县",
						"id": "140727"
					},
					{
						"text": "平遥县",
						"id": "140728"
					},
					{
						"text": "灵石县",
						"id": "140729"
					},
					{
						"text": "介休市",
						"id": "140781"
					}
				]
			},
			{
				"text": "运城市",
				"id": "140800",
				"children": [
					{
						"text": "盐湖区",
						"id": "140802"
					},
					{
						"text": "临猗县",
						"id": "140821"
					},
					{
						"text": "万荣县",
						"id": "140822"
					},
					{
						"text": "闻喜县",
						"id": "140823"
					},
					{
						"text": "稷山县",
						"id": "140824"
					},
					{
						"text": "新绛县",
						"id": "140825"
					},
					{
						"text": "绛县",
						"id": "140826"
					},
					{
						"text": "垣曲县",
						"id": "140827"
					},
					{
						"text": "夏县",
						"id": "140828"
					},
					{
						"text": "平陆县",
						"id": "140829"
					},
					{
						"text": "芮城县",
						"id": "140830"
					},
					{
						"text": "永济市",
						"id": "140881"
					},
					{
						"text": "河津市",
						"id": "140882"
					}
				]
			},
			{
				"text": "忻州市",
				"id": "140900",
				"children": [
					{
						"text": "忻府区",
						"id": "140902"
					},
					{
						"text": "定襄县",
						"id": "140921"
					},
					{
						"text": "五台县",
						"id": "140922"
					},
					{
						"text": "代县",
						"id": "140923"
					},
					{
						"text": "繁峙县",
						"id": "140924"
					},
					{
						"text": "宁武县",
						"id": "140925"
					},
					{
						"text": "静乐县",
						"id": "140926"
					},
					{
						"text": "神池县",
						"id": "140927"
					},
					{
						"text": "五寨县",
						"id": "140928"
					},
					{
						"text": "岢岚县",
						"id": "140929"
					},
					{
						"text": "河曲县",
						"id": "140930"
					},
					{
						"text": "保德县",
						"id": "140931"
					},
					{
						"text": "偏关县",
						"id": "140932"
					},
					{
						"text": "原平市",
						"id": "140981"
					}
				]
			},
			{
				"text": "临汾市",
				"id": "141000",
				"children": [
					{
						"text": "尧都区",
						"id": "141002"
					},
					{
						"text": "曲沃县",
						"id": "141021"
					},
					{
						"text": "翼城县",
						"id": "141022"
					},
					{
						"text": "襄汾县",
						"id": "141023"
					},
					{
						"text": "洪洞县",
						"id": "141024"
					},
					{
						"text": "古县",
						"id": "141025"
					},
					{
						"text": "安泽县",
						"id": "141026"
					},
					{
						"text": "浮山县",
						"id": "141027"
					},
					{
						"text": "吉县",
						"id": "141028"
					},
					{
						"text": "乡宁县",
						"id": "141029"
					},
					{
						"text": "大宁县",
						"id": "141030"
					},
					{
						"text": "隰县",
						"id": "141031"
					},
					{
						"text": "永和县",
						"id": "141032"
					},
					{
						"text": "蒲县",
						"id": "141033"
					},
					{
						"text": "汾西县",
						"id": "141034"
					},
					{
						"text": "侯马市",
						"id": "141081"
					},
					{
						"text": "霍州市",
						"id": "141082"
					}
				]
			},
			{
				"text": "吕梁市",
				"id": "141100",
				"children": [
					{
						"text": "离石区",
						"id": "141102"
					},
					{
						"text": "文水县",
						"id": "141121"
					},
					{
						"text": "交城县",
						"id": "141122"
					},
					{
						"text": "兴县",
						"id": "141123"
					},
					{
						"text": "临县",
						"id": "141124"
					},
					{
						"text": "柳林县",
						"id": "141125"
					},
					{
						"text": "石楼县",
						"id": "141126"
					},
					{
						"text": "岚县",
						"id": "141127"
					},
					{
						"text": "方山县",
						"id": "141128"
					},
					{
						"text": "中阳县",
						"id": "141129"
					},
					{
						"text": "交口县",
						"id": "141130"
					},
					{
						"text": "孝义市",
						"id": "141181"
					},
					{
						"text": "汾阳市",
						"id": "141182"
					}
				]
			}
		]
	},
	{
		"id": "150000",
		"text": "内蒙古",
		"children": [
			{
				"text": "呼和浩特市",
				"id": "150100",
				"children": [
					{
						"text": "新城区",
						"id": "150102"
					},
					{
						"text": "回民区",
						"id": "150103"
					},
					{
						"text": "玉泉区",
						"id": "150104"
					},
					{
						"text": "赛罕区",
						"id": "150105"
					},
					{
						"text": "土默特左旗",
						"id": "150121"
					},
					{
						"text": "托克托县",
						"id": "150122"
					},
					{
						"text": "和林格尔县",
						"id": "150123"
					},
					{
						"text": "清水河县",
						"id": "150124"
					},
					{
						"text": "武川县",
						"id": "150125"
					}
				]
			},
			{
				"text": "包头市",
				"id": "150200",
				"children": [
					{
						"text": "东河区",
						"id": "150202"
					},
					{
						"text": "昆都仑区",
						"id": "150203"
					},
					{
						"text": "青山区",
						"id": "150204"
					},
					{
						"text": "石拐区",
						"id": "150205"
					},
					{
						"text": "白云鄂博矿区",
						"id": "150206"
					},
					{
						"text": "九原区",
						"id": "150207"
					},
					{
						"text": "土默特右旗",
						"id": "150221"
					},
					{
						"text": "固阳县",
						"id": "150222"
					},
					{
						"text": "达尔罕茂明安联合旗",
						"id": "150223"
					}
				]
			},
			{
				"text": "乌海市",
				"id": "150300",
				"children": [
					{
						"text": "海勃湾区",
						"id": "150302"
					},
					{
						"text": "海南区",
						"id": "150303"
					},
					{
						"text": "乌达区",
						"id": "150304"
					}
				]
			},
			{
				"text": "赤峰市",
				"id": "150400",
				"children": [
					{
						"text": "红山区",
						"id": "150402"
					},
					{
						"text": "元宝山区",
						"id": "150403"
					},
					{
						"text": "松山区",
						"id": "150404"
					},
					{
						"text": "阿鲁科尔沁旗",
						"id": "150421"
					},
					{
						"text": "巴林左旗",
						"id": "150422"
					},
					{
						"text": "巴林右旗",
						"id": "150423"
					},
					{
						"text": "林西县",
						"id": "150424"
					},
					{
						"text": "克什克腾旗",
						"id": "150425"
					},
					{
						"text": "翁牛特旗",
						"id": "150426"
					},
					{
						"text": "喀喇沁旗",
						"id": "150428"
					},
					{
						"text": "宁城县",
						"id": "150429"
					},
					{
						"text": "敖汉旗",
						"id": "150430"
					},
					{
						"text": "平庄",
						"id": "150431"
					},
					{
						"text": "新城区",
						"id": "150432"
					}
				]
			},
			{
				"text": "通辽市",
				"id": "150500",
				"children": [
					{
						"text": "科尔沁区",
						"id": "150502"
					},
					{
						"text": "科尔沁左翼中旗",
						"id": "150521"
					},
					{
						"text": "科尔沁左翼后旗",
						"id": "150522"
					},
					{
						"text": "开鲁县",
						"id": "150523"
					},
					{
						"text": "库伦旗",
						"id": "150524"
					},
					{
						"text": "奈曼旗",
						"id": "150525"
					},
					{
						"text": "扎鲁特旗",
						"id": "150526"
					},
					{
						"text": "霍林郭勒市",
						"id": "150581"
					},
					{
						"text": "开发区",
						"id": "150527"
					}
				]
			},
			{
				"text": "鄂尔多斯市",
				"id": "150600",
				"children": [
					{
						"text": "东胜区",
						"id": "150602"
					},
					{
						"text": "达拉特旗",
						"id": "150621"
					},
					{
						"text": "准格尔旗",
						"id": "150622"
					},
					{
						"text": "鄂托克前旗",
						"id": "150623"
					},
					{
						"text": "鄂托克旗",
						"id": "150624"
					},
					{
						"text": "杭锦旗",
						"id": "150625"
					},
					{
						"text": "乌审旗",
						"id": "150626"
					},
					{
						"text": "伊金霍洛旗",
						"id": "150627"
					},
					{
						"text": "康巴什区",
						"id": "150628"
					}
				]
			},
			{
				"text": "呼伦贝尔市",
				"id": "150700",
				"children": [
					{
						"text": "海拉尔区",
						"id": "150702"
					},
					{
						"text": "阿荣旗",
						"id": "150721"
					},
					{
						"text": "莫力达瓦达斡尔族自治旗",
						"id": "150722"
					},
					{
						"text": "鄂伦春自治旗",
						"id": "150723"
					},
					{
						"text": "鄂温克族自治旗",
						"id": "150724"
					},
					{
						"text": "陈巴尔虎旗",
						"id": "150725"
					},
					{
						"text": "新巴尔虎左旗",
						"id": "150726"
					},
					{
						"text": "新巴尔虎右旗",
						"id": "150727"
					},
					{
						"text": "满洲里市",
						"id": "150781"
					},
					{
						"text": "牙克石市",
						"id": "150782"
					},
					{
						"text": "扎兰屯市",
						"id": "150783"
					},
					{
						"text": "额尔古纳市",
						"id": "150784"
					},
					{
						"text": "根河市",
						"id": "150785"
					},
					{
						"text": "大杨树镇",
						"id": "150786"
					},
					{
						"text": "伊敏镇",
						"id": "150787"
					},
					{
						"text": "大雁镇",
						"id": "150788"
					},
					{
						"text": "扎赉诺尔镇",
						"id": "150789"
					}
				]
			},
			{
				"text": "巴彦淖尔市",
				"id": "150800",
				"children": [
					{
						"text": "临河区",
						"id": "150802"
					},
					{
						"text": "五原县",
						"id": "150821"
					},
					{
						"text": "磴口县",
						"id": "150822"
					},
					{
						"text": "乌拉特前旗",
						"id": "150823"
					},
					{
						"text": "乌拉特中旗",
						"id": "150824"
					},
					{
						"text": "乌拉特后旗",
						"id": "150825"
					},
					{
						"text": "杭锦后旗",
						"id": "150826"
					}
				]
			},
			{
				"text": "乌兰察布市",
				"id": "150900",
				"children": [
					{
						"text": "集宁区",
						"id": "150902"
					},
					{
						"text": "卓资县",
						"id": "150921"
					},
					{
						"text": "化德县",
						"id": "150922"
					},
					{
						"text": "商都县",
						"id": "150923"
					},
					{
						"text": "兴和县",
						"id": "150924"
					},
					{
						"text": "凉城县",
						"id": "150925"
					},
					{
						"text": "察哈尔右翼前旗",
						"id": "150926"
					},
					{
						"text": "察哈尔右翼中旗",
						"id": "150927"
					},
					{
						"text": "察哈尔右翼后旗",
						"id": "150928"
					},
					{
						"text": "四子王旗",
						"id": "150929"
					},
					{
						"text": "丰镇市",
						"id": "150981"
					}
				]
			},
			{
				"text": "兴安盟",
				"id": "152200",
				"children": [
					{
						"text": "乌兰浩特市",
						"id": "152201"
					},
					{
						"text": "阿尔山市",
						"id": "152202"
					},
					{
						"text": "科尔沁右翼前旗",
						"id": "152221"
					},
					{
						"text": "科尔沁右翼中旗",
						"id": "152222"
					},
					{
						"text": "扎赉特旗",
						"id": "152223"
					},
					{
						"text": "突泉县",
						"id": "152224"
					}
				]
			},
			{
				"text": "锡林郭勒盟",
				"id": "152500",
				"children": [
					{
						"text": "二连浩特市",
						"id": "152501"
					},
					{
						"text": "锡林浩特市",
						"id": "152502"
					},
					{
						"text": "阿巴嘎旗",
						"id": "152522"
					},
					{
						"text": "苏尼特左旗",
						"id": "152523"
					},
					{
						"text": "苏尼特右旗",
						"id": "152524"
					},
					{
						"text": "东乌珠穆沁旗",
						"id": "152525"
					},
					{
						"text": "西乌珠穆沁旗",
						"id": "152526"
					},
					{
						"text": "太仆寺旗",
						"id": "152527"
					},
					{
						"text": "镶黄旗",
						"id": "152528"
					},
					{
						"text": "正镶白旗",
						"id": "152529"
					},
					{
						"text": "正蓝旗",
						"id": "152530"
					},
					{
						"text": "多伦县",
						"id": "152531"
					}
				]
			},
			{
				"text": "阿拉善盟",
				"id": "152900",
				"children": [
					{
						"text": "阿拉善左旗",
						"id": "152921"
					},
					{
						"text": "阿拉善右旗",
						"id": "152922"
					},
					{
						"text": "额济纳旗",
						"id": "152923"
					}
				]
			}
		]
	},
	{
		"id": "210000",
		"text": "辽宁",
		"children": [
			{
				"text": "沈阳市",
				"id": "210100",
				"children": [
					{
						"text": "和平区",
						"id": "210102"
					},
					{
						"text": "沈河区",
						"id": "210103"
					},
					{
						"text": "大东区",
						"id": "210104"
					},
					{
						"text": "皇姑区",
						"id": "210105"
					},
					{
						"text": "铁西区",
						"id": "210106"
					},
					{
						"text": "苏家屯区",
						"id": "210111"
					},
					{
						"text": "浑南区",
						"id": "210112"
					},
					{
						"text": "沈北新区",
						"id": "210113"
					},
					{
						"text": "于洪区",
						"id": "210114"
					},
					{
						"text": "辽中县",
						"id": "210122"
					},
					{
						"text": "康平县",
						"id": "210123"
					},
					{
						"text": "法库县",
						"id": "210124"
					},
					{
						"text": "新民市",
						"id": "210181"
					}
				]
			},
			{
				"text": "大连市",
				"id": "210200",
				"children": [
					{
						"text": "中山区",
						"id": "210202"
					},
					{
						"text": "西岗区",
						"id": "210203"
					},
					{
						"text": "沙河口区",
						"id": "210204"
					},
					{
						"text": "甘井子区",
						"id": "210211"
					},
					{
						"text": "旅顺口区",
						"id": "210212"
					},
					{
						"text": "金州区",
						"id": "210213"
					},
					{
						"text": "长海县",
						"id": "210224"
					},
					{
						"text": "瓦房店市",
						"id": "210281"
					},
					{
						"text": "普兰店市",
						"id": "210282"
					},
					{
						"text": "庄河市",
						"id": "210283"
					},
					{
						"text": "开发区",
						"id": "210284"
					}
				]
			},
			{
				"text": "鞍山市",
				"id": "210300",
				"children": [
					{
						"text": "铁东区",
						"id": "210302"
					},
					{
						"text": "铁西区",
						"id": "210303"
					},
					{
						"text": "立山区",
						"id": "210304"
					},
					{
						"text": "千山区",
						"id": "210311"
					},
					{
						"text": "台安县",
						"id": "210321"
					},
					{
						"text": "岫岩满族自治县",
						"id": "210323"
					},
					{
						"text": "海城市",
						"id": "210381"
					},
					{
						"text": "深沟寺",
						"id": "210305"
					},
					{
						"text": "高新区",
						"id": "210306"
					}
				]
			},
			{
				"text": "抚顺市",
				"id": "210400",
				"children": [
					{
						"text": "新抚区",
						"id": "210402"
					},
					{
						"text": "东洲区",
						"id": "210403"
					},
					{
						"text": "望花区",
						"id": "210404"
					},
					{
						"text": "顺城区",
						"id": "210411"
					},
					{
						"text": "抚顺县",
						"id": "210421"
					},
					{
						"text": "新宾满族自治县",
						"id": "210422"
					},
					{
						"text": "清原满族自治县",
						"id": "210423"
					}
				]
			},
			{
				"text": "本溪市",
				"id": "210500",
				"children": [
					{
						"text": "平山区",
						"id": "210502"
					},
					{
						"text": "溪湖区",
						"id": "210503"
					},
					{
						"text": "明山区",
						"id": "210504"
					},
					{
						"text": "南芬区",
						"id": "210505"
					},
					{
						"text": "本溪满族自治县",
						"id": "210521"
					},
					{
						"text": "桓仁满族自治县",
						"id": "210522"
					}
				]
			},
			{
				"text": "丹东市",
				"id": "210600",
				"children": [
					{
						"text": "元宝区",
						"id": "210602"
					},
					{
						"text": "振兴区",
						"id": "210603"
					},
					{
						"text": "振安区",
						"id": "210604"
					},
					{
						"text": "宽甸满族自治县",
						"id": "210624"
					},
					{
						"text": "东港市",
						"id": "210681"
					},
					{
						"text": "凤城市",
						"id": "210682"
					}
				]
			},
			{
				"text": "锦州市",
				"id": "210700",
				"children": [
					{
						"text": "古塔区",
						"id": "210702"
					},
					{
						"text": "凌河区",
						"id": "210703"
					},
					{
						"text": "太和区",
						"id": "210711"
					},
					{
						"text": "黑山县",
						"id": "210726"
					},
					{
						"text": "义县",
						"id": "210727"
					},
					{
						"text": "凌海市",
						"id": "210781"
					},
					{
						"text": "北镇市",
						"id": "210782"
					},
					{
						"text": "开发区",
						"id": "210704"
					},
					{
						"text": "松山新区",
						"id": "210705"
					}
				]
			},
			{
				"text": "营口市",
				"id": "210800",
				"children": [
					{
						"text": "站前区",
						"id": "210802"
					},
					{
						"text": "西市区",
						"id": "210803"
					},
					{
						"text": "鲅鱼圈区",
						"id": "210804"
					},
					{
						"text": "老边区",
						"id": "210811"
					},
					{
						"text": "盖州市",
						"id": "210881"
					},
					{
						"text": "大石桥市",
						"id": "210882"
					}
				]
			},
			{
				"text": "阜新市",
				"id": "210900",
				"children": [
					{
						"text": "海州区",
						"id": "210902"
					},
					{
						"text": "新邱区",
						"id": "210903"
					},
					{
						"text": "太平区",
						"id": "210904"
					},
					{
						"text": "清河门区",
						"id": "210905"
					},
					{
						"text": "细河区",
						"id": "210911"
					},
					{
						"text": "阜新蒙古族自治县",
						"id": "210921"
					},
					{
						"text": "彰武县",
						"id": "210922"
					}
				]
			},
			{
				"text": "辽阳市",
				"id": "211000",
				"children": [
					{
						"text": "白塔区",
						"id": "211002"
					},
					{
						"text": "文圣区",
						"id": "211003"
					},
					{
						"text": "宏伟区",
						"id": "211004"
					},
					{
						"text": "弓长岭区",
						"id": "211005"
					},
					{
						"text": "太子河区",
						"id": "211011"
					},
					{
						"text": "辽阳县",
						"id": "211021"
					},
					{
						"text": "灯塔市",
						"id": "211081"
					}
				]
			},
			{
				"text": "盘锦市",
				"id": "211100",
				"children": [
					{
						"text": "双台子区",
						"id": "211102"
					},
					{
						"text": "兴隆台区",
						"id": "211103"
					},
					{
						"text": "大洼县",
						"id": "211121"
					},
					{
						"text": "盘山县",
						"id": "211122"
					}
				]
			},
			{
				"text": "铁岭市",
				"id": "211200",
				"children": [
					{
						"text": "银州区",
						"id": "211202"
					},
					{
						"text": "清河区",
						"id": "211204"
					},
					{
						"text": "铁岭县",
						"id": "211221"
					},
					{
						"text": "西丰县",
						"id": "211223"
					},
					{
						"text": "昌图县",
						"id": "211224"
					},
					{
						"text": "调兵山市",
						"id": "211281"
					},
					{
						"text": "开原市",
						"id": "211282"
					}
				]
			},
			{
				"text": "朝阳市",
				"id": "211300",
				"children": [
					{
						"text": "双塔区",
						"id": "211302"
					},
					{
						"text": "龙城区",
						"id": "211303"
					},
					{
						"text": "朝阳县",
						"id": "211321"
					},
					{
						"text": "建平县",
						"id": "211322"
					},
					{
						"text": "喀喇沁左翼蒙古族自治县",
						"id": "211324"
					},
					{
						"text": "北票市",
						"id": "211381"
					},
					{
						"text": "凌源市",
						"id": "211382"
					}
				]
			},
			{
				"text": "葫芦岛市",
				"id": "211400",
				"children": [
					{
						"text": "连山区",
						"id": "211402"
					},
					{
						"text": "龙港区",
						"id": "211403"
					},
					{
						"text": "南票区",
						"id": "211404"
					},
					{
						"text": "绥中县",
						"id": "211421"
					},
					{
						"text": "建昌县",
						"id": "211422"
					},
					{
						"text": "兴城市",
						"id": "211481"
					}
				]
			}
		]
	},
	{
		"id": "220000",
		"text": "吉林",
		"children": [
			{
				"text": "长春市",
				"id": "220100",
				"children": [
					{
						"text": "南关区",
						"id": "220102"
					},
					{
						"text": "宽城区",
						"id": "220103"
					},
					{
						"text": "朝阳区",
						"id": "220104"
					},
					{
						"text": "二道区",
						"id": "220105"
					},
					{
						"text": "绿园区",
						"id": "220106"
					},
					{
						"text": "双阳区",
						"id": "220112"
					},
					{
						"text": "农安县",
						"id": "220122"
					},
					{
						"text": "九台市",
						"id": "220181"
					},
					{
						"text": "榆树市",
						"id": "220182"
					},
					{
						"text": "德惠市",
						"id": "220183"
					},
					{
						"text": "长春汽车产业开发区",
						"id": "220184"
					},
					{
						"text": "高新技术产业开发区",
						"id": "220185"
					},
					{
						"text": "经济技术产业开发区",
						"id": "220186"
					},
					{
						"text": "净月高新技术产业开发区",
						"id": "220187"
					}
				]
			},
			{
				"text": "吉林市",
				"id": "220200",
				"children": [
					{
						"text": "昌邑区",
						"id": "220202"
					},
					{
						"text": "龙潭区",
						"id": "220203"
					},
					{
						"text": "船营区",
						"id": "220204"
					},
					{
						"text": "丰满区",
						"id": "220211"
					},
					{
						"text": "永吉县",
						"id": "220221"
					},
					{
						"text": "蛟河市",
						"id": "220281"
					},
					{
						"text": "桦甸市",
						"id": "220282"
					},
					{
						"text": "舒兰市",
						"id": "220283"
					},
					{
						"text": "磐石市",
						"id": "220284"
					}
				]
			},
			{
				"text": "四平市",
				"id": "220300",
				"children": [
					{
						"text": "铁西区",
						"id": "220302"
					},
					{
						"text": "铁东区",
						"id": "220303"
					},
					{
						"text": "梨树县",
						"id": "220322"
					},
					{
						"text": "伊通满族自治县",
						"id": "220323"
					},
					{
						"text": "公主岭市",
						"id": "220381"
					},
					{
						"text": "双辽市",
						"id": "220382"
					}
				]
			},
			{
				"text": "辽源市",
				"id": "220400",
				"children": [
					{
						"text": "龙山区",
						"id": "220402"
					},
					{
						"text": "西安区",
						"id": "220403"
					},
					{
						"text": "东丰县",
						"id": "220421"
					},
					{
						"text": "东辽县",
						"id": "220422"
					}
				]
			},
			{
				"text": "通化市",
				"id": "220500",
				"children": [
					{
						"text": "通化市内",
						"id": "220502"
					},
					{
						"text": "二道江区",
						"id": "220503"
					},
					{
						"text": "通化县",
						"id": "220521"
					},
					{
						"text": "辉南县",
						"id": "220523"
					},
					{
						"text": "柳河县",
						"id": "220524"
					},
					{
						"text": "梅河口市",
						"id": "220581"
					},
					{
						"text": "集安市",
						"id": "220582"
					}
				]
			},
			{
				"text": "白山市",
				"id": "220600",
				"children": [
					{
						"text": "八道江区",
						"id": "220602"
					},
					{
						"text": "江源区",
						"id": "220605"
					},
					{
						"text": "抚松县",
						"id": "220621"
					},
					{
						"text": "靖宇县",
						"id": "220622"
					},
					{
						"text": "长白朝鲜族自治县",
						"id": "220623"
					},
					{
						"text": "临江市",
						"id": "220681"
					}
				]
			},
			{
				"text": "松原市",
				"id": "220700",
				"children": [
					{
						"text": "宁江区",
						"id": "220702"
					},
					{
						"text": "前郭尔罗斯蒙古族自治县",
						"id": "220721"
					},
					{
						"text": "长岭县",
						"id": "220722"
					},
					{
						"text": "乾安县",
						"id": "220723"
					},
					{
						"text": "扶余县",
						"id": "220724"
					}
				]
			},
			{
				"text": "白城市",
				"id": "220800",
				"children": [
					{
						"text": "洮北区",
						"id": "220802"
					},
					{
						"text": "镇赉县",
						"id": "220821"
					},
					{
						"text": "通榆县",
						"id": "220822"
					},
					{
						"text": "洮南市",
						"id": "220881"
					},
					{
						"text": "大安市",
						"id": "220882"
					}
				]
			},
			{
				"text": "延边朝鲜族自治州",
				"id": "222400",
				"children": [
					{
						"text": "延吉市",
						"id": "222401"
					},
					{
						"text": "图们市",
						"id": "222402"
					},
					{
						"text": "敦化市",
						"id": "222403"
					},
					{
						"text": "珲春市",
						"id": "222404"
					},
					{
						"text": "龙井市",
						"id": "222405"
					},
					{
						"text": "和龙市",
						"id": "222406"
					},
					{
						"text": "汪清县",
						"id": "222424"
					},
					{
						"text": "安图县",
						"id": "222426"
					}
				]
			}
		]
	},
	{
		"id": "230000",
		"text": "黑龙江",
		"children": [
			{
				"text": "哈尔滨市",
				"id": "230100",
				"children": [
					{
						"text": "道里区",
						"id": "230102"
					},
					{
						"text": "南岗区",
						"id": "230103"
					},
					{
						"text": "道外区",
						"id": "230104"
					},
					{
						"text": "平房区",
						"id": "230108"
					},
					{
						"text": "松北区",
						"id": "230109"
					},
					{
						"text": "香坊区",
						"id": "230110"
					},
					{
						"text": "呼兰区",
						"id": "230111"
					},
					{
						"text": "阿城区",
						"id": "230112"
					},
					{
						"text": "依兰县",
						"id": "230123"
					},
					{
						"text": "方正县",
						"id": "230124"
					},
					{
						"text": "宾县",
						"id": "230125"
					},
					{
						"text": "巴彦县",
						"id": "230126"
					},
					{
						"text": "木兰县",
						"id": "230127"
					},
					{
						"text": "通河县",
						"id": "230128"
					},
					{
						"text": "延寿县",
						"id": "230129"
					},
					{
						"text": "双城市",
						"id": "230182"
					},
					{
						"text": "尚志市",
						"id": "230183"
					},
					{
						"text": "五常市",
						"id": "230184"
					}
				]
			},
			{
				"text": "齐齐哈尔市",
				"id": "230200",
				"children": [
					{
						"text": "龙沙区",
						"id": "230202"
					},
					{
						"text": "建华区",
						"id": "230203"
					},
					{
						"text": "铁锋区",
						"id": "230204"
					},
					{
						"text": "昂昂溪区",
						"id": "230205"
					},
					{
						"text": "富拉尔基区",
						"id": "230206"
					},
					{
						"text": "碾子山区",
						"id": "230207"
					},
					{
						"text": "梅里斯达斡尔族区",
						"id": "230208"
					},
					{
						"text": "龙江县",
						"id": "230221"
					},
					{
						"text": "依安县",
						"id": "230223"
					},
					{
						"text": "泰来县",
						"id": "230224"
					},
					{
						"text": "甘南县",
						"id": "230225"
					},
					{
						"text": "富裕县",
						"id": "230227"
					},
					{
						"text": "克山县",
						"id": "230229"
					},
					{
						"text": "克东县",
						"id": "230230"
					},
					{
						"text": "拜泉县",
						"id": "230231"
					},
					{
						"text": "讷河市",
						"id": "230281"
					}
				]
			},
			{
				"text": "鸡西市",
				"id": "230300",
				"children": [
					{
						"text": "鸡冠区",
						"id": "230302"
					},
					{
						"text": "恒山区",
						"id": "230303"
					},
					{
						"text": "滴道区",
						"id": "230304"
					},
					{
						"text": "梨树区",
						"id": "230305"
					},
					{
						"text": "城子河区",
						"id": "230306"
					},
					{
						"text": "麻山区",
						"id": "230307"
					},
					{
						"text": "鸡东县",
						"id": "230321"
					},
					{
						"text": "虎林市",
						"id": "230381"
					},
					{
						"text": "密山市",
						"id": "230382"
					}
				]
			},
			{
				"text": "鹤岗市",
				"id": "230400",
				"children": [
					{
						"text": "向阳区",
						"id": "230402"
					},
					{
						"text": "工农区",
						"id": "230403"
					},
					{
						"text": "南山区",
						"id": "230404"
					},
					{
						"text": "兴安区",
						"id": "230405"
					},
					{
						"text": "东山区",
						"id": "230406"
					},
					{
						"text": "兴山区",
						"id": "230407"
					},
					{
						"text": "萝北县",
						"id": "230421"
					},
					{
						"text": "绥滨县",
						"id": "230422"
					},
					{
						"text": "宝泉岭",
						"id": "230408"
					}
				]
			},
			{
				"text": "双鸭山市",
				"id": "230500",
				"children": [
					{
						"text": "尖山区",
						"id": "230502"
					},
					{
						"text": "岭东区",
						"id": "230503"
					},
					{
						"text": "四方台区",
						"id": "230505"
					},
					{
						"text": "宝山区",
						"id": "230506"
					},
					{
						"text": "集贤县",
						"id": "230521"
					},
					{
						"text": "友谊县",
						"id": "230522"
					},
					{
						"text": "宝清县",
						"id": "230523"
					},
					{
						"text": "饶河县",
						"id": "230524"
					},
					{
						"text": "红兴隆",
						"id": "230525"
					}
				]
			},
			{
				"text": "大庆市",
				"id": "230600",
				"children": [
					{
						"text": "萨尔图区",
						"id": "230602"
					},
					{
						"text": "龙凤区",
						"id": "230603"
					},
					{
						"text": "让胡路区",
						"id": "230604"
					},
					{
						"text": "红岗区",
						"id": "230605"
					},
					{
						"text": "大同区",
						"id": "230606"
					},
					{
						"text": "肇州县",
						"id": "230621"
					},
					{
						"text": "肇源县",
						"id": "230622"
					},
					{
						"text": "林甸县",
						"id": "230623"
					},
					{
						"text": "杜尔伯特蒙古族自治县",
						"id": "230624"
					}
				]
			},
			{
				"text": "伊春市",
				"id": "230700",
				"children": [
					{
						"text": "伊春区",
						"id": "230702"
					},
					{
						"text": "南岔区",
						"id": "230703"
					},
					{
						"text": "友好区",
						"id": "230704"
					},
					{
						"text": "西林区",
						"id": "230705"
					},
					{
						"text": "翠峦区",
						"id": "230706"
					},
					{
						"text": "新青区",
						"id": "230707"
					},
					{
						"text": "美溪区",
						"id": "230708"
					},
					{
						"text": "金山屯区",
						"id": "230709"
					},
					{
						"text": "五营区",
						"id": "230710"
					},
					{
						"text": "乌马河区",
						"id": "230711"
					},
					{
						"text": "汤旺河区",
						"id": "230712"
					},
					{
						"text": "带岭区",
						"id": "230713"
					},
					{
						"text": "乌伊岭区",
						"id": "230714"
					},
					{
						"text": "红星区",
						"id": "230715"
					},
					{
						"text": "上甘岭区",
						"id": "230716"
					},
					{
						"text": "嘉荫县",
						"id": "230722"
					},
					{
						"text": "铁力市",
						"id": "230781"
					},
					{
						"text": "桃山镇",
						"id": "230717"
					},
					{
						"text": "浩良河镇",
						"id": "230718"
					},
					{
						"text": "朗乡镇",
						"id": "230719"
					},
					{
						"text": "双丰镇",
						"id": "230720"
					}
				]
			},
			{
				"text": "佳木斯市",
				"id": "230800",
				"children": [
					{
						"text": "向阳区",
						"id": "230803"
					},
					{
						"text": "前进区",
						"id": "230804"
					},
					{
						"text": "东风区",
						"id": "230805"
					},
					{
						"text": "郊区",
						"id": "230811"
					},
					{
						"text": "桦南县",
						"id": "230822"
					},
					{
						"text": "桦川县",
						"id": "230826"
					},
					{
						"text": "汤原县",
						"id": "230828"
					},
					{
						"text": "抚远县",
						"id": "230833"
					},
					{
						"text": "同江市",
						"id": "230881"
					},
					{
						"text": "富锦市",
						"id": "230882"
					},
					{
						"text": "建三江市",
						"id": "230884"
					}
				]
			},
			{
				"text": "七台河市",
				"id": "230900",
				"children": [
					{
						"text": "新兴区",
						"id": "230902"
					},
					{
						"text": "桃山区",
						"id": "230903"
					},
					{
						"text": "茄子河区",
						"id": "230904"
					},
					{
						"text": "勃利县",
						"id": "230921"
					}
				]
			},
			{
				"text": "牡丹江市",
				"id": "231000",
				"children": [
					{
						"text": "东安区",
						"id": "231002"
					},
					{
						"text": "阳明区",
						"id": "231003"
					},
					{
						"text": "爱民区",
						"id": "231004"
					},
					{
						"text": "西安区",
						"id": "231005"
					},
					{
						"text": "东宁县",
						"id": "231024"
					},
					{
						"text": "林口县",
						"id": "231025"
					},
					{
						"text": "绥芬河市",
						"id": "231081"
					},
					{
						"text": "海林市",
						"id": "231083"
					},
					{
						"text": "宁安市",
						"id": "231084"
					},
					{
						"text": "穆棱市",
						"id": "231085"
					}
				]
			},
			{
				"text": "黑河市",
				"id": "231100",
				"children": [
					{
						"text": "爱辉区",
						"id": "231102"
					},
					{
						"text": "嫩江县",
						"id": "231121"
					},
					{
						"text": "逊克县",
						"id": "231123"
					},
					{
						"text": "孙吴县",
						"id": "231124"
					},
					{
						"text": "北安市",
						"id": "231181"
					},
					{
						"text": "五大连池市",
						"id": "231182"
					},
					{
						"text": "合作区",
						"id": "231125"
					}
				]
			},
			{
				"text": "绥化市",
				"id": "231200",
				"children": [
					{
						"text": "北林区",
						"id": "231202"
					},
					{
						"text": "望奎县",
						"id": "231221"
					},
					{
						"text": "兰西县",
						"id": "231222"
					},
					{
						"text": "青冈县",
						"id": "231223"
					},
					{
						"text": "庆安县",
						"id": "231224"
					},
					{
						"text": "明水县",
						"id": "231225"
					},
					{
						"text": "绥棱县",
						"id": "231226"
					},
					{
						"text": "安达市",
						"id": "231281"
					},
					{
						"text": "肇东市",
						"id": "231282"
					},
					{
						"text": "海伦市",
						"id": "231283"
					}
				]
			},
			{
				"text": "大兴安岭地区",
				"id": "232700",
				"children": [
					{
						"text": "呼玛县",
						"id": "232721"
					},
					{
						"text": "塔河县",
						"id": "232722"
					},
					{
						"text": "漠河县",
						"id": "232723"
					},
					{
						"text": "加格达奇区",
						"id": "232724"
					},
					{
						"text": "松岭区",
						"id": "232725"
					},
					{
						"text": "新林区",
						"id": "232726"
					},
					{
						"text": "呼中区",
						"id": "232727"
					}
				]
			}
		]
	},
	{
		"id": "310000",
		"text": "上海",
		"children": [
			{
				"text": "上海市",
				"id": "310100",
				"children": [
					{
						"text": "黄浦区",
						"id": "310101"
					},
					{
						"text": "徐汇区",
						"id": "310104"
					},
					{
						"text": "长宁区",
						"id": "310105"
					},
					{
						"text": "静安区",
						"id": "310106"
					},
					{
						"text": "普陀区",
						"id": "310107"
					},
					{
						"text": "虹口区",
						"id": "310109"
					},
					{
						"text": "杨浦区",
						"id": "310110"
					},
					{
						"text": "闵行区",
						"id": "310112"
					},
					{
						"text": "宝山区",
						"id": "310113"
					},
					{
						"text": "嘉定区",
						"id": "310114"
					},
					{
						"text": "浦东新区",
						"id": "310115"
					},
					{
						"text": "金山区",
						"id": "310116"
					},
					{
						"text": "松江区",
						"id": "310117"
					},
					{
						"text": "青浦区",
						"id": "310118"
					},
					{
						"text": "奉贤区",
						"id": "310120"
					},
					{
						"text": "崇明区",
						"id": "310230"
					}
				]
			}
		]
	},
	{
		"id": "320000",
		"text": "江苏",
		"children": [
			{
				"text": "南京市",
				"id": "320100",
				"children": [
					{
						"text": "玄武区",
						"id": "320102"
					},
					{
						"text": "秦淮区",
						"id": "320104"
					},
					{
						"text": "建邺区",
						"id": "320105"
					},
					{
						"text": "鼓楼区",
						"id": "320106"
					},
					{
						"text": "浦口区",
						"id": "320111"
					},
					{
						"text": "栖霞区",
						"id": "320113"
					},
					{
						"text": "雨花台区",
						"id": "320114"
					},
					{
						"text": "江宁区",
						"id": "320115"
					},
					{
						"text": "六合区",
						"id": "320116"
					},
					{
						"text": "溧水区",
						"id": "320124"
					},
					{
						"text": "高淳区",
						"id": "320125"
					}
				]
			},
			{
				"text": "无锡市",
				"id": "320200",
				"children": [
					{
						"text": "新吴区",
						"id": "320214"
					},
					{
						"text": "梁溪区",
						"id": "320213"
					},
					{
						"text": "锡山区",
						"id": "320205"
					},
					{
						"text": "惠山区",
						"id": "320206"
					},
					{
						"text": "滨湖区",
						"id": "320211"
					},
					{
						"text": "江阴市",
						"id": "320281"
					},
					{
						"text": "宜兴市",
						"id": "320282"
					}
				]
			},
			{
				"text": "徐州市",
				"id": "320300",
				"children": [
					{
						"text": "鼓楼区",
						"id": "320302"
					},
					{
						"text": "云龙区",
						"id": "320303"
					},
					{
						"text": "贾汪区",
						"id": "320305"
					},
					{
						"text": "泉山区",
						"id": "320311"
					},
					{
						"text": "铜山区",
						"id": "320312"
					},
					{
						"text": "丰县",
						"id": "320321"
					},
					{
						"text": "沛县",
						"id": "320322"
					},
					{
						"text": "睢宁县",
						"id": "320324"
					},
					{
						"text": "新沂市",
						"id": "320381"
					},
					{
						"text": "邳州市",
						"id": "320382"
					}
				]
			},
			{
				"text": "常州市",
				"id": "320400",
				"children": [
					{
						"text": "天宁区",
						"id": "320402"
					},
					{
						"text": "钟楼区",
						"id": "320404"
					},
					{
						"text": "经开区",
						"id": "320405"
					},
					{
						"text": "新北区",
						"id": "320411"
					},
					{
						"text": "武进区",
						"id": "320412"
					},
					{
						"text": "溧阳市",
						"id": "320481"
					},
					{
						"text": "金坛区",
						"id": "320482"
					}
				]
			},
			{
				"text": "苏州市",
				"id": "320500",
				"children": [
					{
						"text": "姑苏区",
						"id": "320509"
					},
					{
						"text": "虎丘区",
						"id": "320505"
					},
					{
						"text": "吴中区",
						"id": "320506"
					},
					{
						"text": "相城区",
						"id": "320507"
					},
					{
						"text": "工业园区",
						"id": "320508"
					},
					{
						"text": "常熟市",
						"id": "320581"
					},
					{
						"text": "张家港市",
						"id": "320582"
					},
					{
						"text": "昆山市",
						"id": "320583"
					},
					{
						"text": "吴江区",
						"id": "320584"
					},
					{
						"text": "太仓市",
						"id": "320585"
					}
				]
			},
			{
				"text": "南通市",
				"id": "320600",
				"children": [
					{
						"text": "崇川区",
						"id": "320602"
					},
					{
						"text": "港闸区",
						"id": "320611"
					},
					{
						"text": "通州区",
						"id": "320612"
					},
					{
						"text": "海安县",
						"id": "320621"
					},
					{
						"text": "如东县",
						"id": "320623"
					},
					{
						"text": "启东市",
						"id": "320681"
					},
					{
						"text": "如皋市",
						"id": "320682"
					},
					{
						"text": "海门市",
						"id": "320684"
					},
					{
						"text": "开发区",
						"id": "320683"
					}
				]
			},
			{
				"text": "连云港市",
				"id": "320700",
				"children": [
					{
						"text": "连云区",
						"id": "320703"
					},
					{
						"text": "海州区",
						"id": "320706"
					},
					{
						"text": "赣榆区",
						"id": "320721"
					},
					{
						"text": "东海县",
						"id": "320722"
					},
					{
						"text": "灌云县",
						"id": "320723"
					},
					{
						"text": "灌南县",
						"id": "320724"
					}
				]
			},
			{
				"text": "淮安市",
				"id": "320800",
				"children": [
					{
						"text": "清江浦区",
						"id": "320802"
					},
					{
						"text": "淮安区",
						"id": "320803"
					},
					{
						"text": "淮阴区",
						"id": "320804"
					},
					{
						"text": "开发区",
						"id": "320811"
					},
					{
						"text": "涟水县",
						"id": "320826"
					},
					{
						"text": "洪泽区",
						"id": "320829"
					},
					{
						"text": "盱眙县",
						"id": "320830"
					},
					{
						"text": "金湖县",
						"id": "320831"
					}
				]
			},
			{
				"text": "盐城市",
				"id": "320900",
				"children": [
					{
						"text": "亭湖区",
						"id": "320902"
					},
					{
						"text": "盐都区",
						"id": "320903"
					},
					{
						"text": "响水县",
						"id": "320921"
					},
					{
						"text": "滨海县",
						"id": "320922"
					},
					{
						"text": "阜宁县",
						"id": "320923"
					},
					{
						"text": "射阳县",
						"id": "320924"
					},
					{
						"text": "建湖县",
						"id": "320925"
					},
					{
						"text": "东台市",
						"id": "320981"
					},
					{
						"text": "大丰市",
						"id": "320982"
					},
					{
						"text": "城南区",
						"id": "320983"
					},
					{
						"text": "开发区",
						"id": "320984"
					}
				]
			},
			{
				"text": "扬州市",
				"id": "321000",
				"children": [
					{
						"text": "广陵区",
						"id": "321002"
					},
					{
						"text": "邗江区",
						"id": "321003"
					},
					{
						"text": "江都区",
						"id": "321012"
					},
					{
						"text": "宝应县",
						"id": "321023"
					},
					{
						"text": "仪征市",
						"id": "321081"
					},
					{
						"text": "高邮市",
						"id": "321084"
					},
					{
						"text": "经济开发区",
						"id": "321085"
					},
					{
						"text": "生态科技新城",
						"id": "321086"
					},
					{
						"text": "蜀冈-瘦西湖风景名胜区",
						"id": "321087"
					}
				]
			},
			{
				"text": "镇江市",
				"id": "321100",
				"children": [
					{
						"text": "京口区",
						"id": "321102"
					},
					{
						"text": "润州区",
						"id": "321111"
					},
					{
						"text": "丹徒区",
						"id": "321112"
					},
					{
						"text": "丹阳市",
						"id": "321181"
					},
					{
						"text": "扬中市",
						"id": "321182"
					},
					{
						"text": "句容市",
						"id": "321183"
					},
					{
						"text": "大港新区",
						"id": "321184"
					}
				]
			},
			{
				"text": "泰州市",
				"id": "321200",
				"children": [
					{
						"text": "海陵区",
						"id": "321202"
					},
					{
						"text": "高港区",
						"id": "321203"
					},
					{
						"text": "兴化市",
						"id": "321281"
					},
					{
						"text": "靖江市",
						"id": "321282"
					},
					{
						"text": "泰兴市",
						"id": "321283"
					},
					{
						"text": "姜堰市",
						"id": "321284"
					}
				]
			},
			{
				"text": "宿迁市",
				"id": "321300",
				"children": [
					{
						"text": "宿城区",
						"id": "321302"
					},
					{
						"text": "宿豫区",
						"id": "321311"
					},
					{
						"text": "沭阳县",
						"id": "321322"
					},
					{
						"text": "泗阳县",
						"id": "321323"
					},
					{
						"text": "泗洪县",
						"id": "321324"
					}
				]
			}
		]
	},
	{
		"id": "330000",
		"text": "浙江",
		"children": [
			{
				"text": "杭州市",
				"id": "330100",
				"children": [
					{
						"text": "上城区",
						"id": "330102"
					},
					{
						"text": "拱墅区",
						"id": "330105"
					},
					{
						"text": "西湖区",
						"id": "330106"
					},
					{
						"text": "滨江区",
						"id": "330108"
					},
					{
						"text": "萧山区",
						"id": "330109"
					},
					{
						"text": "余杭区",
						"id": "330110"
					},
					{
						"text": "桐庐县",
						"id": "330122"
					},
					{
						"text": "淳安县",
						"id": "330127"
					},
					{
						"text": "建德市",
						"id": "330182"
					},
					{
						"text": "富阳区",
						"id": "330183"
					},
					{
						"text": "临安区",
						"id": "330185"
					},
					{
						"text": "钱塘区",
						"id": "330186"
					},
					{
						"text": "临平区",
						"id": "330111"
					}
				]
			},
			{
				"text": "宁波市",
				"id": "330200",
				"children": [
					{
						"text": "海曙区",
						"id": "330203"
					},
					{
						"text": "江北区",
						"id": "330205"
					},
					{
						"text": "北仑区",
						"id": "330206"
					},
					{
						"text": "镇海区",
						"id": "330211"
					},
					{
						"text": "鄞州区",
						"id": "330212"
					},
					{
						"text": "象山县",
						"id": "330225"
					},
					{
						"text": "宁海县",
						"id": "330226"
					},
					{
						"text": "余姚市",
						"id": "330281"
					},
					{
						"text": "慈溪市",
						"id": "330282"
					},
					{
						"text": "奉化市",
						"id": "330283"
					},
					{
						"text": "高新区",
						"id": "330284"
					},
					{
						"text": "杭州湾",
						"id": "330285"
					}
				]
			},
			{
				"text": "温州市",
				"id": "330300",
				"children": [
					{
						"text": "鹿城区",
						"id": "330302"
					},
					{
						"text": "龙湾区",
						"id": "330303"
					},
					{
						"text": "瓯海区",
						"id": "330304"
					},
					{
						"text": "洞头县",
						"id": "330322"
					},
					{
						"text": "永嘉县",
						"id": "330324"
					},
					{
						"text": "平阳县",
						"id": "330326"
					},
					{
						"text": "苍南县",
						"id": "330327"
					},
					{
						"text": "文成县",
						"id": "330328"
					},
					{
						"text": "泰顺县",
						"id": "330329"
					},
					{
						"text": "瑞安市",
						"id": "330381"
					},
					{
						"text": "乐清市",
						"id": "330382"
					},
					{
						"text": "龙港市",
						"id": "330383"
					}
				]
			},
			{
				"text": "嘉兴市",
				"id": "330400",
				"children": [
					{
						"text": "南湖区",
						"id": "330402"
					},
					{
						"text": "秀洲区",
						"id": "330411"
					},
					{
						"text": "嘉善县",
						"id": "330421"
					},
					{
						"text": "海盐县",
						"id": "330424"
					},
					{
						"text": "海宁市",
						"id": "330481"
					},
					{
						"text": "平湖市",
						"id": "330482"
					},
					{
						"text": "桐乡市",
						"id": "330483"
					},
					{
						"text": "濮院",
						"id": "330484"
					}
				]
			},
			{
				"text": "湖州市",
				"id": "330500",
				"children": [
					{
						"text": "吴兴区",
						"id": "330502"
					},
					{
						"text": "南浔区",
						"id": "330503"
					},
					{
						"text": "德清县",
						"id": "330521"
					},
					{
						"text": "长兴县",
						"id": "330522"
					},
					{
						"text": "安吉县",
						"id": "330523"
					},
					{
						"text": "织里镇",
						"id": "330524"
					},
					{
						"text": "南太湖新区",
						"id": "330525"
					}
				]
			},
			{
				"text": "绍兴市",
				"id": "330600",
				"children": [
					{
						"text": "越城区",
						"id": "330602"
					},
					{
						"text": "柯桥区",
						"id": "330621"
					},
					{
						"text": "新昌县",
						"id": "330624"
					},
					{
						"text": "诸暨市",
						"id": "330681"
					},
					{
						"text": "上虞市",
						"id": "330682"
					},
					{
						"text": "嵊州市",
						"id": "330683"
					}
				]
			},
			{
				"text": "金华市",
				"id": "330700",
				"children": [
					{
						"text": "婺城区",
						"id": "330702"
					},
					{
						"text": "金东区",
						"id": "330703"
					},
					{
						"text": "武义县",
						"id": "330723"
					},
					{
						"text": "浦江县",
						"id": "330726"
					},
					{
						"text": "磐安县",
						"id": "330727"
					},
					{
						"text": "兰溪市",
						"id": "330781"
					},
					{
						"text": "义乌市",
						"id": "330782"
					},
					{
						"text": "东阳市",
						"id": "330783"
					},
					{
						"text": "永康市",
						"id": "330784"
					}
				]
			},
			{
				"text": "衢州市",
				"id": "330800",
				"children": [
					{
						"text": "柯城区",
						"id": "330802"
					},
					{
						"text": "衢江区",
						"id": "330803"
					},
					{
						"text": "常山县",
						"id": "330822"
					},
					{
						"text": "开化县",
						"id": "330824"
					},
					{
						"text": "龙游县",
						"id": "330825"
					},
					{
						"text": "江山市",
						"id": "330881"
					}
				]
			},
			{
				"text": "舟山市",
				"id": "330900",
				"children": [
					{
						"text": "定海区",
						"id": "330902"
					},
					{
						"text": "普陀区",
						"id": "330903"
					},
					{
						"text": "岱山县",
						"id": "330921"
					},
					{
						"text": "嵊泗县",
						"id": "330922"
					},
					{
						"text": "新城",
						"id": "330923"
					}
				]
			},
			{
				"text": "台州市",
				"id": "331000",
				"children": [
					{
						"text": "椒江区",
						"id": "331002"
					},
					{
						"text": "黄岩区",
						"id": "331003"
					},
					{
						"text": "路桥区",
						"id": "331004"
					},
					{
						"text": "玉环县",
						"id": "331021"
					},
					{
						"text": "三门县",
						"id": "331022"
					},
					{
						"text": "天台县",
						"id": "331023"
					},
					{
						"text": "仙居县",
						"id": "331024"
					},
					{
						"text": "温岭市",
						"id": "331081"
					},
					{
						"text": "临海市",
						"id": "331082"
					},
					{
						"text": "台州湾新区",
						"id": "331083"
					}
				]
			},
			{
				"text": "丽水市",
				"id": "331100",
				"children": [
					{
						"text": "莲都区",
						"id": "331102"
					},
					{
						"text": "青田县",
						"id": "331121"
					},
					{
						"text": "缙云县",
						"id": "331122"
					},
					{
						"text": "遂昌县",
						"id": "331123"
					},
					{
						"text": "松阳县",
						"id": "331124"
					},
					{
						"text": "云和县",
						"id": "331125"
					},
					{
						"text": "庆元县",
						"id": "331126"
					},
					{
						"text": "景宁畲族自治县",
						"id": "331127"
					},
					{
						"text": "龙泉市",
						"id": "331181"
					}
				]
			}
		]
	},
	{
		"id": "340000",
		"text": "安徽",
		"children": [
			{
				"text": "合肥市",
				"id": "340100",
				"children": [
					{
						"text": "瑶海区",
						"id": "340102"
					},
					{
						"text": "庐阳区",
						"id": "340103"
					},
					{
						"text": "蜀山区",
						"id": "340104"
					},
					{
						"text": "包河区",
						"id": "340111"
					},
					{
						"text": "长丰县",
						"id": "340121"
					},
					{
						"text": "肥东县",
						"id": "340122"
					},
					{
						"text": "肥西县",
						"id": "340123"
					},
					{
						"text": "庐江县",
						"id": "340124"
					},
					{
						"text": "巢湖市",
						"id": "340181"
					}
				]
			},
			{
				"text": "芜湖市",
				"id": "340200",
				"children": [
					{
						"text": "镜湖区",
						"id": "340202"
					},
					{
						"text": "弋江区",
						"id": "340203"
					},
					{
						"text": "鸠江区",
						"id": "340207"
					},
					{
						"text": "湾沚区",
						"id": "340221"
					},
					{
						"text": "繁昌区",
						"id": "340222"
					},
					{
						"text": "南陵县",
						"id": "340223"
					},
					{
						"text": "无为市",
						"id": "340225"
					}
				]
			},
			{
				"text": "蚌埠市",
				"id": "340300",
				"children": [
					{
						"text": "龙子湖区",
						"id": "340302"
					},
					{
						"text": "蚌山区",
						"id": "340303"
					},
					{
						"text": "禹会区",
						"id": "340304"
					},
					{
						"text": "淮上区",
						"id": "340311"
					},
					{
						"text": "怀远县",
						"id": "340321"
					},
					{
						"text": "五河县",
						"id": "340322"
					},
					{
						"text": "固镇县",
						"id": "340323"
					}
				]
			},
			{
				"text": "淮南市",
				"id": "340400",
				"children": [
					{
						"text": "大通区",
						"id": "340402"
					},
					{
						"text": "田家庵区",
						"id": "340403"
					},
					{
						"text": "谢家集区",
						"id": "340404"
					},
					{
						"text": "八公山区",
						"id": "340405"
					},
					{
						"text": "潘集区",
						"id": "340406"
					},
					{
						"text": "凤台县",
						"id": "340421"
					},
					{
						"text": "寿县",
						"id": "341521"
					}
				]
			},
			{
				"text": "马鞍山市",
				"id": "340500",
				"children": [
					{
						"text": "花山区",
						"id": "340503"
					},
					{
						"text": "雨山区",
						"id": "340504"
					},
					{
						"text": "当涂县",
						"id": "340521"
					},
					{
						"text": "含山县",
						"id": "340522"
					},
					{
						"text": "和县",
						"id": "340523"
					},
					{
						"text": "博望区",
						"id": "340524"
					}
				]
			},
			{
				"text": "淮北市",
				"id": "340600",
				"children": [
					{
						"text": "杜集区",
						"id": "340602"
					},
					{
						"text": "相山区",
						"id": "340603"
					},
					{
						"text": "烈山区",
						"id": "340604"
					},
					{
						"text": "濉溪县",
						"id": "340621"
					}
				]
			},
			{
				"text": "铜陵市",
				"id": "340700",
				"children": [
					{
						"text": "郊区",
						"id": "340711"
					},
					{
						"text": "枞阳县",
						"id": "340823"
					},
					{
						"text": "义安区",
						"id": "340722"
					},
					{
						"text": "铜官区",
						"id": "340723"
					}
				]
			},
			{
				"text": "安庆市",
				"id": "340800",
				"children": [
					{
						"text": "迎江区",
						"id": "340802"
					},
					{
						"text": "大观区",
						"id": "340803"
					},
					{
						"text": "宜秀区",
						"id": "340811"
					},
					{
						"text": "怀宁县",
						"id": "340822"
					},
					{
						"text": "潜山市",
						"id": "340824"
					},
					{
						"text": "太湖县",
						"id": "340825"
					},
					{
						"text": "宿松县",
						"id": "340826"
					},
					{
						"text": "望江县",
						"id": "340827"
					},
					{
						"text": "岳西县",
						"id": "340828"
					},
					{
						"text": "桐城市",
						"id": "340881"
					}
				]
			},
			{
				"text": "黄山市",
				"id": "341000",
				"children": [
					{
						"text": "屯溪区",
						"id": "341002"
					},
					{
						"text": "黄山区",
						"id": "341003"
					},
					{
						"text": "徽州区",
						"id": "341004"
					},
					{
						"text": "歙县",
						"id": "341021"
					},
					{
						"text": "休宁县",
						"id": "341022"
					},
					{
						"text": "黟县",
						"id": "341023"
					},
					{
						"text": "祁门县",
						"id": "341024"
					}
				]
			},
			{
				"text": "滁州市",
				"id": "341100",
				"children": [
					{
						"text": "琅琊区",
						"id": "341102"
					},
					{
						"text": "南谯区",
						"id": "341103"
					},
					{
						"text": "来安县",
						"id": "341122"
					},
					{
						"text": "全椒县",
						"id": "341124"
					},
					{
						"text": "定远县",
						"id": "341125"
					},
					{
						"text": "凤阳县",
						"id": "341126"
					},
					{
						"text": "天长市",
						"id": "341181"
					},
					{
						"text": "明光市",
						"id": "341182"
					}
				]
			},
			{
				"text": "阜阳市",
				"id": "341200",
				"children": [
					{
						"text": "颍州区",
						"id": "341202"
					},
					{
						"text": "颍东区",
						"id": "341203"
					},
					{
						"text": "颍泉区",
						"id": "341204"
					},
					{
						"text": "临泉县",
						"id": "341221"
					},
					{
						"text": "太和县",
						"id": "341222"
					},
					{
						"text": "阜南县",
						"id": "341225"
					},
					{
						"text": "颍上县",
						"id": "341226"
					},
					{
						"text": "界首市",
						"id": "341282"
					}
				]
			},
			{
				"text": "宿州市",
				"id": "341300",
				"children": [
					{
						"text": "埇桥区",
						"id": "341302"
					},
					{
						"text": "砀山县",
						"id": "341321"
					},
					{
						"text": "萧县",
						"id": "341322"
					},
					{
						"text": "灵璧县",
						"id": "341323"
					},
					{
						"text": "泗县",
						"id": "341324"
					}
				]
			},
			{
				"text": "六安市",
				"id": "341500",
				"children": [
					{
						"text": "金安区",
						"id": "341502"
					},
					{
						"text": "裕安区",
						"id": "341503"
					},
					{
						"text": "霍邱县",
						"id": "341522"
					},
					{
						"text": "舒城县",
						"id": "341523"
					},
					{
						"text": "金寨县",
						"id": "341524"
					},
					{
						"text": "霍山县",
						"id": "341525"
					},
					{
						"text": "叶集区",
						"id": "341526"
					}
				]
			},
			{
				"text": "亳州市",
				"id": "341600",
				"children": [
					{
						"text": "谯城区",
						"id": "341602"
					},
					{
						"text": "涡阳县",
						"id": "341621"
					},
					{
						"text": "蒙城县",
						"id": "341622"
					},
					{
						"text": "利辛县",
						"id": "341623"
					}
				]
			},
			{
				"text": "池州市",
				"id": "341700",
				"children": [
					{
						"text": "贵池区",
						"id": "341702"
					},
					{
						"text": "东至县",
						"id": "341721"
					},
					{
						"text": "石台县",
						"id": "341722"
					},
					{
						"text": "青阳县",
						"id": "341723"
					}
				]
			},
			{
				"text": "宣城市",
				"id": "341800",
				"children": [
					{
						"text": "宣州区",
						"id": "341802"
					},
					{
						"text": "郎溪县",
						"id": "341821"
					},
					{
						"text": "广德市",
						"id": "341822"
					},
					{
						"text": "泾县",
						"id": "341823"
					},
					{
						"text": "绩溪县",
						"id": "341824"
					},
					{
						"text": "旌德县",
						"id": "341825"
					},
					{
						"text": "宁国市",
						"id": "341881"
					}
				]
			}
		]
	},
	{
		"id": "350000",
		"text": "福建",
		"children": [
			{
				"text": "福州市",
				"id": "350100",
				"children": [
					{
						"text": "鼓楼区",
						"id": "350102"
					},
					{
						"text": "台江区",
						"id": "350103"
					},
					{
						"text": "仓山区",
						"id": "350104"
					},
					{
						"text": "马尾区",
						"id": "350105"
					},
					{
						"text": "晋安区",
						"id": "350111"
					},
					{
						"text": "闽侯县",
						"id": "350121"
					},
					{
						"text": "连江县",
						"id": "350122"
					},
					{
						"text": "罗源县",
						"id": "350123"
					},
					{
						"text": "闽清县",
						"id": "350124"
					},
					{
						"text": "永泰县",
						"id": "350125"
					},
					{
						"text": "平潭县",
						"id": "350128"
					},
					{
						"text": "福清市",
						"id": "350181"
					},
					{
						"text": "长乐市",
						"id": "350182"
					}
				]
			},
			{
				"text": "厦门市",
				"id": "350200",
				"children": [
					{
						"text": "思明区",
						"id": "350203"
					},
					{
						"text": "海沧区",
						"id": "350205"
					},
					{
						"text": "湖里区",
						"id": "350206"
					},
					{
						"text": "集美区",
						"id": "350211"
					},
					{
						"text": "同安区",
						"id": "350212"
					},
					{
						"text": "翔安区",
						"id": "350213"
					}
				]
			},
			{
				"text": "莆田市",
				"id": "350300",
				"children": [
					{
						"text": "城厢区",
						"id": "350302"
					},
					{
						"text": "涵江区",
						"id": "350303"
					},
					{
						"text": "荔城区",
						"id": "350304"
					},
					{
						"text": "秀屿区",
						"id": "350305"
					},
					{
						"text": "仙游县",
						"id": "350322"
					}
				]
			},
			{
				"text": "三明市",
				"id": "350400",
				"children": [
					{
						"text": "梅列区",
						"id": "350402"
					},
					{
						"text": "三元区",
						"id": "350403"
					},
					{
						"text": "明溪县",
						"id": "350421"
					},
					{
						"text": "清流县",
						"id": "350423"
					},
					{
						"text": "宁化县",
						"id": "350424"
					},
					{
						"text": "大田县",
						"id": "350425"
					},
					{
						"text": "尤溪县",
						"id": "350426"
					},
					{
						"text": "沙县",
						"id": "350427"
					},
					{
						"text": "将乐县",
						"id": "350428"
					},
					{
						"text": "泰宁县",
						"id": "350429"
					},
					{
						"text": "建宁县",
						"id": "350430"
					},
					{
						"text": "永安市",
						"id": "350481"
					}
				]
			},
			{
				"text": "泉州市",
				"id": "350500",
				"children": [
					{
						"text": "鲤城区",
						"id": "350502"
					},
					{
						"text": "丰泽区",
						"id": "350503"
					},
					{
						"text": "洛江区",
						"id": "350504"
					},
					{
						"text": "泉港区",
						"id": "350505"
					},
					{
						"text": "惠安县",
						"id": "350521"
					},
					{
						"text": "安溪县",
						"id": "350524"
					},
					{
						"text": "永春县",
						"id": "350525"
					},
					{
						"text": "德化县",
						"id": "350526"
					},
					{
						"text": "金门县",
						"id": "350527"
					},
					{
						"text": "石狮市",
						"id": "350581"
					},
					{
						"text": "晋江市",
						"id": "350582"
					},
					{
						"text": "南安市",
						"id": "350583"
					}
				]
			},
			{
				"text": "漳州市",
				"id": "350600",
				"children": [
					{
						"text": "芗城区",
						"id": "350602"
					},
					{
						"text": "龙文区",
						"id": "350603"
					},
					{
						"text": "云霄县",
						"id": "350622"
					},
					{
						"text": "漳浦县",
						"id": "350623"
					},
					{
						"text": "诏安县",
						"id": "350624"
					},
					{
						"text": "长泰县",
						"id": "350625"
					},
					{
						"text": "东山县",
						"id": "350626"
					},
					{
						"text": "南靖县",
						"id": "350627"
					},
					{
						"text": "平和县",
						"id": "350628"
					},
					{
						"text": "华安县",
						"id": "350629"
					},
					{
						"text": "龙海市",
						"id": "350681"
					}
				]
			},
			{
				"text": "南平市",
				"id": "350700",
				"children": [
					{
						"text": "延平区",
						"id": "350702"
					},
					{
						"text": "顺昌县",
						"id": "350721"
					},
					{
						"text": "浦城县",
						"id": "350722"
					},
					{
						"text": "光泽县",
						"id": "350723"
					},
					{
						"text": "松溪县",
						"id": "350724"
					},
					{
						"text": "政和县",
						"id": "350725"
					},
					{
						"text": "邵武市",
						"id": "350781"
					},
					{
						"text": "武夷山市",
						"id": "350782"
					},
					{
						"text": "建瓯市",
						"id": "350783"
					},
					{
						"text": "建阳市",
						"id": "350784"
					}
				]
			},
			{
				"text": "龙岩市",
				"id": "350800",
				"children": [
					{
						"text": "新罗区",
						"id": "350802"
					},
					{
						"text": "长汀县",
						"id": "350821"
					},
					{
						"text": "永定县",
						"id": "350822"
					},
					{
						"text": "上杭县",
						"id": "350823"
					},
					{
						"text": "武平县",
						"id": "350824"
					},
					{
						"text": "连城县",
						"id": "350825"
					},
					{
						"text": "漳平市",
						"id": "350881"
					}
				]
			},
			{
				"text": "宁德市",
				"id": "350900",
				"children": [
					{
						"text": "蕉城区",
						"id": "350902"
					},
					{
						"text": "霞浦县",
						"id": "350921"
					},
					{
						"text": "古田县",
						"id": "350922"
					},
					{
						"text": "屏南县",
						"id": "350923"
					},
					{
						"text": "寿宁县",
						"id": "350924"
					},
					{
						"text": "周宁县",
						"id": "350925"
					},
					{
						"text": "柘荣县",
						"id": "350926"
					},
					{
						"text": "福安市",
						"id": "350981"
					},
					{
						"text": "福鼎市",
						"id": "350982"
					}
				]
			}
		]
	},
	{
		"id": "360000",
		"text": "江西",
		"children": [
			{
				"text": "南昌市",
				"id": "360100",
				"children": [
					{
						"text": "东湖区",
						"id": "360102"
					},
					{
						"text": "西湖区",
						"id": "360103"
					},
					{
						"text": "青云谱区",
						"id": "360104"
					},
					{
						"text": "湾里区",
						"id": "360105"
					},
					{
						"text": "青山湖区",
						"id": "360111"
					},
					{
						"text": "南昌县",
						"id": "360121"
					},
					{
						"text": "新建区",
						"id": "360122"
					},
					{
						"text": "安义县",
						"id": "360123"
					},
					{
						"text": "进贤县",
						"id": "360124"
					},
					{
						"text": "红谷滩区",
						"id": "360125"
					},
					{
						"text": "赣江新区",
						"id": "360126"
					}
				]
			},
			{
				"text": "景德镇市",
				"id": "360200",
				"children": [
					{
						"text": "昌江区",
						"id": "360202"
					},
					{
						"text": "珠山区",
						"id": "360203"
					},
					{
						"text": "浮梁县",
						"id": "360222"
					},
					{
						"text": "乐平市",
						"id": "360281"
					}
				]
			},
			{
				"text": "萍乡市",
				"id": "360300",
				"children": [
					{
						"text": "安源区",
						"id": "360302"
					},
					{
						"text": "湘东区",
						"id": "360313"
					},
					{
						"text": "莲花县",
						"id": "360321"
					},
					{
						"text": "上栗县",
						"id": "360322"
					},
					{
						"text": "芦溪县",
						"id": "360323"
					}
				]
			},
			{
				"text": "九江市",
				"id": "360400",
				"children": [
					{
						"text": "濂溪区",
						"id": "360402"
					},
					{
						"text": "浔阳区",
						"id": "360403"
					},
					{
						"text": "柴桑区",
						"id": "360421"
					},
					{
						"text": "武宁县",
						"id": "360423"
					},
					{
						"text": "修水县",
						"id": "360424"
					},
					{
						"text": "永修县",
						"id": "360425"
					},
					{
						"text": "德安县",
						"id": "360426"
					},
					{
						"text": "庐山市",
						"id": "360427"
					},
					{
						"text": "都昌县",
						"id": "360428"
					},
					{
						"text": "湖口县",
						"id": "360429"
					},
					{
						"text": "彭泽县",
						"id": "360430"
					},
					{
						"text": "瑞昌市",
						"id": "360481"
					},
					{
						"text": "共青城市",
						"id": "360482"
					}
				]
			},
			{
				"text": "新余市",
				"id": "360500",
				"children": [
					{
						"text": "渝水区",
						"id": "360502"
					},
					{
						"text": "分宜县",
						"id": "360521"
					}
				]
			},
			{
				"text": "鹰潭市",
				"id": "360600",
				"children": [
					{
						"text": "月湖区",
						"id": "360602"
					},
					{
						"text": "余江县",
						"id": "360622"
					},
					{
						"text": "贵溪市",
						"id": "360681"
					}
				]
			},
			{
				"text": "赣州市",
				"id": "360700",
				"children": [
					{
						"text": "章贡区",
						"id": "360702"
					},
					{
						"text": "赣县",
						"id": "360721"
					},
					{
						"text": "信丰县",
						"id": "360722"
					},
					{
						"text": "大余县",
						"id": "360723"
					},
					{
						"text": "上犹县",
						"id": "360724"
					},
					{
						"text": "崇义县",
						"id": "360725"
					},
					{
						"text": "安远县",
						"id": "360726"
					},
					{
						"text": "龙南县",
						"id": "360727"
					},
					{
						"text": "定南县",
						"id": "360728"
					},
					{
						"text": "全南县",
						"id": "360729"
					},
					{
						"text": "宁都县",
						"id": "360730"
					},
					{
						"text": "于都县",
						"id": "360731"
					},
					{
						"text": "兴国县",
						"id": "360732"
					},
					{
						"text": "会昌县",
						"id": "360733"
					},
					{
						"text": "寻乌县",
						"id": "360734"
					},
					{
						"text": "石城县",
						"id": "360735"
					},
					{
						"text": "瑞金市",
						"id": "360781"
					},
					{
						"text": "南康市",
						"id": "360782"
					},
					{
						"text": "黄金开发区",
						"id": "360783"
					}
				]
			},
			{
				"text": "吉安市",
				"id": "360800",
				"children": [
					{
						"text": "吉州区",
						"id": "360802"
					},
					{
						"text": "青原区",
						"id": "360803"
					},
					{
						"text": "吉安县",
						"id": "360821"
					},
					{
						"text": "吉水县",
						"id": "360822"
					},
					{
						"text": "峡江县",
						"id": "360823"
					},
					{
						"text": "新干县",
						"id": "360824"
					},
					{
						"text": "永丰县",
						"id": "360825"
					},
					{
						"text": "泰和县",
						"id": "360826"
					},
					{
						"text": "遂川县",
						"id": "360827"
					},
					{
						"text": "万安县",
						"id": "360828"
					},
					{
						"text": "安福县",
						"id": "360829"
					},
					{
						"text": "永新县",
						"id": "360830"
					},
					{
						"text": "井冈山市",
						"id": "360881"
					}
				]
			},
			{
				"text": "宜春市",
				"id": "360900",
				"children": [
					{
						"text": "袁州区",
						"id": "360902"
					},
					{
						"text": "奉新县",
						"id": "360921"
					},
					{
						"text": "万载县",
						"id": "360922"
					},
					{
						"text": "上高县",
						"id": "360923"
					},
					{
						"text": "宜丰县",
						"id": "360924"
					},
					{
						"text": "靖安县",
						"id": "360925"
					},
					{
						"text": "铜鼓县",
						"id": "360926"
					},
					{
						"text": "丰城市",
						"id": "360981"
					},
					{
						"text": "樟树市",
						"id": "360982"
					},
					{
						"text": "高安市",
						"id": "360983"
					}
				]
			},
			{
				"text": "抚州市",
				"id": "361000",
				"children": [
					{
						"text": "临川区",
						"id": "361002"
					},
					{
						"text": "南城县",
						"id": "361021"
					},
					{
						"text": "黎川县",
						"id": "361022"
					},
					{
						"text": "南丰县",
						"id": "361023"
					},
					{
						"text": "崇仁县",
						"id": "361024"
					},
					{
						"text": "乐安县",
						"id": "361025"
					},
					{
						"text": "宜黄县",
						"id": "361026"
					},
					{
						"text": "金溪县",
						"id": "361027"
					},
					{
						"text": "资溪县",
						"id": "361028"
					},
					{
						"text": "东乡县",
						"id": "361029"
					},
					{
						"text": "广昌县",
						"id": "361030"
					}
				]
			},
			{
				"text": "上饶市",
				"id": "361100",
				"children": [
					{
						"text": "信州区",
						"id": "361102"
					},
					{
						"text": "上饶县",
						"id": "361121"
					},
					{
						"text": "广丰县",
						"id": "361122"
					},
					{
						"text": "玉山县",
						"id": "361123"
					},
					{
						"text": "铅山县",
						"id": "361124"
					},
					{
						"text": "横峰县",
						"id": "361125"
					},
					{
						"text": "弋阳县",
						"id": "361126"
					},
					{
						"text": "余干县",
						"id": "361127"
					},
					{
						"text": "鄱阳县",
						"id": "361128"
					},
					{
						"text": "万年县",
						"id": "361129"
					},
					{
						"text": "婺源县",
						"id": "361130"
					},
					{
						"text": "德兴市",
						"id": "361181"
					}
				]
			}
		]
	},
	{
		"id": "370000",
		"text": "山东",
		"children": [
			{
				"text": "济南市",
				"id": "370100",
				"children": [
					{
						"text": "历下区",
						"id": "370102"
					},
					{
						"text": "市中区",
						"id": "370103"
					},
					{
						"text": "槐荫区",
						"id": "370104"
					},
					{
						"text": "天桥区",
						"id": "370105"
					},
					{
						"text": "历城区",
						"id": "370112"
					},
					{
						"text": "长清区",
						"id": "370113"
					},
					{
						"text": "平阴县",
						"id": "370124"
					},
					{
						"text": "济阳县",
						"id": "370125"
					},
					{
						"text": "商河县",
						"id": "370126"
					},
					{
						"text": "章丘市",
						"id": "370181"
					},
					{
						"text": "高新区",
						"id": "370127"
					}
				]
			},
			{
				"text": "青岛市",
				"id": "370200",
				"children": [
					{
						"text": "市南区",
						"id": "370202"
					},
					{
						"text": "市北区",
						"id": "370203"
					},
					{
						"text": "黄岛区（原胶南市）",
						"id": "370211"
					},
					{
						"text": "崂山区",
						"id": "370212"
					},
					{
						"text": "李沧区",
						"id": "370213"
					},
					{
						"text": "城阳区",
						"id": "370214"
					},
					{
						"text": "胶州市",
						"id": "370281"
					},
					{
						"text": "即墨市",
						"id": "370282"
					},
					{
						"text": "平度市",
						"id": "370283"
					},
					{
						"text": "莱西市",
						"id": "370285"
					},
					{
						"text": "高新区",
						"id": "370286"
					},
					{
						"text": "开发区",
						"id": "370287"
					}
				]
			},
			{
				"text": "淄博市",
				"id": "370300",
				"children": [
					{
						"text": "淄川区",
						"id": "370302"
					},
					{
						"text": "张店区",
						"id": "370303"
					},
					{
						"text": "博山区",
						"id": "370304"
					},
					{
						"text": "临淄区",
						"id": "370305"
					},
					{
						"text": "周村区",
						"id": "370306"
					},
					{
						"text": "桓台县",
						"id": "370321"
					},
					{
						"text": "高青县",
						"id": "370322"
					},
					{
						"text": "沂源县",
						"id": "370323"
					}
				]
			},
			{
				"text": "枣庄市",
				"id": "370400",
				"children": [
					{
						"text": "市中区",
						"id": "370402"
					},
					{
						"text": "薛城区",
						"id": "370403"
					},
					{
						"text": "峄城区",
						"id": "370404"
					},
					{
						"text": "台儿庄区",
						"id": "370405"
					},
					{
						"text": "山亭区",
						"id": "370406"
					},
					{
						"text": "滕州市",
						"id": "370481"
					}
				]
			},
			{
				"text": "东营市",
				"id": "370500",
				"children": [
					{
						"text": "东营区",
						"id": "370502"
					},
					{
						"text": "河口区",
						"id": "370503"
					},
					{
						"text": "垦利区",
						"id": "370521"
					},
					{
						"text": "利津县",
						"id": "370522"
					},
					{
						"text": "广饶县",
						"id": "370523"
					},
					{
						"text": "胜北",
						"id": "370524"
					},
					{
						"text": "胜南",
						"id": "370525"
					},
					{
						"text": "胜东",
						"id": "370526"
					},
					{
						"text": "胜利河口",
						"id": "370527"
					},
					{
						"text": "孤岛",
						"id": "370528"
					},
					{
						"text": "仙河",
						"id": "370529"
					},
					{
						"text": "开发区",
						"id": "370530"
					},
					{
						"text": "胜中",
						"id": "370531"
					}
				]
			},
			{
				"text": "烟台市",
				"id": "370600",
				"children": [
					{
						"text": "芝罘区",
						"id": "370602"
					},
					{
						"text": "福山区",
						"id": "370611"
					},
					{
						"text": "牟平区",
						"id": "370612"
					},
					{
						"text": "高新区",
						"id": "370615"
					},
					{
						"text": "莱山区",
						"id": "370613"
					},
					{
						"text": "开发区",
						"id": "370614"
					},
					{
						"text": "长岛县",
						"id": "370634"
					},
					{
						"text": "龙口市",
						"id": "370681"
					},
					{
						"text": "莱阳市",
						"id": "370682"
					},
					{
						"text": "莱州市",
						"id": "370683"
					},
					{
						"text": "蓬莱市",
						"id": "370684"
					},
					{
						"text": "招远市",
						"id": "370685"
					},
					{
						"text": "栖霞市",
						"id": "370686"
					},
					{
						"text": "海阳市",
						"id": "370687"
					}
				]
			},
			{
				"text": "潍坊市",
				"id": "370700",
				"children": [
					{
						"text": "潍城区",
						"id": "370702"
					},
					{
						"text": "寒亭区",
						"id": "370703"
					},
					{
						"text": "坊子区",
						"id": "370704"
					},
					{
						"text": "奎文区",
						"id": "370705"
					},
					{
						"text": "临朐县",
						"id": "370724"
					},
					{
						"text": "昌乐县",
						"id": "370725"
					},
					{
						"text": "青州市",
						"id": "370781"
					},
					{
						"text": "诸城市",
						"id": "370782"
					},
					{
						"text": "寿光市",
						"id": "370783"
					},
					{
						"text": "安丘市",
						"id": "370784"
					},
					{
						"text": "高密市",
						"id": "370785"
					},
					{
						"text": "昌邑市",
						"id": "370786"
					},
					{
						"text": "峡山区",
						"id": "370788"
					},
					{
						"text": "滨海区",
						"id": "370789"
					},
					{
						"text": "高新区",
						"id": "370787"
					}
				]
			},
			{
				"text": "济宁市",
				"id": "370800",
				"children": [
					{
						"text": "市中区",
						"id": "370802"
					},
					{
						"text": "任城区",
						"id": "370811"
					},
					{
						"text": "微山县",
						"id": "370826"
					},
					{
						"text": "鱼台县",
						"id": "370827"
					},
					{
						"text": "金乡县",
						"id": "370828"
					},
					{
						"text": "嘉祥县",
						"id": "370829"
					},
					{
						"text": "汶上县",
						"id": "370830"
					},
					{
						"text": "泗水县",
						"id": "370831"
					},
					{
						"text": "梁山县",
						"id": "370832"
					},
					{
						"text": "曲阜市",
						"id": "370881"
					},
					{
						"text": "兖州市",
						"id": "370882"
					},
					{
						"text": "邹城市",
						"id": "370883"
					},
					{
						"text": "高新区",
						"id": "370884"
					},
					{
						"text": "北湖区",
						"id": "370885"
					}
				]
			},
			{
				"text": "泰安市",
				"id": "370900",
				"children": [
					{
						"text": "泰山区",
						"id": "370902"
					},
					{
						"text": "岱岳区",
						"id": "370911"
					},
					{
						"text": "宁阳县",
						"id": "370921"
					},
					{
						"text": "东平县",
						"id": "370923"
					},
					{
						"text": "新泰市",
						"id": "370982"
					},
					{
						"text": "肥城市",
						"id": "370983"
					},
					{
						"text": "高新区",
						"id": "370984"
					}
				]
			},
			{
				"text": "威海市",
				"id": "371000",
				"children": [
					{
						"text": "环翠区",
						"id": "371002"
					},
					{
						"text": "文登区",
						"id": "371081"
					},
					{
						"text": "荣成市",
						"id": "371082"
					},
					{
						"text": "乳山市",
						"id": "371083"
					},
					{
						"text": "经区",
						"id": "371084"
					},
					{
						"text": "高区",
						"id": "371085"
					},
					{
						"text": "临港区",
						"id": "371086"
					}
				]
			},
			{
				"text": "日照市",
				"id": "371100",
				"children": [
					{
						"text": "东港区",
						"id": "371102"
					},
					{
						"text": "岚山区",
						"id": "371103"
					},
					{
						"text": "五莲县",
						"id": "371121"
					},
					{
						"text": "莒县",
						"id": "371122"
					}
				]
			},
			{
				"text": "莱芜市",
				"id": "371200",
				"children": [
					{
						"text": "莱城区",
						"id": "371202"
					},
					{
						"text": "钢城区",
						"id": "371203"
					}
				]
			},
			{
				"text": "临沂市",
				"id": "371300",
				"children": [
					{
						"text": "兰山区",
						"id": "371302"
					},
					{
						"text": "罗庄区",
						"id": "371311"
					},
					{
						"text": "河东区",
						"id": "371312"
					},
					{
						"text": "沂南县",
						"id": "371321"
					},
					{
						"text": "郯城县",
						"id": "371322"
					},
					{
						"text": "沂水县",
						"id": "371323"
					},
					{
						"text": "兰陵县",
						"id": "371324"
					},
					{
						"text": "费县",
						"id": "371325"
					},
					{
						"text": "平邑县",
						"id": "371326"
					},
					{
						"text": "莒南县",
						"id": "371327"
					},
					{
						"text": "蒙阴县",
						"id": "371328"
					},
					{
						"text": "临沭县",
						"id": "371329"
					},
					{
						"text": "市区",
						"id": "371330"
					},
					{
						"text": "开发区",
						"id": "371313"
					}
				]
			},
			{
				"text": "德州市",
				"id": "371400",
				"children": [
					{
						"text": "德城区",
						"id": "371402"
					},
					{
						"text": "陵城区",
						"id": "371421"
					},
					{
						"text": "宁津县",
						"id": "371422"
					},
					{
						"text": "庆云县",
						"id": "371423"
					},
					{
						"text": "临邑县",
						"id": "371424"
					},
					{
						"text": "齐河县",
						"id": "371425"
					},
					{
						"text": "平原县",
						"id": "371426"
					},
					{
						"text": "夏津县",
						"id": "371427"
					},
					{
						"text": "武城县",
						"id": "371428"
					},
					{
						"text": "乐陵市",
						"id": "371481"
					},
					{
						"text": "禹城市",
						"id": "371482"
					},
					{
						"text": "经济开发区",
						"id": "371483"
					}
				]
			},
			{
				"text": "聊城市",
				"id": "371500",
				"children": [
					{
						"text": "东昌府区",
						"id": "371502"
					},
					{
						"text": "阳谷县",
						"id": "371521"
					},
					{
						"text": "莘县",
						"id": "371522"
					},
					{
						"text": "茌平县",
						"id": "371523"
					},
					{
						"text": "东阿县",
						"id": "371524"
					},
					{
						"text": "冠县",
						"id": "371525"
					},
					{
						"text": "高唐县",
						"id": "371526"
					},
					{
						"text": "临清市",
						"id": "371581"
					},
					{
						"text": "市城区",
						"id": "371527"
					}
				]
			},
			{
				"text": "滨州市",
				"id": "371600",
				"children": [
					{
						"text": "滨城区",
						"id": "371602"
					},
					{
						"text": "惠民县",
						"id": "371621"
					},
					{
						"text": "阳信县",
						"id": "371622"
					},
					{
						"text": "无棣县",
						"id": "371623"
					},
					{
						"text": "沾化区",
						"id": "371624"
					},
					{
						"text": "博兴县",
						"id": "371625"
					},
					{
						"text": "邹平县",
						"id": "371626"
					},
					{
						"text": "滨北",
						"id": "371627"
					},
					{
						"text": "高新区",
						"id": "371628"
					}
				]
			},
			{
				"text": "菏泽市",
				"id": "371700",
				"children": [
					{
						"text": "牡丹区",
						"id": "371702"
					},
					{
						"text": "曹县",
						"id": "371721"
					},
					{
						"text": "单县",
						"id": "371722"
					},
					{
						"text": "成武县",
						"id": "371723"
					},
					{
						"text": "巨野县",
						"id": "371724"
					},
					{
						"text": "郓城县",
						"id": "371725"
					},
					{
						"text": "鄄城县",
						"id": "371726"
					},
					{
						"text": "定陶区",
						"id": "371727"
					},
					{
						"text": "东明县",
						"id": "371728"
					}
				]
			}
		]
	},
	{
		"id": "410000",
		"text": "河南",
		"children": [
			{
				"text": "郑州市",
				"id": "410100",
				"children": [
					{
						"text": "中原区",
						"id": "410102"
					},
					{
						"text": "二七区",
						"id": "410103"
					},
					{
						"text": "管城回族区",
						"id": "410104"
					},
					{
						"text": "金水区",
						"id": "410105"
					},
					{
						"text": "上街区",
						"id": "410106"
					},
					{
						"text": "惠济区",
						"id": "410108"
					},
					{
						"text": "中牟县",
						"id": "410122"
					},
					{
						"text": "巩义市",
						"id": "410181"
					},
					{
						"text": "荥阳市",
						"id": "410182"
					},
					{
						"text": "新密市",
						"id": "410183"
					},
					{
						"text": "新郑市",
						"id": "410184"
					},
					{
						"text": "登封市",
						"id": "410185"
					},
					{
						"text": "高新技术产业开发区",
						"id": "410186"
					},
					{
						"text": "经济技术开发区",
						"id": "410187"
					},
					{
						"text": "郑东新区",
						"id": "410188"
					},
					{
						"text": "航空港区",
						"id": "410189"
					}
				]
			},
			{
				"text": "开封市",
				"id": "410200",
				"children": [
					{
						"text": "龙亭区",
						"id": "410202"
					},
					{
						"text": "顺河回族区",
						"id": "410203"
					},
					{
						"text": "鼓楼区",
						"id": "410204"
					},
					{
						"text": "禹王台区",
						"id": "410205"
					},
					{
						"text": "金明区",
						"id": "410211"
					},
					{
						"text": "杞县",
						"id": "410221"
					},
					{
						"text": "通许县",
						"id": "410222"
					},
					{
						"text": "尉氏县",
						"id": "410223"
					},
					{
						"text": "祥符区",
						"id": "410224"
					},
					{
						"text": "兰考县",
						"id": "410225"
					}
				]
			},
			{
				"text": "洛阳市",
				"id": "410300",
				"children": [
					{
						"text": "老城区",
						"id": "410302"
					},
					{
						"text": "西工区",
						"id": "410303"
					},
					{
						"text": "瀍河回族区",
						"id": "410304"
					},
					{
						"text": "涧西区",
						"id": "410305"
					},
					{
						"text": "吉利区",
						"id": "410306"
					},
					{
						"text": "洛龙区",
						"id": "410311"
					},
					{
						"text": "孟津县",
						"id": "410322"
					},
					{
						"text": "新安县",
						"id": "410323"
					},
					{
						"text": "栾川县",
						"id": "410324"
					},
					{
						"text": "嵩县",
						"id": "410325"
					},
					{
						"text": "汝阳县",
						"id": "410326"
					},
					{
						"text": "宜阳县",
						"id": "410327"
					},
					{
						"text": "洛宁县",
						"id": "410328"
					},
					{
						"text": "伊川县",
						"id": "410329"
					},
					{
						"text": "偃师市",
						"id": "410381"
					},
					{
						"text": "伊滨区",
						"id": "410382"
					}
				]
			},
			{
				"text": "平顶山市",
				"id": "410400",
				"children": [
					{
						"text": "新华区",
						"id": "410402"
					},
					{
						"text": "卫东区",
						"id": "410403"
					},
					{
						"text": "石龙区",
						"id": "410404"
					},
					{
						"text": "湛河区",
						"id": "410411"
					},
					{
						"text": "宝丰县",
						"id": "410421"
					},
					{
						"text": "叶县",
						"id": "410422"
					},
					{
						"text": "鲁山县",
						"id": "410423"
					},
					{
						"text": "郏县",
						"id": "410425"
					},
					{
						"text": "舞钢市",
						"id": "410481"
					},
					{
						"text": "汝州市",
						"id": "410482"
					},
					{
						"text": "市区新城区",
						"id": "410483"
					},
					{
						"text": "市区高新区",
						"id": "410484"
					}
				]
			},
			{
				"text": "安阳市",
				"id": "410500",
				"children": [
					{
						"text": "文峰区",
						"id": "410502"
					},
					{
						"text": "北关区",
						"id": "410503"
					},
					{
						"text": "殷都区",
						"id": "410505"
					},
					{
						"text": "龙安区",
						"id": "410506"
					},
					{
						"text": "安阳县",
						"id": "410522"
					},
					{
						"text": "汤阴县",
						"id": "410523"
					},
					{
						"text": "滑县",
						"id": "410526"
					},
					{
						"text": "内黄县",
						"id": "410527"
					},
					{
						"text": "林州市",
						"id": "410581"
					}
				]
			},
			{
				"text": "鹤壁市",
				"id": "410600",
				"children": [
					{
						"text": "鹤山区",
						"id": "410602"
					},
					{
						"text": "山城区",
						"id": "410603"
					},
					{
						"text": "淇滨区",
						"id": "410611"
					},
					{
						"text": "浚县",
						"id": "410621"
					},
					{
						"text": "淇县",
						"id": "410622"
					}
				]
			},
			{
				"text": "新乡市",
				"id": "410700",
				"children": [
					{
						"text": "红旗区",
						"id": "410702"
					},
					{
						"text": "卫滨区",
						"id": "410703"
					},
					{
						"text": "凤泉区",
						"id": "410704"
					},
					{
						"text": "牧野区",
						"id": "410711"
					},
					{
						"text": "新乡县",
						"id": "410721"
					},
					{
						"text": "获嘉县",
						"id": "410724"
					},
					{
						"text": "原阳县",
						"id": "410725"
					},
					{
						"text": "延津县",
						"id": "410726"
					},
					{
						"text": "封丘县",
						"id": "410727"
					},
					{
						"text": "长垣县",
						"id": "410728"
					},
					{
						"text": "卫辉市",
						"id": "410781"
					},
					{
						"text": "辉县市",
						"id": "410782"
					}
				]
			},
			{
				"text": "焦作市",
				"id": "410800",
				"children": [
					{
						"text": "解放区",
						"id": "410802"
					},
					{
						"text": "中站区",
						"id": "410803"
					},
					{
						"text": "马村区",
						"id": "410804"
					},
					{
						"text": "山阳区",
						"id": "410811"
					},
					{
						"text": "修武县",
						"id": "410821"
					},
					{
						"text": "博爱县",
						"id": "410822"
					},
					{
						"text": "武陟县",
						"id": "410823"
					},
					{
						"text": "温县",
						"id": "410825"
					},
					{
						"text": "沁阳市",
						"id": "410882"
					},
					{
						"text": "孟州市",
						"id": "410883"
					}
				]
			},
			{
				"text": "濮阳市",
				"id": "410900",
				"children": [
					{
						"text": "华龙区",
						"id": "410902"
					},
					{
						"text": "清丰县",
						"id": "410922"
					},
					{
						"text": "南乐县",
						"id": "410923"
					},
					{
						"text": "范县",
						"id": "410926"
					},
					{
						"text": "台前县",
						"id": "410927"
					},
					{
						"text": "濮阳县",
						"id": "410928"
					}
				]
			},
			{
				"text": "许昌市",
				"id": "411000",
				"children": [
					{
						"text": "魏都区",
						"id": "411002"
					},
					{
						"text": "建安区",
						"id": "411023"
					},
					{
						"text": "鄢陵县",
						"id": "411024"
					},
					{
						"text": "襄城县",
						"id": "411025"
					},
					{
						"text": "禹州市",
						"id": "411081"
					},
					{
						"text": "长葛市",
						"id": "411082"
					}
				]
			},
			{
				"text": "漯河市",
				"id": "411100",
				"children": [
					{
						"text": "源汇区",
						"id": "411102"
					},
					{
						"text": "郾城区",
						"id": "411103"
					},
					{
						"text": "召陵区",
						"id": "411104"
					},
					{
						"text": "舞阳县",
						"id": "411121"
					},
					{
						"text": "临颍县",
						"id": "411122"
					}
				]
			},
			{
				"text": "三门峡市",
				"id": "411200",
				"children": [
					{
						"text": "湖滨区",
						"id": "411202"
					},
					{
						"text": "渑池县",
						"id": "411221"
					},
					{
						"text": "陕县",
						"id": "411222"
					},
					{
						"text": "卢氏县",
						"id": "411224"
					},
					{
						"text": "义马市",
						"id": "411281"
					},
					{
						"text": "灵宝市",
						"id": "411282"
					}
				]
			},
			{
				"text": "南阳市",
				"id": "411300",
				"children": [
					{
						"text": "宛城区",
						"id": "411302"
					},
					{
						"text": "卧龙区",
						"id": "411303"
					},
					{
						"text": "南召县",
						"id": "411321"
					},
					{
						"text": "方城县",
						"id": "411322"
					},
					{
						"text": "西峡县",
						"id": "411323"
					},
					{
						"text": "镇平县",
						"id": "411324"
					},
					{
						"text": "内乡县",
						"id": "411325"
					},
					{
						"text": "淅川县",
						"id": "411326"
					},
					{
						"text": "社旗县",
						"id": "411327"
					},
					{
						"text": "唐河县",
						"id": "411328"
					},
					{
						"text": "新野县",
						"id": "411329"
					},
					{
						"text": "桐柏县",
						"id": "411330"
					},
					{
						"text": "邓州市",
						"id": "411381"
					}
				]
			},
			{
				"text": "商丘市",
				"id": "411400",
				"children": [
					{
						"text": "梁园区",
						"id": "411402"
					},
					{
						"text": "睢阳区",
						"id": "411403"
					},
					{
						"text": "民权县",
						"id": "411421"
					},
					{
						"text": "睢县",
						"id": "411422"
					},
					{
						"text": "宁陵县",
						"id": "411423"
					},
					{
						"text": "柘城县",
						"id": "411424"
					},
					{
						"text": "虞城县",
						"id": "411425"
					},
					{
						"text": "夏邑县",
						"id": "411426"
					},
					{
						"text": "永城市",
						"id": "411481"
					}
				]
			},
			{
				"text": "信阳市",
				"id": "411500",
				"children": [
					{
						"text": "浉河区",
						"id": "411502"
					},
					{
						"text": "平桥区",
						"id": "411503"
					},
					{
						"text": "罗山县",
						"id": "411521"
					},
					{
						"text": "光山县",
						"id": "411522"
					},
					{
						"text": "新县",
						"id": "411523"
					},
					{
						"text": "商城县",
						"id": "411524"
					},
					{
						"text": "固始县",
						"id": "411525"
					},
					{
						"text": "潢川县",
						"id": "411526"
					},
					{
						"text": "淮滨县",
						"id": "411527"
					},
					{
						"text": "息县",
						"id": "411528"
					}
				]
			},
			{
				"text": "周口市",
				"id": "411600",
				"children": [
					{
						"text": "川汇区",
						"id": "411602"
					},
					{
						"text": "扶沟县",
						"id": "411621"
					},
					{
						"text": "西华县",
						"id": "411622"
					},
					{
						"text": "商水县",
						"id": "411623"
					},
					{
						"text": "沈丘县",
						"id": "411624"
					},
					{
						"text": "郸城县",
						"id": "411625"
					},
					{
						"text": "淮阳县",
						"id": "411626"
					},
					{
						"text": "太康县",
						"id": "411627"
					},
					{
						"text": "鹿邑县",
						"id": "411628"
					},
					{
						"text": "项城市",
						"id": "411681"
					}
				]
			},
			{
				"text": "驻马店市",
				"id": "411700",
				"children": [
					{
						"text": "驿城区",
						"id": "411702"
					},
					{
						"text": "西平县",
						"id": "411721"
					},
					{
						"text": "上蔡县",
						"id": "411722"
					},
					{
						"text": "平舆县",
						"id": "411723"
					},
					{
						"text": "正阳县",
						"id": "411724"
					},
					{
						"text": "确山县",
						"id": "411725"
					},
					{
						"text": "泌阳县",
						"id": "411726"
					},
					{
						"text": "汝南县",
						"id": "411727"
					},
					{
						"text": "遂平县",
						"id": "411728"
					},
					{
						"text": "新蔡县",
						"id": "411729"
					},
					{
						"text": "开发区",
						"id": "411730"
					}
				]
			},
			{
				"text": "济源市",
				"id": "419001",
				"children": [
					{
						"text": "济源市",
						"id": "419001"
					}
				]
			}
		]
	},
	{
		"id": "420000",
		"text": "湖北",
		"children": [
			{
				"text": "武汉市",
				"id": "420100",
				"children": [
					{
						"text": "江岸区",
						"id": "420102"
					},
					{
						"text": "江汉区",
						"id": "420103"
					},
					{
						"text": "硚口区",
						"id": "420104"
					},
					{
						"text": "汉阳区",
						"id": "420105"
					},
					{
						"text": "武昌区",
						"id": "420106"
					},
					{
						"text": "青山区",
						"id": "420107"
					},
					{
						"text": "洪山区",
						"id": "420111"
					},
					{
						"text": "东西湖区",
						"id": "420112"
					},
					{
						"text": "汉南区",
						"id": "420113"
					},
					{
						"text": "蔡甸区",
						"id": "420114"
					},
					{
						"text": "江夏区",
						"id": "420115"
					},
					{
						"text": "黄陂区",
						"id": "420116"
					},
					{
						"text": "新洲区",
						"id": "420117"
					},
					{
						"text": "汉口北",
						"id": "420118"
					},
					{
						"text": "东湖高新",
						"id": "420119"
					},
					{
						"text": "阳逻",
						"id": "420120"
					},
					{
						"text": "开发区",
						"id": "420121"
					}
				]
			},
			{
				"text": "黄石市",
				"id": "420200",
				"children": [
					{
						"text": "黄石港区",
						"id": "420202"
					},
					{
						"text": "西塞山区",
						"id": "420203"
					},
					{
						"text": "下陆区",
						"id": "420204"
					},
					{
						"text": "铁山区",
						"id": "420205"
					},
					{
						"text": "阳新县",
						"id": "420222"
					},
					{
						"text": "大冶市",
						"id": "420281"
					}
				]
			},
			{
				"text": "十堰市",
				"id": "420300",
				"children": [
					{
						"text": "茅箭区",
						"id": "420302"
					},
					{
						"text": "张湾区",
						"id": "420303"
					},
					{
						"text": "郧县",
						"id": "420321"
					},
					{
						"text": "郧西县",
						"id": "420322"
					},
					{
						"text": "竹山县",
						"id": "420323"
					},
					{
						"text": "竹溪县",
						"id": "420324"
					},
					{
						"text": "房县",
						"id": "420325"
					},
					{
						"text": "丹江口市",
						"id": "420381"
					}
				]
			},
			{
				"text": "宜昌市",
				"id": "420500",
				"children": [
					{
						"text": "西陵区",
						"id": "420502"
					},
					{
						"text": "伍家岗区",
						"id": "420503"
					},
					{
						"text": "点军区",
						"id": "420504"
					},
					{
						"text": "猇亭区",
						"id": "420505"
					},
					{
						"text": "夷陵区",
						"id": "420506"
					},
					{
						"text": "远安县",
						"id": "420525"
					},
					{
						"text": "兴山县",
						"id": "420526"
					},
					{
						"text": "秭归县",
						"id": "420527"
					},
					{
						"text": "长阳土家族自治县",
						"id": "420528"
					},
					{
						"text": "五峰土家族自治县",
						"id": "420529"
					},
					{
						"text": "宜都市",
						"id": "420581"
					},
					{
						"text": "当阳市",
						"id": "420582"
					},
					{
						"text": "枝江市",
						"id": "420583"
					}
				]
			},
			{
				"text": "襄阳市",
				"id": "420600",
				"children": [
					{
						"text": "襄城区",
						"id": "420602"
					},
					{
						"text": "樊城区",
						"id": "420606"
					},
					{
						"text": "襄州区",
						"id": "420607"
					},
					{
						"text": "南漳县",
						"id": "420624"
					},
					{
						"text": "谷城县",
						"id": "420625"
					},
					{
						"text": "保康县",
						"id": "420626"
					},
					{
						"text": "老河口市",
						"id": "420682"
					},
					{
						"text": "枣阳市",
						"id": "420683"
					},
					{
						"text": "宜城市",
						"id": "420684"
					}
				]
			},
			{
				"text": "鄂州市",
				"id": "420700",
				"children": [
					{
						"text": "梁子湖区",
						"id": "420702"
					},
					{
						"text": "华容区",
						"id": "420703"
					},
					{
						"text": "鄂城区",
						"id": "420704"
					}
				]
			},
			{
				"text": "荆门市",
				"id": "420800",
				"children": [
					{
						"text": "东宝区",
						"id": "420802"
					},
					{
						"text": "掇刀区",
						"id": "420804"
					},
					{
						"text": "京山县",
						"id": "420821"
					},
					{
						"text": "沙洋县",
						"id": "420822"
					},
					{
						"text": "钟祥市",
						"id": "420881"
					}
				]
			},
			{
				"text": "孝感市",
				"id": "420900",
				"children": [
					{
						"text": "孝南区",
						"id": "420902"
					},
					{
						"text": "孝昌县",
						"id": "420921"
					},
					{
						"text": "大悟县",
						"id": "420922"
					},
					{
						"text": "云梦县",
						"id": "420923"
					},
					{
						"text": "应城市",
						"id": "420981"
					},
					{
						"text": "安陆市",
						"id": "420982"
					},
					{
						"text": "汉川市",
						"id": "420984"
					}
				]
			},
			{
				"text": "荆州市",
				"id": "421000",
				"children": [
					{
						"text": "沙市区",
						"id": "421002"
					},
					{
						"text": "荆州区",
						"id": "421003"
					},
					{
						"text": "公安县",
						"id": "421022"
					},
					{
						"text": "监利县",
						"id": "421023"
					},
					{
						"text": "江陵县",
						"id": "421024"
					},
					{
						"text": "石首市",
						"id": "421081"
					},
					{
						"text": "洪湖市",
						"id": "421083"
					},
					{
						"text": "松滋市",
						"id": "421087"
					}
				]
			},
			{
				"text": "黄冈市",
				"id": "421100",
				"children": [
					{
						"text": "黄州区",
						"id": "421102"
					},
					{
						"text": "团风县",
						"id": "421121"
					},
					{
						"text": "红安县",
						"id": "421122"
					},
					{
						"text": "罗田县",
						"id": "421123"
					},
					{
						"text": "英山县",
						"id": "421124"
					},
					{
						"text": "浠水县",
						"id": "421125"
					},
					{
						"text": "蕲春县",
						"id": "421126"
					},
					{
						"text": "黄梅县",
						"id": "421127"
					},
					{
						"text": "麻城市",
						"id": "421181"
					},
					{
						"text": "武穴市",
						"id": "421182"
					}
				]
			},
			{
				"text": "咸宁市",
				"id": "421200",
				"children": [
					{
						"text": "咸安区",
						"id": "421202"
					},
					{
						"text": "嘉鱼县",
						"id": "421221"
					},
					{
						"text": "通城县",
						"id": "421222"
					},
					{
						"text": "崇阳县",
						"id": "421223"
					},
					{
						"text": "通山县",
						"id": "421224"
					},
					{
						"text": "赤壁市",
						"id": "421281"
					}
				]
			},
			{
				"text": "随州市",
				"id": "421300",
				"children": [
					{
						"text": "曾都区",
						"id": "421303"
					},
					{
						"text": "随县",
						"id": "421321"
					},
					{
						"text": "广水市",
						"id": "421381"
					}
				]
			},
			{
				"text": "恩施土家族苗族自治州",
				"id": "422800",
				"children": [
					{
						"text": "恩施市",
						"id": "422801"
					},
					{
						"text": "利川市",
						"id": "422802"
					},
					{
						"text": "建始县",
						"id": "422822"
					},
					{
						"text": "巴东县",
						"id": "422823"
					},
					{
						"text": "宣恩县",
						"id": "422825"
					},
					{
						"text": "咸丰县",
						"id": "422826"
					},
					{
						"text": "来凤县",
						"id": "422827"
					},
					{
						"text": "鹤峰县",
						"id": "422828"
					}
				]
			},
			{
				"text": "仙桃市",
				"id": "429004",
				"children": [
					{
						"text": "仙桃市",
						"id": "429004"
					}
				]
			},
			{
				"text": "潜江市",
				"id": "429005",
				"children": [
					{
						"text": "潜江市",
						"id": "429005"
					}
				]
			},
			{
				"text": "天门市",
				"id": "429006",
				"children": [
					{
						"text": "天门市",
						"id": "429006"
					}
				]
			}
		]
	},
	{
		"id": "430000",
		"text": "湖南",
		"children": [
			{
				"text": "长沙市",
				"id": "430100",
				"children": [
					{
						"text": "芙蓉区",
						"id": "430102"
					},
					{
						"text": "天心区",
						"id": "430103"
					},
					{
						"text": "岳麓区",
						"id": "430104"
					},
					{
						"text": "开福区",
						"id": "430105"
					},
					{
						"text": "雨花区",
						"id": "430111"
					},
					{
						"text": "望城区",
						"id": "430112"
					},
					{
						"text": "长沙县",
						"id": "430121"
					},
					{
						"text": "宁乡县",
						"id": "430124"
					},
					{
						"text": "浏阳市",
						"id": "430181"
					}
				]
			},
			{
				"text": "株洲市",
				"id": "430200",
				"children": [
					{
						"text": "荷塘区",
						"id": "430202"
					},
					{
						"text": "芦淞区",
						"id": "430203"
					},
					{
						"text": "石峰区",
						"id": "430204"
					},
					{
						"text": "天元区",
						"id": "430211"
					},
					{
						"text": "株洲县",
						"id": "430221"
					},
					{
						"text": "攸县",
						"id": "430223"
					},
					{
						"text": "茶陵县",
						"id": "430224"
					},
					{
						"text": "炎陵县",
						"id": "430225"
					},
					{
						"text": "醴陵市",
						"id": "430281"
					}
				]
			},
			{
				"text": "湘潭市",
				"id": "430300",
				"children": [
					{
						"text": "雨湖区",
						"id": "430302"
					},
					{
						"text": "岳塘区",
						"id": "430304"
					},
					{
						"text": "湘潭县",
						"id": "430321"
					},
					{
						"text": "湘乡市",
						"id": "430381"
					},
					{
						"text": "韶山市",
						"id": "430382"
					},
					{
						"text": "九华区",
						"id": "430409"
					}
				]
			},
			{
				"text": "衡阳市",
				"id": "430400",
				"children": [
					{
						"text": "珠晖区",
						"id": "430405"
					},
					{
						"text": "雁峰区",
						"id": "430406"
					},
					{
						"text": "石鼓区",
						"id": "430407"
					},
					{
						"text": "蒸湘区",
						"id": "430408"
					},
					{
						"text": "南岳区",
						"id": "430412"
					},
					{
						"text": "衡阳县",
						"id": "430421"
					},
					{
						"text": "衡南县",
						"id": "430422"
					},
					{
						"text": "衡山县",
						"id": "430423"
					},
					{
						"text": "衡东县",
						"id": "430424"
					},
					{
						"text": "祁东县",
						"id": "430426"
					},
					{
						"text": "耒阳市",
						"id": "430481"
					},
					{
						"text": "常宁市",
						"id": "430482"
					}
				]
			},
			{
				"text": "邵阳市",
				"id": "430500",
				"children": [
					{
						"text": "双清区",
						"id": "430502"
					},
					{
						"text": "大祥区",
						"id": "430503"
					},
					{
						"text": "北塔区",
						"id": "430511"
					},
					{
						"text": "邵东县",
						"id": "430521"
					},
					{
						"text": "新邵县",
						"id": "430522"
					},
					{
						"text": "邵阳县",
						"id": "430523"
					},
					{
						"text": "隆回县",
						"id": "430524"
					},
					{
						"text": "洞口县",
						"id": "430525"
					},
					{
						"text": "绥宁县",
						"id": "430527"
					},
					{
						"text": "新宁县",
						"id": "430528"
					},
					{
						"text": "城步苗族自治县",
						"id": "430529"
					},
					{
						"text": "武冈市",
						"id": "430581"
					}
				]
			},
			{
				"text": "岳阳市",
				"id": "430600",
				"children": [
					{
						"text": "岳阳楼区",
						"id": "430602"
					},
					{
						"text": "云溪区",
						"id": "430603"
					},
					{
						"text": "君山区",
						"id": "430611"
					},
					{
						"text": "岳阳县",
						"id": "430621"
					},
					{
						"text": "华容县",
						"id": "430623"
					},
					{
						"text": "湘阴县",
						"id": "430624"
					},
					{
						"text": "平江县",
						"id": "430626"
					},
					{
						"text": "汨罗市",
						"id": "430681"
					},
					{
						"text": "临湘市",
						"id": "430682"
					}
				]
			},
			{
				"text": "常德市",
				"id": "430700",
				"children": [
					{
						"text": "武陵区",
						"id": "430702"
					},
					{
						"text": "鼎城区",
						"id": "430703"
					},
					{
						"text": "安乡县",
						"id": "430721"
					},
					{
						"text": "汉寿县",
						"id": "430722"
					},
					{
						"text": "澧县",
						"id": "430723"
					},
					{
						"text": "临澧县",
						"id": "430724"
					},
					{
						"text": "桃源县",
						"id": "430725"
					},
					{
						"text": "石门县",
						"id": "430726"
					},
					{
						"text": "津市市",
						"id": "430781"
					}
				]
			},
			{
				"text": "张家界市",
				"id": "430800",
				"children": [
					{
						"text": "永定区",
						"id": "430802"
					},
					{
						"text": "武陵源区",
						"id": "430811"
					},
					{
						"text": "慈利县",
						"id": "430821"
					},
					{
						"text": "桑植县",
						"id": "430822"
					}
				]
			},
			{
				"text": "益阳市",
				"id": "430900",
				"children": [
					{
						"text": "资阳区",
						"id": "430902"
					},
					{
						"text": "赫山区",
						"id": "430903"
					},
					{
						"text": "南县",
						"id": "430921"
					},
					{
						"text": "桃江县",
						"id": "430922"
					},
					{
						"text": "安化县",
						"id": "430923"
					},
					{
						"text": "沅江市",
						"id": "430981"
					},
					{
						"text": "高新区",
						"id": "430924"
					}
				]
			},
			{
				"text": "郴州市",
				"id": "431000",
				"children": [
					{
						"text": "北湖区",
						"id": "431002"
					},
					{
						"text": "苏仙区",
						"id": "431003"
					},
					{
						"text": "桂阳县",
						"id": "431021"
					},
					{
						"text": "宜章县",
						"id": "431022"
					},
					{
						"text": "永兴县",
						"id": "431023"
					},
					{
						"text": "嘉禾县",
						"id": "431024"
					},
					{
						"text": "临武县",
						"id": "431025"
					},
					{
						"text": "汝城县",
						"id": "431026"
					},
					{
						"text": "桂东县",
						"id": "431027"
					},
					{
						"text": "安仁县",
						"id": "431028"
					},
					{
						"text": "资兴市",
						"id": "431081"
					}
				]
			},
			{
				"text": "永州市",
				"id": "431100",
				"children": [
					{
						"text": "零陵区",
						"id": "431102"
					},
					{
						"text": "冷水滩区",
						"id": "431103"
					},
					{
						"text": "祁阳县",
						"id": "431121"
					},
					{
						"text": "东安县",
						"id": "431122"
					},
					{
						"text": "双牌县",
						"id": "431123"
					},
					{
						"text": "道县",
						"id": "431124"
					},
					{
						"text": "江永县",
						"id": "431125"
					},
					{
						"text": "宁远县",
						"id": "431126"
					},
					{
						"text": "蓝山县",
						"id": "431127"
					},
					{
						"text": "新田县",
						"id": "431128"
					},
					{
						"text": "江华瑶族自治县",
						"id": "431129"
					}
				]
			},
			{
				"text": "怀化市",
				"id": "431200",
				"children": [
					{
						"text": "鹤城区",
						"id": "431202"
					},
					{
						"text": "中方县",
						"id": "431221"
					},
					{
						"text": "沅陵县",
						"id": "431222"
					},
					{
						"text": "辰溪县",
						"id": "431223"
					},
					{
						"text": "溆浦县",
						"id": "431224"
					},
					{
						"text": "会同县",
						"id": "431225"
					},
					{
						"text": "麻阳苗族自治县",
						"id": "431226"
					},
					{
						"text": "新晃侗族自治县",
						"id": "431227"
					},
					{
						"text": "芷江侗族自治县",
						"id": "431228"
					},
					{
						"text": "靖州苗族侗族自治县",
						"id": "431229"
					},
					{
						"text": "通道侗族自治县",
						"id": "431230"
					},
					{
						"text": "洪江市",
						"id": "431281"
					}
				]
			},
			{
				"text": "娄底市",
				"id": "431300",
				"children": [
					{
						"text": "娄星区",
						"id": "431302"
					},
					{
						"text": "双峰县",
						"id": "431321"
					},
					{
						"text": "新化县",
						"id": "431322"
					},
					{
						"text": "冷水江市",
						"id": "431381"
					},
					{
						"text": "涟源市",
						"id": "431382"
					}
				]
			},
			{
				"text": "湘西土家族苗族自治州",
				"id": "433100",
				"children": [
					{
						"text": "吉首市",
						"id": "433101"
					},
					{
						"text": "泸溪县",
						"id": "433122"
					},
					{
						"text": "凤凰县",
						"id": "433123"
					},
					{
						"text": "花垣县",
						"id": "433124"
					},
					{
						"text": "保靖县",
						"id": "433125"
					},
					{
						"text": "古丈县",
						"id": "433126"
					},
					{
						"text": "永顺县",
						"id": "433127"
					},
					{
						"text": "龙山县",
						"id": "433130"
					}
				]
			}
		]
	},
	{
		"id": "440000",
		"text": "广东",
		"children": [
			{
				"text": "广州市",
				"id": "440100",
				"children": [
					{
						"text": "市辖区",
						"id": "440101"
					},
					{
						"text": "荔湾区",
						"id": "440103"
					},
					{
						"text": "越秀区",
						"id": "440104"
					},
					{
						"text": "海珠区",
						"id": "440105"
					},
					{
						"text": "天河区",
						"id": "440106"
					},
					{
						"text": "白云区",
						"id": "440111"
					},
					{
						"text": "黄埔区",
						"id": "440112"
					},
					{
						"text": "番禺区",
						"id": "440113"
					},
					{
						"text": "花都区",
						"id": "440114"
					},
					{
						"text": "南沙区",
						"id": "440115"
					},
					{
						"text": "增城区",
						"id": "440183"
					},
					{
						"text": "从化区",
						"id": "440184"
					}
				]
			},
			{
				"text": "深圳市",
				"id": "440300",
				"children": [
					{
						"text": "罗湖区",
						"id": "440303"
					},
					{
						"text": "福田区",
						"id": "440304"
					},
					{
						"text": "南山区",
						"id": "440305"
					},
					{
						"text": "宝安区",
						"id": "440306"
					},
					{
						"text": "龙岗区",
						"id": "440307"
					},
					{
						"text": "盐田区",
						"id": "440308"
					},
					{
						"text": "龙华新区",
						"id": "440309"
					},
					{
						"text": "光明新区",
						"id": "440310"
					},
					{
						"text": "坪山新区",
						"id": "440311"
					},
					{
						"text": "大鹏新区",
						"id": "440312"
					},
					{
						"text": "深汕合作区",
						"id": "440313"
					}
				]
			},
			{
				"text": "东莞市",
				"id": "441900",
				"children": [
					{
						"text": "莞城",
						"id": "441933"
					},
					{
						"text": "南城",
						"id": "441934"
					},
					{
						"text": "东城",
						"id": "441935"
					},
					{
						"text": "万江",
						"id": "441936"
					},
					{
						"text": "长安",
						"id": "441937"
					},
					{
						"text": "虎门",
						"id": "441938"
					},
					{
						"text": "塘厦",
						"id": "441939"
					},
					{
						"text": "凤岗",
						"id": "441940"
					},
					{
						"text": "高埗",
						"id": "441941"
					},
					{
						"text": "石碣",
						"id": "441942"
					},
					{
						"text": "厚街",
						"id": "441943"
					},
					{
						"text": "石龙",
						"id": "441944"
					},
					{
						"text": "大岭山",
						"id": "441945"
					},
					{
						"text": "茶山",
						"id": "441946"
					},
					{
						"text": "石排",
						"id": "441947"
					},
					{
						"text": "企石",
						"id": "441948"
					},
					{
						"text": "横沥",
						"id": "441949"
					},
					{
						"text": "桥头",
						"id": "441950"
					},
					{
						"text": "谢岗",
						"id": "441951"
					},
					{
						"text": "东坑",
						"id": "441952"
					},
					{
						"text": "樟木头",
						"id": "441953"
					},
					{
						"text": "常平",
						"id": "441954"
					},
					{
						"text": "寮步",
						"id": "441955"
					},
					{
						"text": "大朗",
						"id": "441956"
					},
					{
						"text": "黄江",
						"id": "441957"
					},
					{
						"text": "清溪",
						"id": "441958"
					},
					{
						"text": "沙田",
						"id": "441959"
					},
					{
						"text": "道滘",
						"id": "441960"
					},
					{
						"text": "洪梅",
						"id": "441961"
					},
					{
						"text": "麻涌",
						"id": "441962"
					},
					{
						"text": "中堂",
						"id": "441963"
					},
					{
						"text": "望牛墩",
						"id": "441964"
					},
					{
						"text": "松山湖",
						"id": "441965"
					}
				]
			},
			{
				"text": "佛山市",
				"id": "440600",
				"children": [
					{
						"text": "禅城区",
						"id": "440604"
					},
					{
						"text": "南海区",
						"id": "440605"
					},
					{
						"text": "顺德区",
						"id": "440606"
					},
					{
						"text": "三水区",
						"id": "440607"
					},
					{
						"text": "高明区",
						"id": "440608"
					}
				]
			},
			{
				"text": "惠州市",
				"id": "441300",
				"children": [
					{
						"text": "惠城区",
						"id": "441302"
					},
					{
						"text": "惠阳区",
						"id": "441303"
					},
					{
						"text": "博罗县",
						"id": "441322"
					},
					{
						"text": "惠东县",
						"id": "441323"
					},
					{
						"text": "龙门县",
						"id": "441324"
					},
					{
						"text": "仲恺区",
						"id": "441325"
					},
					{
						"text": "大亚湾区",
						"id": "441326"
					},
					{
						"text": "市辖区",
						"id": "441301"
					}
				]
			},
			{
				"text": "珠海市",
				"id": "440400",
				"children": [
					{
						"text": "香洲区",
						"id": "440402"
					},
					{
						"text": "斗门区",
						"id": "440403"
					},
					{
						"text": "金湾区",
						"id": "440404"
					},
					{
						"text": "市辖区",
						"id": "440401"
					}
				]
			},
			{
				"text": "中山市",
				"id": "442000",
				"children": [
					{
						"text": "石岐区街道",
						"id": "442043"
					},
					{
						"text": "东区街道",
						"id": "442020"
					},
					{
						"text": "西区街道",
						"id": "442021"
					},
					{
						"text": "南区街道",
						"id": "442022"
					},
					{
						"text": "五桂山街道",
						"id": "442023"
					},
					{
						"text": "火炬开发区",
						"id": "442024"
					},
					{
						"text": "小榄镇",
						"id": "442025"
					},
					{
						"text": "黄圃镇",
						"id": "442026"
					},
					{
						"text": "民众镇",
						"id": "442027"
					},
					{
						"text": "东凤镇",
						"id": "442028"
					},
					{
						"text": "东升镇",
						"id": "442029"
					},
					{
						"text": "古镇镇",
						"id": "442030"
					},
					{
						"text": "沙溪镇",
						"id": "442031"
					},
					{
						"text": "坦洲镇",
						"id": "442032"
					},
					{
						"text": "港口镇",
						"id": "442033"
					},
					{
						"text": "三角镇",
						"id": "442034"
					},
					{
						"text": "横栏镇",
						"id": "442035"
					},
					{
						"text": "南头镇",
						"id": "442036"
					},
					{
						"text": "阜沙镇",
						"id": "442037"
					},
					{
						"text": "南朗镇",
						"id": "442038"
					},
					{
						"text": "三乡镇",
						"id": "442039"
					},
					{
						"text": "板芙镇",
						"id": "442040"
					},
					{
						"text": "大涌镇",
						"id": "442041"
					},
					{
						"text": "神湾镇",
						"id": "442042"
					}
				]
			},
			{
				"text": "江门市",
				"id": "440700",
				"children": [
					{
						"text": "蓬江区",
						"id": "440703"
					},
					{
						"text": "江海区",
						"id": "440704"
					},
					{
						"text": "新会区",
						"id": "440705"
					},
					{
						"text": "台山市",
						"id": "440781"
					},
					{
						"text": "开平市",
						"id": "440783"
					},
					{
						"text": "鹤山市",
						"id": "440784"
					},
					{
						"text": "恩平市",
						"id": "440785"
					}
				]
			},
			{
				"text": "汕头市",
				"id": "440500",
				"children": [
					{
						"text": "龙湖区",
						"id": "440507"
					},
					{
						"text": "金平区",
						"id": "440511"
					},
					{
						"text": "濠江区",
						"id": "440512"
					},
					{
						"text": "潮阳区",
						"id": "440513"
					},
					{
						"text": "潮南区",
						"id": "440514"
					},
					{
						"text": "澄海区",
						"id": "440515"
					},
					{
						"text": "南澳县",
						"id": "440523"
					}
				]
			},
			{
				"text": "湛江市",
				"id": "440800",
				"children": [
					{
						"text": "赤坎区",
						"id": "440802"
					},
					{
						"text": "霞山区",
						"id": "440803"
					},
					{
						"text": "坡头区",
						"id": "440804"
					},
					{
						"text": "麻章区",
						"id": "440811"
					},
					{
						"text": "遂溪县",
						"id": "440823"
					},
					{
						"text": "徐闻县",
						"id": "440825"
					},
					{
						"text": "廉江市",
						"id": "440881"
					},
					{
						"text": "雷州市",
						"id": "440882"
					},
					{
						"text": "吴川市",
						"id": "440883"
					}
				]
			},
			{
				"text": "揭阳市",
				"id": "445200",
				"children": [
					{
						"text": "榕城区",
						"id": "445202"
					},
					{
						"text": "揭东区",
						"id": "445221"
					},
					{
						"text": "揭西县",
						"id": "445222"
					},
					{
						"text": "惠来县",
						"id": "445224"
					},
					{
						"text": "普宁市",
						"id": "445281"
					}
				]
			},
			{
				"text": "肇庆市",
				"id": "441200",
				"children": [
					{
						"text": "端州区",
						"id": "441202"
					},
					{
						"text": "鼎湖区",
						"id": "441203"
					},
					{
						"text": "广宁县",
						"id": "441223"
					},
					{
						"text": "怀集县",
						"id": "441224"
					},
					{
						"text": "封开县",
						"id": "441225"
					},
					{
						"text": "德庆县",
						"id": "441226"
					},
					{
						"text": "高要区",
						"id": "441283"
					},
					{
						"text": "四会市",
						"id": "441284"
					},
					{
						"text": "高新区",
						"id": "441285"
					}
				]
			},
			{
				"text": "清远市",
				"id": "441800",
				"children": [
					{
						"text": "清城区",
						"id": "441802"
					},
					{
						"text": "佛冈县",
						"id": "441821"
					},
					{
						"text": "阳山县",
						"id": "441823"
					},
					{
						"text": "连山壮族瑶族自治县",
						"id": "441825"
					},
					{
						"text": "连南瑶族自治县",
						"id": "441826"
					},
					{
						"text": "清新区",
						"id": "441827"
					},
					{
						"text": "连州市",
						"id": "441882"
					},
					{
						"text": "英德市",
						"id": "441883"
					}
				]
			},
			{
				"text": "韶关市",
				"id": "440200",
				"children": [
					{
						"text": "武江区",
						"id": "440203"
					},
					{
						"text": "浈江区",
						"id": "440204"
					},
					{
						"text": "曲江区",
						"id": "440205"
					},
					{
						"text": "始兴县",
						"id": "440222"
					},
					{
						"text": "仁化县",
						"id": "440224"
					},
					{
						"text": "翁源县",
						"id": "440229"
					},
					{
						"text": "乳源瑶族自治县",
						"id": "440232"
					},
					{
						"text": "新丰县",
						"id": "440233"
					},
					{
						"text": "乐昌市",
						"id": "440281"
					},
					{
						"text": "南雄市",
						"id": "440282"
					}
				]
			},
			{
				"text": "潮州市",
				"id": "445100",
				"children": [
					{
						"text": "湘桥区",
						"id": "445102"
					},
					{
						"text": "潮安区",
						"id": "445121"
					},
					{
						"text": "饶平县",
						"id": "445122"
					}
				]
			},
			{
				"text": "茂名市",
				"id": "440900",
				"children": [
					{
						"text": "茂南区",
						"id": "440902"
					},
					{
						"text": "电白区",
						"id": "440923"
					},
					{
						"text": "高州市",
						"id": "440981"
					},
					{
						"text": "化州市",
						"id": "440982"
					},
					{
						"text": "信宜市",
						"id": "440983"
					}
				]
			},
			{
				"text": "河源市",
				"id": "441600",
				"children": [
					{
						"text": "源城区",
						"id": "441602"
					},
					{
						"text": "紫金县",
						"id": "441621"
					},
					{
						"text": "龙川县",
						"id": "441622"
					},
					{
						"text": "连平县",
						"id": "441623"
					},
					{
						"text": "和平县",
						"id": "441624"
					},
					{
						"text": "东源县",
						"id": "441625"
					},
					{
						"text": "市辖区",
						"id": "441601"
					}
				]
			},
			{
				"text": "汕尾市",
				"id": "441500",
				"children": [
					{
						"text": "城区",
						"id": "441502"
					},
					{
						"text": "海丰县",
						"id": "441521"
					},
					{
						"text": "陆河县",
						"id": "441523"
					},
					{
						"text": "陆丰市",
						"id": "441581"
					}
				]
			},
			{
				"text": "阳江市",
				"id": "441700",
				"children": [
					{
						"text": "江城区",
						"id": "441702"
					},
					{
						"text": "阳西县",
						"id": "441721"
					},
					{
						"text": "阳东区",
						"id": "441723"
					},
					{
						"text": "阳春市",
						"id": "441781"
					}
				]
			},
			{
				"text": "梅州市",
				"id": "441400",
				"children": [
					{
						"text": "梅江区",
						"id": "441402"
					},
					{
						"text": "梅县区",
						"id": "441421"
					},
					{
						"text": "大埔县",
						"id": "441422"
					},
					{
						"text": "丰顺县",
						"id": "441423"
					},
					{
						"text": "五华县",
						"id": "441424"
					},
					{
						"text": "平远县",
						"id": "441426"
					},
					{
						"text": "蕉岭县",
						"id": "441427"
					},
					{
						"text": "兴宁市",
						"id": "441481"
					}
				]
			},
			{
				"text": "云浮市",
				"id": "445300",
				"children": [
					{
						"text": "云城区",
						"id": "445302"
					},
					{
						"text": "新兴县",
						"id": "445321"
					},
					{
						"text": "郁南县",
						"id": "445322"
					},
					{
						"text": "云安区",
						"id": "445323"
					},
					{
						"text": "罗定市",
						"id": "445381"
					}
				]
			}
		]
	},
	{
		"id": "450000",
		"text": "广西",
		"children": [
			{
				"text": "南宁市",
				"id": "450100",
				"children": [
					{
						"text": "兴宁区",
						"id": "450102"
					},
					{
						"text": "青秀区",
						"id": "450103"
					},
					{
						"text": "江南区",
						"id": "450105"
					},
					{
						"text": "西乡塘区",
						"id": "450107"
					},
					{
						"text": "良庆区",
						"id": "450108"
					},
					{
						"text": "邕宁区",
						"id": "450109"
					},
					{
						"text": "武鸣区",
						"id": "450122"
					},
					{
						"text": "隆安县",
						"id": "450123"
					},
					{
						"text": "马山县",
						"id": "450124"
					},
					{
						"text": "上林县",
						"id": "450125"
					},
					{
						"text": "宾阳县",
						"id": "450126"
					},
					{
						"text": "横县",
						"id": "450127"
					}
				]
			},
			{
				"text": "柳州市",
				"id": "450200",
				"children": [
					{
						"text": "城中区",
						"id": "450202"
					},
					{
						"text": "鱼峰区",
						"id": "450203"
					},
					{
						"text": "柳南区",
						"id": "450204"
					},
					{
						"text": "柳北区",
						"id": "450205"
					},
					{
						"text": "柳江区",
						"id": "450221"
					},
					{
						"text": "柳城县",
						"id": "450222"
					},
					{
						"text": "鹿寨县",
						"id": "450223"
					},
					{
						"text": "融安县",
						"id": "450224"
					},
					{
						"text": "融水苗族自治县",
						"id": "450225"
					},
					{
						"text": "三江侗族自治县",
						"id": "450226"
					}
				]
			},
			{
				"text": "桂林市",
				"id": "450300",
				"children": [
					{
						"text": "秀峰区",
						"id": "450302"
					},
					{
						"text": "叠彩区",
						"id": "450303"
					},
					{
						"text": "象山区",
						"id": "450304"
					},
					{
						"text": "七星区",
						"id": "450305"
					},
					{
						"text": "雁山区",
						"id": "450311"
					},
					{
						"text": "阳朔县",
						"id": "450321"
					},
					{
						"text": "临桂区",
						"id": "450322"
					},
					{
						"text": "灵川县",
						"id": "450323"
					},
					{
						"text": "全州县",
						"id": "450324"
					},
					{
						"text": "兴安县",
						"id": "450325"
					},
					{
						"text": "永福县",
						"id": "450326"
					},
					{
						"text": "灌阳县",
						"id": "450327"
					},
					{
						"text": "龙胜各族自治县",
						"id": "450328"
					},
					{
						"text": "资源县",
						"id": "450329"
					},
					{
						"text": "平乐县",
						"id": "450330"
					},
					{
						"text": "荔浦市",
						"id": "450331"
					},
					{
						"text": "恭城瑶族自治县",
						"id": "450332"
					}
				]
			},
			{
				"text": "梧州市",
				"id": "450400",
				"children": [
					{
						"text": "万秀区",
						"id": "450403"
					},
					{
						"text": "长洲区",
						"id": "450405"
					},
					{
						"text": "苍梧县",
						"id": "450421"
					},
					{
						"text": "藤县",
						"id": "450422"
					},
					{
						"text": "蒙山县",
						"id": "450423"
					},
					{
						"text": "岑溪市",
						"id": "450481"
					}
				]
			},
			{
				"text": "北海市",
				"id": "450500",
				"children": [
					{
						"text": "海城区",
						"id": "450502"
					},
					{
						"text": "银海区",
						"id": "450503"
					},
					{
						"text": "铁山港区",
						"id": "450512"
					},
					{
						"text": "合浦县",
						"id": "450521"
					}
				]
			},
			{
				"text": "防城港市",
				"id": "450600",
				"children": [
					{
						"text": "港口区",
						"id": "450602"
					},
					{
						"text": "防城区",
						"id": "450603"
					},
					{
						"text": "上思县",
						"id": "450621"
					},
					{
						"text": "东兴市",
						"id": "450681"
					}
				]
			},
			{
				"text": "钦州市",
				"id": "450700",
				"children": [
					{
						"text": "钦南区",
						"id": "450702"
					},
					{
						"text": "钦北区",
						"id": "450703"
					},
					{
						"text": "灵山县",
						"id": "450721"
					},
					{
						"text": "浦北县",
						"id": "450722"
					}
				]
			},
			{
				"text": "贵港市",
				"id": "450800",
				"children": [
					{
						"text": "港北区",
						"id": "450802"
					},
					{
						"text": "港南区",
						"id": "450803"
					},
					{
						"text": "覃塘区",
						"id": "450804"
					},
					{
						"text": "平南县",
						"id": "450821"
					},
					{
						"text": "桂平市",
						"id": "450881"
					}
				]
			},
			{
				"text": "玉林市",
				"id": "450900",
				"children": [
					{
						"text": "玉州区",
						"id": "450902"
					},
					{
						"text": "容县",
						"id": "450921"
					},
					{
						"text": "陆川县",
						"id": "450922"
					},
					{
						"text": "博白县",
						"id": "450923"
					},
					{
						"text": "兴业县",
						"id": "450924"
					},
					{
						"text": "北流市",
						"id": "450981"
					}
				]
			},
			{
				"text": "百色市",
				"id": "451000",
				"children": [
					{
						"text": "右江区",
						"id": "451002"
					},
					{
						"text": "田阳区",
						"id": "451021"
					},
					{
						"text": "田东县",
						"id": "451022"
					},
					{
						"text": "平果市",
						"id": "451023"
					},
					{
						"text": "德保县",
						"id": "451024"
					},
					{
						"text": "靖西市",
						"id": "451025"
					},
					{
						"text": "那坡县",
						"id": "451026"
					},
					{
						"text": "凌云县",
						"id": "451027"
					},
					{
						"text": "乐业县",
						"id": "451028"
					},
					{
						"text": "田林县",
						"id": "451029"
					},
					{
						"text": "西林县",
						"id": "451030"
					},
					{
						"text": "隆林各族自治县",
						"id": "451031"
					}
				]
			},
			{
				"text": "贺州市",
				"id": "451100",
				"children": [
					{
						"text": "八步区",
						"id": "451102"
					},
					{
						"text": "昭平县",
						"id": "451121"
					},
					{
						"text": "钟山县",
						"id": "451122"
					},
					{
						"text": "富川瑶族自治县",
						"id": "451123"
					},
					{
						"text": "平桂区",
						"id": "451124"
					}
				]
			},
			{
				"text": "河池市",
				"id": "451200",
				"children": [
					{
						"text": "金城江区",
						"id": "451202"
					},
					{
						"text": "南丹县",
						"id": "451221"
					},
					{
						"text": "天峨县",
						"id": "451222"
					},
					{
						"text": "凤山县",
						"id": "451223"
					},
					{
						"text": "东兰县",
						"id": "451224"
					},
					{
						"text": "罗城仫佬族自治县",
						"id": "451225"
					},
					{
						"text": "环江毛南族自治县",
						"id": "451226"
					},
					{
						"text": "巴马瑶族自治县",
						"id": "451227"
					},
					{
						"text": "都安瑶族自治县",
						"id": "451228"
					},
					{
						"text": "大化瑶族自治县",
						"id": "451229"
					},
					{
						"text": "宜州区",
						"id": "451281"
					}
				]
			},
			{
				"text": "来宾市",
				"id": "451300",
				"children": [
					{
						"text": "兴宾区",
						"id": "451302"
					},
					{
						"text": "忻城县",
						"id": "451321"
					},
					{
						"text": "象州县",
						"id": "451322"
					},
					{
						"text": "武宣县",
						"id": "451323"
					},
					{
						"text": "金秀瑶族自治县",
						"id": "451324"
					},
					{
						"text": "合山市",
						"id": "451381"
					}
				]
			},
			{
				"text": "崇左市",
				"id": "451400",
				"children": [
					{
						"text": "江州区",
						"id": "451402"
					},
					{
						"text": "扶绥县",
						"id": "451421"
					},
					{
						"text": "宁明县",
						"id": "451422"
					},
					{
						"text": "龙州县",
						"id": "451423"
					},
					{
						"text": "大新县",
						"id": "451424"
					},
					{
						"text": "天等县",
						"id": "451425"
					},
					{
						"text": "凭祥市",
						"id": "451481"
					}
				]
			}
		]
	},
	{
		"id": "460000",
		"text": "海南",
		"children": [
			{
				"text": "海口市",
				"id": "460100",
				"children": [
					{
						"text": "秀英区",
						"id": "460105"
					},
					{
						"text": "龙华区",
						"id": "460106"
					},
					{
						"text": "琼山区",
						"id": "460107"
					},
					{
						"text": "美兰区",
						"id": "460108"
					}
				]
			},
			{
				"text": "三亚市",
				"id": "460200",
				"children": [
					{
						"text": "吉阳区",
						"id": "460201"
					},
					{
						"text": "天涯区",
						"id": "460202"
					},
					{
						"text": "崖州区",
						"id": "460203"
					},
					{
						"text": "海棠区",
						"id": "460204"
					}
				]
			},
			{
				"text": "琼海市",
				"id": "460300",
				"children": [
					{
						"text": "琼海市",
						"id": "460301"
					}
				]
			},
			{
				"text": "儋州市",
				"id": "460400",
				"children": [
					{
						"text": "儋州市",
						"id": "460401"
					}
				]
			},
			{
				"text": "东方市",
				"id": "460500",
				"children": [
					{
						"text": "东方市",
						"id": "460501"
					}
				]
			},
			{
				"text": "万宁市",
				"id": "460600",
				"children": [
					{
						"text": "万宁市",
						"id": "460601"
					}
				]
			},
			{
				"text": "五指山市",
				"id": "460700",
				"children": [
					{
						"text": "五指山市",
						"id": "460701"
					}
				]
			},
			{
				"text": "文昌市",
				"id": "460800",
				"children": [
					{
						"text": "文昌市",
						"id": "460801"
					}
				]
			},
			{
				"text": "昌江县",
				"id": "460900",
				"children": [
					{
						"text": "昌江县",
						"id": "460901"
					}
				]
			},
			{
				"text": "临高县",
				"id": "461000",
				"children": [
					{
						"text": "临高县",
						"id": "461001"
					}
				]
			},
			{
				"text": "定安县",
				"id": "461100",
				"children": [
					{
						"text": "定安县",
						"id": "461101"
					}
				]
			},
			{
				"text": "琼中县",
				"id": "461200",
				"children": [
					{
						"text": "琼中县",
						"id": "461201"
					}
				]
			},
			{
				"text": "陵水县",
				"id": "461300",
				"children": [
					{
						"text": "陵水县",
						"id": "461301"
					}
				]
			},
			{
				"text": "乐东县",
				"id": "461400",
				"children": [
					{
						"text": "乐东县",
						"id": "461401"
					}
				]
			},
			{
				"text": "白沙县",
				"id": "461500",
				"children": [
					{
						"text": "白沙县",
						"id": "461501"
					}
				]
			},
			{
				"text": "屯昌县",
				"id": "461600",
				"children": [
					{
						"text": "屯昌县",
						"id": "461601"
					}
				]
			},
			{
				"text": "澄迈县",
				"id": "461700",
				"children": [
					{
						"text": "澄迈县",
						"id": "461701"
					}
				]
			},
			{
				"text": "保亭县",
				"id": "461800",
				"children": [
					{
						"text": "保亭县",
						"id": "461801"
					}
				]
			},
			{
				"text": "三沙市",
				"id": "461900",
				"children": [
					{
						"text": "三沙市",
						"id": "461901"
					}
				]
			}
		]
	},
	{
		"id": "500000",
		"text": "重庆",
		"children": [
			{
				"text": "重庆市",
				"id": "500100",
				"children": [
					{
						"text": "万州区",
						"id": "500101"
					},
					{
						"text": "涪陵区",
						"id": "500102"
					},
					{
						"text": "渝中区",
						"id": "500103"
					},
					{
						"text": "大渡口区",
						"id": "500104"
					},
					{
						"text": "江北区",
						"id": "500105"
					},
					{
						"text": "沙坪坝区",
						"id": "500106"
					},
					{
						"text": "九龙坡区",
						"id": "500107"
					},
					{
						"text": "南岸区",
						"id": "500108"
					},
					{
						"text": "北碚区",
						"id": "500109"
					},
					{
						"text": "綦江区",
						"id": "500110"
					},
					{
						"text": "大足区",
						"id": "500111"
					},
					{
						"text": "渝北区",
						"id": "500112"
					},
					{
						"text": "巴南区",
						"id": "500113"
					},
					{
						"text": "黔江区",
						"id": "500114"
					},
					{
						"text": "长寿区",
						"id": "500115"
					},
					{
						"text": "江津区",
						"id": "500116"
					},
					{
						"text": "合川区",
						"id": "500117"
					},
					{
						"text": "永川区",
						"id": "500118"
					},
					{
						"text": "南川区",
						"id": "500119"
					},
					{
						"text": "潼南县",
						"id": "500223"
					},
					{
						"text": "铜梁县",
						"id": "500224"
					},
					{
						"text": "荣昌县",
						"id": "500226"
					},
					{
						"text": "璧山县",
						"id": "500227"
					},
					{
						"text": "梁平县",
						"id": "500228"
					},
					{
						"text": "城口县",
						"id": "500229"
					},
					{
						"text": "丰都县",
						"id": "500230"
					},
					{
						"text": "垫江县",
						"id": "500231"
					},
					{
						"text": "武隆县",
						"id": "500232"
					},
					{
						"text": "忠县",
						"id": "500233"
					},
					{
						"text": "开县",
						"id": "500234"
					},
					{
						"text": "云阳县",
						"id": "500235"
					},
					{
						"text": "奉节县",
						"id": "500236"
					},
					{
						"text": "巫山县",
						"id": "500237"
					},
					{
						"text": "巫溪县",
						"id": "500238"
					},
					{
						"text": "石柱土家族自治县",
						"id": "500240"
					},
					{
						"text": "秀山土家族苗族自治县",
						"id": "500241"
					},
					{
						"text": "酉阳土家族苗族自治县",
						"id": "500242"
					},
					{
						"text": "彭水苗族土家族自治县",
						"id": "500243"
					},
					{
						"text": "两江新区",
						"id": "500244"
					}
				]
			}
		]
	},
	{
		"id": "510000",
		"text": "四川",
		"children": [
			{
				"text": "成都市",
				"id": "510100",
				"children": [
					{
						"text": "锦江区",
						"id": "510104"
					},
					{
						"text": "青羊区",
						"id": "510105"
					},
					{
						"text": "金牛区",
						"id": "510106"
					},
					{
						"text": "武侯区",
						"id": "510107"
					},
					{
						"text": "成华区",
						"id": "510108"
					},
					{
						"text": "龙泉驿区",
						"id": "510112"
					},
					{
						"text": "青白江区",
						"id": "510113"
					},
					{
						"text": "新都区",
						"id": "510114"
					},
					{
						"text": "温江区",
						"id": "510115"
					},
					{
						"text": "高新区",
						"id": "510116"
					},
					{
						"text": "金堂县",
						"id": "510121"
					},
					{
						"text": "双流区",
						"id": "510122"
					},
					{
						"text": "郫都区",
						"id": "510124"
					},
					{
						"text": "大邑县",
						"id": "510129"
					},
					{
						"text": "蒲江县",
						"id": "510131"
					},
					{
						"text": "新津县",
						"id": "510132"
					},
					{
						"text": "都江堰市",
						"id": "510181"
					},
					{
						"text": "彭州市",
						"id": "510182"
					},
					{
						"text": "邛崃市",
						"id": "510183"
					},
					{
						"text": "崇州市",
						"id": "510184"
					},
					{
						"text": "简阳市",
						"id": "510185"
					},
					{
						"text": "天府新区",
						"id": "510186"
					},
					{
						"text": "东部新区",
						"id": "510187"
					}
				]
			},
			{
				"text": "自贡市",
				"id": "510300",
				"children": [
					{
						"text": "自流井区",
						"id": "510302"
					},
					{
						"text": "贡井区",
						"id": "510303"
					},
					{
						"text": "大安区",
						"id": "510304"
					},
					{
						"text": "沿滩区",
						"id": "510311"
					},
					{
						"text": "荣县",
						"id": "510321"
					},
					{
						"text": "富顺县",
						"id": "510322"
					}
				]
			},
			{
				"text": "雅安市",
				"id": "511800",
				"children": [
					{
						"text": "雨城区",
						"id": "511802"
					},
					{
						"text": "名山区",
						"id": "511821"
					},
					{
						"text": "荥经县",
						"id": "511822"
					},
					{
						"text": "汉源县",
						"id": "511823"
					},
					{
						"text": "石棉县",
						"id": "511824"
					},
					{
						"text": "天全县",
						"id": "511825"
					},
					{
						"text": "芦山县",
						"id": "511826"
					},
					{
						"text": "宝兴县",
						"id": "511827"
					}
				]
			},
			{
				"text": "绵阳市",
				"id": "510700",
				"children": [
					{
						"text": "涪城区",
						"id": "510703"
					},
					{
						"text": "游仙区",
						"id": "510704"
					},
					{
						"text": "三台县",
						"id": "510722"
					},
					{
						"text": "盐亭县",
						"id": "510723"
					},
					{
						"text": "安州区",
						"id": "510724"
					},
					{
						"text": "梓潼县",
						"id": "510725"
					},
					{
						"text": "北川羌族自治县",
						"id": "510726"
					},
					{
						"text": "平武县",
						"id": "510727"
					},
					{
						"text": "江油市",
						"id": "510781"
					},
					{
						"text": "高新区",
						"id": "510705"
					}
				]
			},
			{
				"text": "乐山市",
				"id": "511100",
				"children": [
					{
						"text": "市中区",
						"id": "511102"
					},
					{
						"text": "沙湾区",
						"id": "511111"
					},
					{
						"text": "五通桥区",
						"id": "511112"
					},
					{
						"text": "金口河区",
						"id": "511113"
					},
					{
						"text": "犍为县",
						"id": "511123"
					},
					{
						"text": "井研县",
						"id": "511124"
					},
					{
						"text": "夹江县",
						"id": "511126"
					},
					{
						"text": "沐川县",
						"id": "511129"
					},
					{
						"text": "峨边彝族自治县",
						"id": "511132"
					},
					{
						"text": "马边彝族自治县",
						"id": "511133"
					},
					{
						"text": "峨眉山市",
						"id": "511181"
					}
				]
			},
			{
				"text": "德阳市",
				"id": "510600",
				"children": [
					{
						"text": "旌阳区",
						"id": "510603"
					},
					{
						"text": "中江县",
						"id": "510623"
					},
					{
						"text": "罗江区",
						"id": "510626"
					},
					{
						"text": "广汉市",
						"id": "510681"
					},
					{
						"text": "什邡市",
						"id": "510682"
					},
					{
						"text": "绵竹市",
						"id": "510683"
					}
				]
			},
			{
				"text": "攀枝花市",
				"id": "510400",
				"children": [
					{
						"text": "东区",
						"id": "510402"
					},
					{
						"text": "西区",
						"id": "510403"
					},
					{
						"text": "仁和区",
						"id": "510411"
					},
					{
						"text": "米易县",
						"id": "510421"
					},
					{
						"text": "盐边县",
						"id": "510422"
					}
				]
			},
			{
				"text": "宜宾市",
				"id": "511500",
				"children": [
					{
						"text": "翠屏区",
						"id": "511502"
					},
					{
						"text": "南溪区",
						"id": "511503"
					},
					{
						"text": "叙州区",
						"id": "511521"
					},
					{
						"text": "江安县",
						"id": "511523"
					},
					{
						"text": "长宁县",
						"id": "511524"
					},
					{
						"text": "高县",
						"id": "511525"
					},
					{
						"text": "珙县",
						"id": "511526"
					},
					{
						"text": "筠连县",
						"id": "511527"
					},
					{
						"text": "兴文县",
						"id": "511528"
					},
					{
						"text": "屏山县",
						"id": "511529"
					},
					{
						"text": "叙州区南岸",
						"id": "511530"
					},
					{
						"text": "翠屏区江北",
						"id": "511531"
					},
					{
						"text": "临港区",
						"id": "511532"
					},
					{
						"text": "观音镇",
						"id": "511533"
					}
				]
			},
			{
				"text": "阿坝藏族羌族自治州",
				"id": "513200",
				"children": [
					{
						"text": "汶川县",
						"id": "513221"
					},
					{
						"text": "理县",
						"id": "513222"
					},
					{
						"text": "茂县",
						"id": "513223"
					},
					{
						"text": "松潘县",
						"id": "513224"
					},
					{
						"text": "九寨沟县",
						"id": "513225"
					},
					{
						"text": "金川县",
						"id": "513226"
					},
					{
						"text": "小金县",
						"id": "513227"
					},
					{
						"text": "黑水县",
						"id": "513228"
					},
					{
						"text": "马尔康市",
						"id": "513229"
					},
					{
						"text": "壤塘县",
						"id": "513230"
					},
					{
						"text": "阿坝县",
						"id": "513231"
					},
					{
						"text": "若尔盖县",
						"id": "513232"
					},
					{
						"text": "红原县",
						"id": "513233"
					}
				]
			},
			{
				"text": "内江市",
				"id": "511000",
				"children": [
					{
						"text": "市中区",
						"id": "511002"
					},
					{
						"text": "东兴区",
						"id": "511011"
					},
					{
						"text": "威远县",
						"id": "511024"
					},
					{
						"text": "资中县",
						"id": "511025"
					},
					{
						"text": "隆昌市",
						"id": "511028"
					}
				]
			},
			{
				"text": "眉山市",
				"id": "511400",
				"children": [
					{
						"text": "东坡区",
						"id": "511402"
					},
					{
						"text": "仁寿县",
						"id": "511421"
					},
					{
						"text": "彭山区",
						"id": "511422"
					},
					{
						"text": "洪雅县",
						"id": "511423"
					},
					{
						"text": "丹棱县",
						"id": "511424"
					},
					{
						"text": "青神县",
						"id": "511425"
					}
				]
			},
			{
				"text": "资阳市",
				"id": "512000",
				"children": [
					{
						"text": "雁江区",
						"id": "512002"
					},
					{
						"text": "安岳县",
						"id": "512021"
					},
					{
						"text": "乐至县",
						"id": "512022"
					}
				]
			},
			{
				"text": "泸州市",
				"id": "510500",
				"children": [
					{
						"text": "江阳区",
						"id": "510502"
					},
					{
						"text": "纳溪区",
						"id": "510503"
					},
					{
						"text": "龙马潭区",
						"id": "510504"
					},
					{
						"text": "泸县",
						"id": "510521"
					},
					{
						"text": "合江县",
						"id": "510522"
					},
					{
						"text": "叙永县",
						"id": "510524"
					},
					{
						"text": "古蔺县",
						"id": "510525"
					}
				]
			},
			{
				"text": "南充市",
				"id": "511300",
				"children": [
					{
						"text": "顺庆区",
						"id": "511302"
					},
					{
						"text": "高坪区",
						"id": "511303"
					},
					{
						"text": "嘉陵区",
						"id": "511304"
					},
					{
						"text": "南部县",
						"id": "511321"
					},
					{
						"text": "营山县",
						"id": "511322"
					},
					{
						"text": "蓬安县",
						"id": "511323"
					},
					{
						"text": "仪陇县",
						"id": "511324"
					},
					{
						"text": "西充县",
						"id": "511325"
					},
					{
						"text": "阆中市",
						"id": "511381"
					}
				]
			},
			{
				"text": "达州市",
				"id": "511700",
				"children": [
					{
						"text": "通川区",
						"id": "511702"
					},
					{
						"text": "达川区",
						"id": "511721"
					},
					{
						"text": "宣汉县",
						"id": "511722"
					},
					{
						"text": "开江县",
						"id": "511723"
					},
					{
						"text": "大竹县",
						"id": "511724"
					},
					{
						"text": "渠县",
						"id": "511725"
					},
					{
						"text": "万源市",
						"id": "511781"
					}
				]
			},
			{
				"text": "遂宁市",
				"id": "510900",
				"children": [
					{
						"text": "船山区",
						"id": "510903"
					},
					{
						"text": "安居区",
						"id": "510904"
					},
					{
						"text": "蓬溪县",
						"id": "510921"
					},
					{
						"text": "射洪市",
						"id": "510922"
					},
					{
						"text": "大英县",
						"id": "510923"
					}
				]
			},
			{
				"text": "广元市",
				"id": "510800",
				"children": [
					{
						"text": "利州区",
						"id": "510802"
					},
					{
						"text": "昭化区",
						"id": "510811"
					},
					{
						"text": "朝天区",
						"id": "510812"
					},
					{
						"text": "旺苍县",
						"id": "510821"
					},
					{
						"text": "青川县",
						"id": "510822"
					},
					{
						"text": "剑阁县",
						"id": "510823"
					},
					{
						"text": "苍溪县",
						"id": "510824"
					}
				]
			},
			{
				"text": "广安市",
				"id": "511600",
				"children": [
					{
						"text": "广安区",
						"id": "511602"
					},
					{
						"text": "岳池县",
						"id": "511621"
					},
					{
						"text": "武胜县",
						"id": "511622"
					},
					{
						"text": "邻水县",
						"id": "511623"
					},
					{
						"text": "华蓥市",
						"id": "511681"
					},
					{
						"text": "前锋区",
						"id": "511624"
					}
				]
			},
			{
				"text": "巴中市",
				"id": "511900",
				"children": [
					{
						"text": "巴州区",
						"id": "511902"
					},
					{
						"text": "通江县",
						"id": "511921"
					},
					{
						"text": "南江县",
						"id": "511922"
					},
					{
						"text": "平昌县",
						"id": "511923"
					},
					{
						"text": "恩阳区",
						"id": "511924"
					}
				]
			},
			{
				"text": "凉山彝族自治州",
				"id": "513400",
				"children": [
					{
						"text": "西昌市",
						"id": "513401"
					},
					{
						"text": "木里藏族自治县",
						"id": "513422"
					},
					{
						"text": "盐源县",
						"id": "513423"
					},
					{
						"text": "德昌县",
						"id": "513424"
					},
					{
						"text": "会理县",
						"id": "513425"
					},
					{
						"text": "会东县",
						"id": "513426"
					},
					{
						"text": "宁南县",
						"id": "513427"
					},
					{
						"text": "普格县",
						"id": "513428"
					},
					{
						"text": "布拖县",
						"id": "513429"
					},
					{
						"text": "金阳县",
						"id": "513430"
					},
					{
						"text": "昭觉县",
						"id": "513431"
					},
					{
						"text": "喜德县",
						"id": "513432"
					},
					{
						"text": "冕宁县",
						"id": "513433"
					},
					{
						"text": "越西县",
						"id": "513434"
					},
					{
						"text": "甘洛县",
						"id": "513435"
					},
					{
						"text": "美姑县",
						"id": "513436"
					},
					{
						"text": "雷波县",
						"id": "513437"
					}
				]
			},
			{
				"text": "甘孜藏族自治州",
				"id": "513300",
				"children": [
					{
						"text": "康定市",
						"id": "513321"
					},
					{
						"text": "泸定县",
						"id": "513322"
					},
					{
						"text": "丹巴县",
						"id": "513323"
					},
					{
						"text": "九龙县",
						"id": "513324"
					},
					{
						"text": "雅江县",
						"id": "513325"
					},
					{
						"text": "道孚县",
						"id": "513326"
					},
					{
						"text": "炉霍县",
						"id": "513327"
					},
					{
						"text": "甘孜县",
						"id": "513328"
					},
					{
						"text": "新龙县",
						"id": "513329"
					},
					{
						"text": "德格县",
						"id": "513330"
					},
					{
						"text": "白玉县",
						"id": "513331"
					},
					{
						"text": "石渠县",
						"id": "513332"
					},
					{
						"text": "色达县",
						"id": "513333"
					},
					{
						"text": "理塘县",
						"id": "513334"
					},
					{
						"text": "巴塘县",
						"id": "513335"
					},
					{
						"text": "乡城县",
						"id": "513336"
					},
					{
						"text": "稻城县",
						"id": "513337"
					},
					{
						"text": "得荣县",
						"id": "513338"
					}
				]
			}
		]
	},
	{
		"id": "520000",
		"text": "贵州",
		"children": [
			{
				"text": "贵阳市",
				"id": "520100",
				"children": [
					{
						"text": "南明区",
						"id": "520102"
					},
					{
						"text": "云岩区",
						"id": "520103"
					},
					{
						"text": "花溪区",
						"id": "520111"
					},
					{
						"text": "乌当区",
						"id": "520112"
					},
					{
						"text": "白云区",
						"id": "520113"
					},
					{
						"text": "小河区",
						"id": "520114"
					},
					{
						"text": "开阳县",
						"id": "520121"
					},
					{
						"text": "息烽县",
						"id": "520122"
					},
					{
						"text": "修文县",
						"id": "520123"
					},
					{
						"text": "清镇市",
						"id": "520181"
					},
					{
						"text": "观山湖区",
						"id": "520104"
					},
					{
						"text": "贵安",
						"id": "520182"
					}
				]
			},
			{
				"text": "六盘水市",
				"id": "520200",
				"children": [
					{
						"text": "钟山区",
						"id": "520201"
					},
					{
						"text": "六枝特区",
						"id": "520203"
					},
					{
						"text": "水城县",
						"id": "520221"
					},
					{
						"text": "盘县",
						"id": "520222"
					}
				]
			},
			{
				"text": "遵义市",
				"id": "520300",
				"children": [
					{
						"text": "红花岗区",
						"id": "520302"
					},
					{
						"text": "汇川区",
						"id": "520303"
					},
					{
						"text": "遵义县",
						"id": "520321"
					},
					{
						"text": "桐梓县",
						"id": "520322"
					},
					{
						"text": "绥阳县",
						"id": "520323"
					},
					{
						"text": "正安县",
						"id": "520324"
					},
					{
						"text": "道真仡佬族苗族自治县",
						"id": "520325"
					},
					{
						"text": "务川仡佬族苗族自治县",
						"id": "520326"
					},
					{
						"text": "凤冈县",
						"id": "520327"
					},
					{
						"text": "湄潭县",
						"id": "520328"
					},
					{
						"text": "余庆县",
						"id": "520329"
					},
					{
						"text": "习水县",
						"id": "520330"
					},
					{
						"text": "赤水市",
						"id": "520381"
					},
					{
						"text": "仁怀市",
						"id": "520382"
					}
				]
			},
			{
				"text": "安顺市",
				"id": "520400",
				"children": [
					{
						"text": "西秀区",
						"id": "520402"
					},
					{
						"text": "平坝县",
						"id": "520421"
					},
					{
						"text": "普定县",
						"id": "520422"
					},
					{
						"text": "镇宁布依族苗族自治县",
						"id": "520423"
					},
					{
						"text": "关岭布依族苗族自治县",
						"id": "520424"
					},
					{
						"text": "紫云苗族布依族自治县",
						"id": "520425"
					}
				]
			},
			{
				"text": "毕节市",
				"id": "520500",
				"children": [
					{
						"text": "七星关区",
						"id": "520502"
					},
					{
						"text": "大方县",
						"id": "520521"
					},
					{
						"text": "黔西县",
						"id": "520522"
					},
					{
						"text": "金沙县",
						"id": "520523"
					},
					{
						"text": "织金县",
						"id": "520524"
					},
					{
						"text": "纳雍县",
						"id": "520525"
					},
					{
						"text": "威宁彝族回族苗族自治县",
						"id": "520526"
					},
					{
						"text": "赫章县",
						"id": "520527"
					}
				]
			},
			{
				"text": "铜仁市",
				"id": "520600",
				"children": [
					{
						"text": "碧江区",
						"id": "520602"
					},
					{
						"text": "万山区",
						"id": "520603"
					},
					{
						"text": "江口县",
						"id": "520621"
					},
					{
						"text": "玉屏侗族自治县",
						"id": "520622"
					},
					{
						"text": "石阡县",
						"id": "520623"
					},
					{
						"text": "思南县",
						"id": "520624"
					},
					{
						"text": "印江土家族苗族自治县",
						"id": "520625"
					},
					{
						"text": "德江县",
						"id": "520626"
					},
					{
						"text": "沿河土家族自治县",
						"id": "520627"
					},
					{
						"text": "松桃苗族自治县",
						"id": "520628"
					}
				]
			},
			{
				"text": "黔西南",
				"id": "522300",
				"children": [
					{
						"text": "兴义市",
						"id": "522301"
					},
					{
						"text": "兴仁县",
						"id": "522322"
					},
					{
						"text": "普安县",
						"id": "522323"
					},
					{
						"text": "晴隆县",
						"id": "522324"
					},
					{
						"text": "贞丰县",
						"id": "522325"
					},
					{
						"text": "望谟县",
						"id": "522326"
					},
					{
						"text": "册亨县",
						"id": "522327"
					},
					{
						"text": "安龙县",
						"id": "522328"
					}
				]
			},
			{
				"text": "黔东南",
				"id": "522600",
				"children": [
					{
						"text": "凯里市",
						"id": "522601"
					},
					{
						"text": "黄平县",
						"id": "522622"
					},
					{
						"text": "施秉县",
						"id": "522623"
					},
					{
						"text": "三穗县",
						"id": "522624"
					},
					{
						"text": "镇远县",
						"id": "522625"
					},
					{
						"text": "岑巩县",
						"id": "522626"
					},
					{
						"text": "天柱县",
						"id": "522627"
					},
					{
						"text": "锦屏县",
						"id": "522628"
					},
					{
						"text": "剑河县",
						"id": "522629"
					},
					{
						"text": "台江县",
						"id": "522630"
					},
					{
						"text": "黎平县",
						"id": "522631"
					},
					{
						"text": "榕江县",
						"id": "522632"
					},
					{
						"text": "从江县",
						"id": "522633"
					},
					{
						"text": "雷山县",
						"id": "522634"
					},
					{
						"text": "麻江县",
						"id": "522635"
					},
					{
						"text": "丹寨县",
						"id": "522636"
					}
				]
			},
			{
				"text": "黔南",
				"id": "522700",
				"children": [
					{
						"text": "都匀市",
						"id": "522701"
					},
					{
						"text": "福泉市",
						"id": "522702"
					},
					{
						"text": "荔波县",
						"id": "522722"
					},
					{
						"text": "贵定县",
						"id": "522723"
					},
					{
						"text": "瓮安县",
						"id": "522725"
					},
					{
						"text": "独山县",
						"id": "522726"
					},
					{
						"text": "平塘县",
						"id": "522727"
					},
					{
						"text": "罗甸县",
						"id": "522728"
					},
					{
						"text": "长顺县",
						"id": "522729"
					},
					{
						"text": "龙里县",
						"id": "522730"
					},
					{
						"text": "惠水县",
						"id": "522731"
					},
					{
						"text": "三都水族自治县",
						"id": "522732"
					}
				]
			}
		]
	},
	{
		"id": "530000",
		"text": "云南",
		"children": [
			{
				"text": "昆明市",
				"id": "530100",
				"children": [
					{
						"text": "五华区",
						"id": "530102"
					},
					{
						"text": "盘龙区",
						"id": "530103"
					},
					{
						"text": "官渡区",
						"id": "530111"
					},
					{
						"text": "西山区",
						"id": "530112"
					},
					{
						"text": "东川区",
						"id": "530113"
					},
					{
						"text": "呈贡区",
						"id": "530114"
					},
					{
						"text": "晋宁县",
						"id": "530122"
					},
					{
						"text": "富民县",
						"id": "530124"
					},
					{
						"text": "宜良县",
						"id": "530125"
					},
					{
						"text": "石林彝族自治县",
						"id": "530126"
					},
					{
						"text": "嵩明县",
						"id": "530127"
					},
					{
						"text": "禄劝彝族苗族自治县",
						"id": "530128"
					},
					{
						"text": "寻甸回族彝族自治县",
						"id": "530129"
					},
					{
						"text": "安宁市",
						"id": "530181"
					}
				]
			},
			{
				"text": "曲靖市",
				"id": "530300",
				"children": [
					{
						"text": "麒麟区",
						"id": "530302"
					},
					{
						"text": "马龙县",
						"id": "530321"
					},
					{
						"text": "陆良县",
						"id": "530322"
					},
					{
						"text": "师宗县",
						"id": "530323"
					},
					{
						"text": "罗平县",
						"id": "530324"
					},
					{
						"text": "富源县",
						"id": "530325"
					},
					{
						"text": "会泽县",
						"id": "530326"
					},
					{
						"text": "沾益县",
						"id": "530328"
					},
					{
						"text": "宣威市",
						"id": "530381"
					}
				]
			},
			{
				"text": "玉溪市",
				"id": "530400",
				"children": [
					{
						"text": "红塔区",
						"id": "530402"
					},
					{
						"text": "江川县",
						"id": "530421"
					},
					{
						"text": "澄江县",
						"id": "530422"
					},
					{
						"text": "通海县",
						"id": "530423"
					},
					{
						"text": "华宁县",
						"id": "530424"
					},
					{
						"text": "易门县",
						"id": "530425"
					},
					{
						"text": "峨山彝族自治县",
						"id": "530426"
					},
					{
						"text": "新平彝族傣族自治县",
						"id": "530427"
					},
					{
						"text": "元江哈尼族彝族傣族自治县",
						"id": "530428"
					}
				]
			},
			{
				"text": "保山市",
				"id": "530500",
				"children": [
					{
						"text": "隆阳区",
						"id": "530502"
					},
					{
						"text": "施甸县",
						"id": "530521"
					},
					{
						"text": "腾冲县",
						"id": "530522"
					},
					{
						"text": "龙陵县",
						"id": "530523"
					},
					{
						"text": "昌宁县",
						"id": "530524"
					}
				]
			},
			{
				"text": "昭通市",
				"id": "530600",
				"children": [
					{
						"text": "昭阳区",
						"id": "530602"
					},
					{
						"text": "鲁甸县",
						"id": "530621"
					},
					{
						"text": "巧家县",
						"id": "530622"
					},
					{
						"text": "盐津县",
						"id": "530623"
					},
					{
						"text": "大关县",
						"id": "530624"
					},
					{
						"text": "永善县",
						"id": "530625"
					},
					{
						"text": "绥江县",
						"id": "530626"
					},
					{
						"text": "镇雄县",
						"id": "530627"
					},
					{
						"text": "彝良县",
						"id": "530628"
					},
					{
						"text": "威信县",
						"id": "530629"
					},
					{
						"text": "水富县",
						"id": "530630"
					}
				]
			},
			{
				"text": "丽江市",
				"id": "530700",
				"children": [
					{
						"text": "古城区",
						"id": "530702"
					},
					{
						"text": "玉龙纳西族自治县",
						"id": "530721"
					},
					{
						"text": "永胜县",
						"id": "530722"
					},
					{
						"text": "华坪县",
						"id": "530723"
					},
					{
						"text": "宁蒗彝族自治县",
						"id": "530724"
					}
				]
			},
			{
				"text": "普洱市",
				"id": "530800",
				"children": [
					{
						"text": "思茅区",
						"id": "530802"
					},
					{
						"text": "宁洱哈尼族彝族自治县",
						"id": "530821"
					},
					{
						"text": "墨江哈尼族自治县",
						"id": "530822"
					},
					{
						"text": "景东彝族自治县",
						"id": "530823"
					},
					{
						"text": "景谷傣族彝族自治县",
						"id": "530824"
					},
					{
						"text": "镇沅彝族哈尼族拉祜族自治县",
						"id": "530825"
					},
					{
						"text": "江城哈尼族彝族自治县",
						"id": "530826"
					},
					{
						"text": "孟连傣族拉祜族佤族自治县",
						"id": "530827"
					},
					{
						"text": "澜沧拉祜族自治县",
						"id": "530828"
					},
					{
						"text": "西盟佤族自治县",
						"id": "530829"
					}
				]
			},
			{
				"text": "临沧市",
				"id": "530900",
				"children": [
					{
						"text": "临翔区",
						"id": "530902"
					},
					{
						"text": "凤庆县",
						"id": "530921"
					},
					{
						"text": "云县",
						"id": "530922"
					},
					{
						"text": "永德县",
						"id": "530923"
					},
					{
						"text": "镇康县",
						"id": "530924"
					},
					{
						"text": "双江拉祜族佤族布朗族傣族自治县",
						"id": "530925"
					},
					{
						"text": "耿马傣族佤族自治县",
						"id": "530926"
					},
					{
						"text": "沧源佤族自治县",
						"id": "530927"
					}
				]
			},
			{
				"text": "楚雄彝族自治州",
				"id": "532300",
				"children": [
					{
						"text": "楚雄市",
						"id": "532301"
					},
					{
						"text": "双柏县",
						"id": "532322"
					},
					{
						"text": "牟定县",
						"id": "532323"
					},
					{
						"text": "南华县",
						"id": "532324"
					},
					{
						"text": "姚安县",
						"id": "532325"
					},
					{
						"text": "大姚县",
						"id": "532326"
					},
					{
						"text": "永仁县",
						"id": "532327"
					},
					{
						"text": "元谋县",
						"id": "532328"
					},
					{
						"text": "武定县",
						"id": "532329"
					},
					{
						"text": "禄丰县",
						"id": "532331"
					}
				]
			},
			{
				"text": "红河哈尼族彝族自治州",
				"id": "532500",
				"children": [
					{
						"text": "个旧市",
						"id": "532501"
					},
					{
						"text": "开远市",
						"id": "532502"
					},
					{
						"text": "蒙自市",
						"id": "532503"
					},
					{
						"text": "屏边苗族自治县",
						"id": "532523"
					},
					{
						"text": "建水县",
						"id": "532524"
					},
					{
						"text": "石屏县",
						"id": "532525"
					},
					{
						"text": "弥勒县",
						"id": "532526"
					},
					{
						"text": "泸西县",
						"id": "532527"
					},
					{
						"text": "元阳县",
						"id": "532528"
					},
					{
						"text": "红河县",
						"id": "532529"
					},
					{
						"text": "金平苗族瑶族傣族自治县",
						"id": "532530"
					},
					{
						"text": "绿春县",
						"id": "532531"
					},
					{
						"text": "河口瑶族自治县",
						"id": "532532"
					}
				]
			},
			{
				"text": "文山州",
				"id": "532600",
				"children": [
					{
						"text": "文山市",
						"id": "532601"
					},
					{
						"text": "砚山县",
						"id": "532622"
					},
					{
						"text": "西畴县",
						"id": "532623"
					},
					{
						"text": "麻栗坡县",
						"id": "532624"
					},
					{
						"text": "马关县",
						"id": "532625"
					},
					{
						"text": "丘北县",
						"id": "532626"
					},
					{
						"text": "广南县",
						"id": "532627"
					},
					{
						"text": "富宁县",
						"id": "532628"
					}
				]
			},
			{
				"text": "西双版纳傣族自治州",
				"id": "532800",
				"children": [
					{
						"text": "景洪市",
						"id": "532801"
					},
					{
						"text": "勐海县",
						"id": "532822"
					},
					{
						"text": "勐腊县",
						"id": "532823"
					}
				]
			},
			{
				"text": "大理白族自治州",
				"id": "532900",
				"children": [
					{
						"text": "大理市",
						"id": "532901"
					},
					{
						"text": "漾濞彝族自治县",
						"id": "532922"
					},
					{
						"text": "祥云县",
						"id": "532923"
					},
					{
						"text": "宾川县",
						"id": "532924"
					},
					{
						"text": "弥渡县",
						"id": "532925"
					},
					{
						"text": "南涧彝族自治县",
						"id": "532926"
					},
					{
						"text": "巍山彝族回族自治县",
						"id": "532927"
					},
					{
						"text": "永平县",
						"id": "532928"
					},
					{
						"text": "云龙县",
						"id": "532929"
					},
					{
						"text": "洱源县",
						"id": "532930"
					},
					{
						"text": "剑川县",
						"id": "532931"
					},
					{
						"text": "鹤庆县",
						"id": "532932"
					}
				]
			},
			{
				"text": "德宏傣族景颇族自治州",
				"id": "533100",
				"children": [
					{
						"text": "瑞丽市",
						"id": "533102"
					},
					{
						"text": "芒市",
						"id": "533103"
					},
					{
						"text": "梁河县",
						"id": "533122"
					},
					{
						"text": "盈江县",
						"id": "533123"
					},
					{
						"text": "陇川县",
						"id": "533124"
					}
				]
			},
			{
				"text": "怒江傈僳族自治州",
				"id": "533300",
				"children": [
					{
						"text": "泸水县",
						"id": "533321"
					},
					{
						"text": "福贡县",
						"id": "533323"
					},
					{
						"text": "贡山独龙族怒族自治县",
						"id": "533324"
					},
					{
						"text": "兰坪白族普米族自治县",
						"id": "533325"
					}
				]
			},
			{
				"text": "迪庆藏族自治州",
				"id": "533400",
				"children": [
					{
						"text": "香格里拉县",
						"id": "533421"
					},
					{
						"text": "德钦县",
						"id": "533422"
					},
					{
						"text": "维西傈僳族自治县",
						"id": "533423"
					}
				]
			}
		]
	},
	{
		"id": "540000",
		"text": "西藏",
		"children": [
			{
				"text": "拉萨市",
				"id": "540100",
				"children": [
					{
						"text": "城关区",
						"id": "540102"
					},
					{
						"text": "林周县",
						"id": "540121"
					},
					{
						"text": "当雄县",
						"id": "540122"
					},
					{
						"text": "尼木县",
						"id": "540123"
					},
					{
						"text": "曲水县",
						"id": "540124"
					},
					{
						"text": "堆龙德庆县",
						"id": "540125"
					},
					{
						"text": "达孜县",
						"id": "540126"
					},
					{
						"text": "墨竹工卡县",
						"id": "540127"
					}
				]
			},
			{
				"text": "昌都市",
				"id": "542100",
				"children": [
					{
						"text": "昌都县",
						"id": "542121"
					},
					{
						"text": "江达县",
						"id": "542122"
					},
					{
						"text": "贡觉县",
						"id": "542123"
					},
					{
						"text": "类乌齐县",
						"id": "542124"
					},
					{
						"text": "丁青县",
						"id": "542125"
					},
					{
						"text": "察雅县",
						"id": "542126"
					},
					{
						"text": "八宿县",
						"id": "542127"
					},
					{
						"text": "左贡县",
						"id": "542128"
					},
					{
						"text": "芒康县",
						"id": "542129"
					},
					{
						"text": "洛隆县",
						"id": "542132"
					},
					{
						"text": "边坝县",
						"id": "542133"
					}
				]
			},
			{
				"text": "山南市",
				"id": "542200",
				"children": [
					{
						"text": "乃东县",
						"id": "542221"
					},
					{
						"text": "扎囊县",
						"id": "542222"
					},
					{
						"text": "贡嘎县",
						"id": "542223"
					},
					{
						"text": "桑日县",
						"id": "542224"
					},
					{
						"text": "琼结县",
						"id": "542225"
					},
					{
						"text": "曲松县",
						"id": "542226"
					},
					{
						"text": "措美县",
						"id": "542227"
					},
					{
						"text": "洛扎县",
						"id": "542228"
					},
					{
						"text": "加查县",
						"id": "542229"
					},
					{
						"text": "隆子县",
						"id": "542231"
					},
					{
						"text": "错那县",
						"id": "542232"
					},
					{
						"text": "浪卡子县",
						"id": "542233"
					}
				]
			},
			{
				"text": "日喀则市",
				"id": "542300",
				"children": [
					{
						"text": "桑珠孜区",
						"id": "542301"
					},
					{
						"text": "南木林县",
						"id": "542322"
					},
					{
						"text": "江孜县",
						"id": "542323"
					},
					{
						"text": "定日县",
						"id": "542324"
					},
					{
						"text": "萨迦县",
						"id": "542325"
					},
					{
						"text": "拉孜县",
						"id": "542326"
					},
					{
						"text": "昂仁县",
						"id": "542327"
					},
					{
						"text": "谢通门县",
						"id": "542328"
					},
					{
						"text": "白朗县",
						"id": "542329"
					},
					{
						"text": "仁布县",
						"id": "542330"
					},
					{
						"text": "康马县",
						"id": "542331"
					},
					{
						"text": "定结县",
						"id": "542332"
					},
					{
						"text": "仲巴县",
						"id": "542333"
					},
					{
						"text": "亚东县",
						"id": "542334"
					},
					{
						"text": "吉隆县",
						"id": "542335"
					},
					{
						"text": "聂拉木县",
						"id": "542336"
					},
					{
						"text": "萨嘎县",
						"id": "542337"
					},
					{
						"text": "岗巴县",
						"id": "542338"
					}
				]
			},
			{
				"text": "那曲市",
				"id": "542400",
				"children": [
					{
						"text": "那曲县",
						"id": "542421"
					},
					{
						"text": "嘉黎县",
						"id": "542422"
					},
					{
						"text": "比如县",
						"id": "542423"
					},
					{
						"text": "聂荣县",
						"id": "542424"
					},
					{
						"text": "安多县",
						"id": "542425"
					},
					{
						"text": "申扎县",
						"id": "542426"
					},
					{
						"text": "索县",
						"id": "542427"
					},
					{
						"text": "班戈县",
						"id": "542428"
					},
					{
						"text": "巴青县",
						"id": "542429"
					},
					{
						"text": "尼玛县",
						"id": "542430"
					}
				]
			},
			{
				"text": "阿里地区",
				"id": "542500",
				"children": [
					{
						"text": "普兰县",
						"id": "542521"
					},
					{
						"text": "札达县",
						"id": "542522"
					},
					{
						"text": "噶尔县",
						"id": "542523"
					},
					{
						"text": "日土县",
						"id": "542524"
					},
					{
						"text": "革吉县",
						"id": "542525"
					},
					{
						"text": "改则县",
						"id": "542526"
					},
					{
						"text": "措勤县",
						"id": "542527"
					}
				]
			},
			{
				"text": "林芝市",
				"id": "542600",
				"children": [
					{
						"text": "林芝县",
						"id": "542621"
					},
					{
						"text": "工布江达县",
						"id": "542622"
					},
					{
						"text": "米林县",
						"id": "542623"
					},
					{
						"text": "墨脱县",
						"id": "542624"
					},
					{
						"text": "波密县",
						"id": "542625"
					},
					{
						"text": "察隅县",
						"id": "542626"
					},
					{
						"text": "朗县",
						"id": "542627"
					}
				]
			}
		]
	},
	{
		"id": "610000",
		"text": "陕西",
		"children": [
			{
				"text": "西安市",
				"id": "610100",
				"children": [
					{
						"text": "新城区",
						"id": "610102"
					},
					{
						"text": "碑林区",
						"id": "610103"
					},
					{
						"text": "莲湖区",
						"id": "610104"
					},
					{
						"text": "灞桥区",
						"id": "610111"
					},
					{
						"text": "未央区",
						"id": "610112"
					},
					{
						"text": "雁塔区",
						"id": "610113"
					},
					{
						"text": "阎良区",
						"id": "610114"
					},
					{
						"text": "临潼区",
						"id": "610115"
					},
					{
						"text": "长安区",
						"id": "610116"
					},
					{
						"text": "蓝田县",
						"id": "610122"
					},
					{
						"text": "周至县",
						"id": "610124"
					},
					{
						"text": "鄠邑区",
						"id": "610125"
					},
					{
						"text": "高陵区",
						"id": "610126"
					},
					{
						"text": "杨凌区",
						"id": "610403"
					}
				]
			},
			{
				"text": "铜川市",
				"id": "610200",
				"children": [
					{
						"text": "王益区",
						"id": "610202"
					},
					{
						"text": "印台区",
						"id": "610203"
					},
					{
						"text": "耀州区",
						"id": "610204"
					},
					{
						"text": "宜君县",
						"id": "610222"
					}
				]
			},
			{
				"text": "宝鸡市",
				"id": "610300",
				"children": [
					{
						"text": "渭滨区",
						"id": "610302"
					},
					{
						"text": "金台区",
						"id": "610303"
					},
					{
						"text": "陈仓区",
						"id": "610304"
					},
					{
						"text": "凤翔区",
						"id": "610322"
					},
					{
						"text": "岐山县",
						"id": "610323"
					},
					{
						"text": "扶风县",
						"id": "610324"
					},
					{
						"text": "眉县",
						"id": "610326"
					},
					{
						"text": "陇县",
						"id": "610327"
					},
					{
						"text": "千阳县",
						"id": "610328"
					},
					{
						"text": "麟游县",
						"id": "610329"
					},
					{
						"text": "凤县",
						"id": "610330"
					},
					{
						"text": "太白县",
						"id": "610331"
					}
				]
			},
			{
				"text": "咸阳市",
				"id": "610400",
				"children": [
					{
						"text": "秦都区",
						"id": "610402"
					},
					{
						"text": "渭城区",
						"id": "610404"
					},
					{
						"text": "三原县",
						"id": "610422"
					},
					{
						"text": "泾阳县",
						"id": "610423"
					},
					{
						"text": "乾县",
						"id": "610424"
					},
					{
						"text": "礼泉县",
						"id": "610425"
					},
					{
						"text": "永寿县",
						"id": "610426"
					},
					{
						"text": "彬州市",
						"id": "610427"
					},
					{
						"text": "长武县",
						"id": "610428"
					},
					{
						"text": "旬邑县",
						"id": "610429"
					},
					{
						"text": "淳化县",
						"id": "610430"
					},
					{
						"text": "武功县",
						"id": "610431"
					},
					{
						"text": "兴平市",
						"id": "610481"
					}
				]
			},
			{
				"text": "渭南市",
				"id": "610500",
				"children": [
					{
						"text": "临渭区",
						"id": "610502"
					},
					{
						"text": "华州区",
						"id": "610521"
					},
					{
						"text": "潼关县",
						"id": "610522"
					},
					{
						"text": "大荔县",
						"id": "610523"
					},
					{
						"text": "合阳县",
						"id": "610524"
					},
					{
						"text": "澄城县",
						"id": "610525"
					},
					{
						"text": "蒲城县",
						"id": "610526"
					},
					{
						"text": "白水县",
						"id": "610527"
					},
					{
						"text": "富平县",
						"id": "610528"
					},
					{
						"text": "韩城市",
						"id": "610581"
					},
					{
						"text": "华阴市",
						"id": "610582"
					}
				]
			},
			{
				"text": "延安市",
				"id": "610600",
				"children": [
					{
						"text": "宝塔区",
						"id": "610602"
					},
					{
						"text": "延长县",
						"id": "610621"
					},
					{
						"text": "延川县",
						"id": "610622"
					},
					{
						"text": "子长市",
						"id": "610623"
					},
					{
						"text": "安塞区",
						"id": "610624"
					},
					{
						"text": "志丹县",
						"id": "610625"
					},
					{
						"text": "吴起县",
						"id": "610626"
					},
					{
						"text": "甘泉县",
						"id": "610627"
					},
					{
						"text": "富县",
						"id": "610628"
					},
					{
						"text": "洛川县",
						"id": "610629"
					},
					{
						"text": "宜川县",
						"id": "610630"
					},
					{
						"text": "黄龙县",
						"id": "610631"
					},
					{
						"text": "黄陵县",
						"id": "610632"
					}
				]
			},
			{
				"text": "汉中市",
				"id": "610700",
				"children": [
					{
						"text": "汉台区",
						"id": "610702"
					},
					{
						"text": "南郑县",
						"id": "610721"
					},
					{
						"text": "城固县",
						"id": "610722"
					},
					{
						"text": "洋县",
						"id": "610723"
					},
					{
						"text": "西乡县",
						"id": "610724"
					},
					{
						"text": "勉县",
						"id": "610725"
					},
					{
						"text": "宁强县",
						"id": "610726"
					},
					{
						"text": "略阳县",
						"id": "610727"
					},
					{
						"text": "镇巴县",
						"id": "610728"
					},
					{
						"text": "留坝县",
						"id": "610729"
					},
					{
						"text": "佛坪县",
						"id": "610730"
					}
				]
			},
			{
				"text": "榆林市",
				"id": "610800",
				"children": [
					{
						"text": "榆阳区",
						"id": "610802"
					},
					{
						"text": "神木市",
						"id": "610821"
					},
					{
						"text": "府谷县",
						"id": "610822"
					},
					{
						"text": "横山区",
						"id": "610823"
					},
					{
						"text": "靖边县",
						"id": "610824"
					},
					{
						"text": "定边县",
						"id": "610825"
					},
					{
						"text": "绥德县",
						"id": "610826"
					},
					{
						"text": "米脂县",
						"id": "610827"
					},
					{
						"text": "佳县",
						"id": "610828"
					},
					{
						"text": "吴堡县",
						"id": "610829"
					},
					{
						"text": "清涧县",
						"id": "610830"
					},
					{
						"text": "子洲县",
						"id": "610831"
					}
				]
			},
			{
				"text": "安康市",
				"id": "610900",
				"children": [
					{
						"text": "汉滨区",
						"id": "610902"
					},
					{
						"text": "汉阴县",
						"id": "610921"
					},
					{
						"text": "石泉县",
						"id": "610922"
					},
					{
						"text": "宁陕县",
						"id": "610923"
					},
					{
						"text": "紫阳县",
						"id": "610924"
					},
					{
						"text": "岚皋县",
						"id": "610925"
					},
					{
						"text": "平利县",
						"id": "610926"
					},
					{
						"text": "镇坪县",
						"id": "610927"
					},
					{
						"text": "旬阳市",
						"id": "610928"
					},
					{
						"text": "白河县",
						"id": "610929"
					}
				]
			},
			{
				"text": "商洛市",
				"id": "611000",
				"children": [
					{
						"text": "商州区",
						"id": "611002"
					},
					{
						"text": "洛南县",
						"id": "611021"
					},
					{
						"text": "丹凤县",
						"id": "611022"
					},
					{
						"text": "商南县",
						"id": "611023"
					},
					{
						"text": "山阳县",
						"id": "611024"
					},
					{
						"text": "镇安县",
						"id": "611025"
					},
					{
						"text": "柞水县",
						"id": "611026"
					}
				]
			}
		]
	},
	{
		"id": "620000",
		"text": "甘肃",
		"children": [
			{
				"text": "兰州市",
				"id": "620100",
				"children": [
					{
						"text": "城关区",
						"id": "620102"
					},
					{
						"text": "七里河区",
						"id": "620103"
					},
					{
						"text": "西固区",
						"id": "620104"
					},
					{
						"text": "安宁区",
						"id": "620105"
					},
					{
						"text": "红古区",
						"id": "620111"
					},
					{
						"text": "永登县",
						"id": "620121"
					},
					{
						"text": "皋兰县",
						"id": "620122"
					},
					{
						"text": "榆中县",
						"id": "620123"
					}
				]
			},
			{
				"text": "嘉峪关市",
				"id": "620200",
				"children": [
					{
						"text": "嘉峪关市",
						"id": "620201"
					}
				]
			},
			{
				"text": "金昌市",
				"id": "620300",
				"children": [
					{
						"text": "金川区",
						"id": "620302"
					},
					{
						"text": "永昌县",
						"id": "620321"
					}
				]
			},
			{
				"text": "白银市",
				"id": "620400",
				"children": [
					{
						"text": "白银区",
						"id": "620402"
					},
					{
						"text": "平川区",
						"id": "620403"
					},
					{
						"text": "靖远县",
						"id": "620421"
					},
					{
						"text": "会宁县",
						"id": "620422"
					},
					{
						"text": "景泰县",
						"id": "620423"
					}
				]
			},
			{
				"text": "天水市",
				"id": "620500",
				"children": [
					{
						"text": "秦州区",
						"id": "620502"
					},
					{
						"text": "麦积区",
						"id": "620503"
					},
					{
						"text": "清水县",
						"id": "620521"
					},
					{
						"text": "秦安县",
						"id": "620522"
					},
					{
						"text": "甘谷县",
						"id": "620523"
					},
					{
						"text": "武山县",
						"id": "620524"
					},
					{
						"text": "张家川回族自治县",
						"id": "620525"
					}
				]
			},
			{
				"text": "武威市",
				"id": "620600",
				"children": [
					{
						"text": "凉州区",
						"id": "620602"
					},
					{
						"text": "民勤县",
						"id": "620621"
					},
					{
						"text": "古浪县",
						"id": "620622"
					},
					{
						"text": "天祝藏族自治县",
						"id": "620623"
					}
				]
			},
			{
				"text": "张掖市",
				"id": "620700",
				"children": [
					{
						"text": "甘州区",
						"id": "620702"
					},
					{
						"text": "肃南裕固族自治县",
						"id": "620721"
					},
					{
						"text": "民乐县",
						"id": "620722"
					},
					{
						"text": "临泽县",
						"id": "620723"
					},
					{
						"text": "高台县",
						"id": "620724"
					},
					{
						"text": "山丹县",
						"id": "620725"
					}
				]
			},
			{
				"text": "平凉市",
				"id": "620800",
				"children": [
					{
						"text": "崆峒区",
						"id": "620802"
					},
					{
						"text": "泾川县",
						"id": "620821"
					},
					{
						"text": "灵台县",
						"id": "620822"
					},
					{
						"text": "崇信县",
						"id": "620823"
					},
					{
						"text": "华亭县",
						"id": "620824"
					},
					{
						"text": "庄浪县",
						"id": "620825"
					},
					{
						"text": "静宁县",
						"id": "620826"
					}
				]
			},
			{
				"text": "酒泉市",
				"id": "620900",
				"children": [
					{
						"text": "肃州区",
						"id": "620902"
					},
					{
						"text": "金塔县",
						"id": "620921"
					},
					{
						"text": "瓜州县",
						"id": "620922"
					},
					{
						"text": "肃北蒙古族自治县",
						"id": "620923"
					},
					{
						"text": "阿克塞哈萨克族自治县",
						"id": "620924"
					},
					{
						"text": "玉门市",
						"id": "620981"
					},
					{
						"text": "敦煌市",
						"id": "620982"
					}
				]
			},
			{
				"text": "庆阳市",
				"id": "621000",
				"children": [
					{
						"text": "西峰区",
						"id": "621002"
					},
					{
						"text": "庆城县",
						"id": "621021"
					},
					{
						"text": "环县",
						"id": "621022"
					},
					{
						"text": "华池县",
						"id": "621023"
					},
					{
						"text": "合水县",
						"id": "621024"
					},
					{
						"text": "正宁县",
						"id": "621025"
					},
					{
						"text": "宁县",
						"id": "621026"
					},
					{
						"text": "镇原县",
						"id": "621027"
					}
				]
			},
			{
				"text": "定西市",
				"id": "621100",
				"children": [
					{
						"text": "安定区",
						"id": "621102"
					},
					{
						"text": "通渭县",
						"id": "621121"
					},
					{
						"text": "陇西县",
						"id": "621122"
					},
					{
						"text": "渭源县",
						"id": "621123"
					},
					{
						"text": "临洮县",
						"id": "621124"
					},
					{
						"text": "漳县",
						"id": "621125"
					},
					{
						"text": "岷县",
						"id": "621126"
					}
				]
			},
			{
				"text": "陇南市",
				"id": "621200",
				"children": [
					{
						"text": "武都区",
						"id": "621202"
					},
					{
						"text": "成县",
						"id": "621221"
					},
					{
						"text": "文县",
						"id": "621222"
					},
					{
						"text": "宕昌县",
						"id": "621223"
					},
					{
						"text": "康县",
						"id": "621224"
					},
					{
						"text": "西和县",
						"id": "621225"
					},
					{
						"text": "礼县",
						"id": "621226"
					},
					{
						"text": "徽县",
						"id": "621227"
					},
					{
						"text": "两当县",
						"id": "621228"
					}
				]
			},
			{
				"text": "临夏回族自治州",
				"id": "622900",
				"children": [
					{
						"text": "临夏市",
						"id": "622901"
					},
					{
						"text": "临夏县",
						"id": "622921"
					},
					{
						"text": "康乐县",
						"id": "622922"
					},
					{
						"text": "永靖县",
						"id": "622923"
					},
					{
						"text": "广河县",
						"id": "622924"
					},
					{
						"text": "和政县",
						"id": "622925"
					},
					{
						"text": "东乡族自治县",
						"id": "622926"
					},
					{
						"text": "积石山保安族东乡族撒拉族自治县",
						"id": "622927"
					}
				]
			},
			{
				"text": "甘南藏族自治州",
				"id": "623000",
				"children": [
					{
						"text": "合作市",
						"id": "623001"
					},
					{
						"text": "临潭县",
						"id": "623021"
					},
					{
						"text": "卓尼县",
						"id": "623022"
					},
					{
						"text": "舟曲县",
						"id": "623023"
					},
					{
						"text": "迭部县",
						"id": "623024"
					},
					{
						"text": "玛曲县",
						"id": "623025"
					},
					{
						"text": "碌曲县",
						"id": "623026"
					},
					{
						"text": "夏河县",
						"id": "623027"
					}
				]
			}
		]
	},
	{
		"id": "630000",
		"text": "青海",
		"children": [
			{
				"text": "西宁市",
				"id": "630100",
				"children": [
					{
						"text": "城东区",
						"id": "630102"
					},
					{
						"text": "城中区",
						"id": "630103"
					},
					{
						"text": "城西区",
						"id": "630104"
					},
					{
						"text": "城北区",
						"id": "630105"
					},
					{
						"text": "大通县",
						"id": "630121"
					},
					{
						"text": "湟中县",
						"id": "630122"
					},
					{
						"text": "湟源县",
						"id": "630123"
					},
					{
						"text": "城南新区",
						"id": "630124"
					},
					{
						"text": "海湖新区",
						"id": "630125"
					},
					{
						"text": "多巴镇",
						"id": "630126"
					}
				]
			},
			{
				"text": "海东市",
				"id": "632100",
				"children": [
					{
						"text": "平安区",
						"id": "632121"
					},
					{
						"text": "民和县",
						"id": "632122"
					},
					{
						"text": "乐都区",
						"id": "632123"
					},
					{
						"text": "互助县",
						"id": "632126"
					},
					{
						"text": "化隆县",
						"id": "632127"
					},
					{
						"text": "循化县",
						"id": "632128"
					}
				]
			},
			{
				"text": "海北州",
				"id": "632200",
				"children": [
					{
						"text": "门源县",
						"id": "632221"
					},
					{
						"text": "祁连县",
						"id": "632222"
					},
					{
						"text": "海晏县",
						"id": "632223"
					},
					{
						"text": "刚察县",
						"id": "632224"
					}
				]
			},
			{
				"text": "黄南州",
				"id": "632300",
				"children": [
					{
						"text": "同仁县",
						"id": "632321"
					},
					{
						"text": "尖扎县",
						"id": "632322"
					},
					{
						"text": "泽库县",
						"id": "632323"
					},
					{
						"text": "河南县",
						"id": "632324"
					}
				]
			},
			{
				"text": "海南州",
				"id": "632500",
				"children": [
					{
						"text": "共和县",
						"id": "632521"
					},
					{
						"text": "同德县",
						"id": "632522"
					},
					{
						"text": "贵德县",
						"id": "632523"
					},
					{
						"text": "兴海县",
						"id": "632524"
					},
					{
						"text": "贵南县",
						"id": "632525"
					}
				]
			},
			{
				"text": "果洛州",
				"id": "632600",
				"children": [
					{
						"text": "玛沁县",
						"id": "632621"
					},
					{
						"text": "班玛县",
						"id": "632622"
					},
					{
						"text": "甘德县",
						"id": "632623"
					},
					{
						"text": "达日县",
						"id": "632624"
					},
					{
						"text": "久治县",
						"id": "632625"
					},
					{
						"text": "玛多县",
						"id": "632626"
					}
				]
			},
			{
				"text": "玉树州",
				"id": "632700",
				"children": [
					{
						"text": "玉树市",
						"id": "632721"
					},
					{
						"text": "杂多县",
						"id": "632722"
					},
					{
						"text": "称多县",
						"id": "632723"
					},
					{
						"text": "治多县",
						"id": "632724"
					},
					{
						"text": "囊谦县",
						"id": "632725"
					},
					{
						"text": "曲麻莱县",
						"id": "632726"
					}
				]
			},
			{
				"text": "海西州",
				"id": "632800",
				"children": [
					{
						"text": "德令哈市",
						"id": "632802"
					}
				]
			},
			{
				"text": "格尔木市",
				"id": "632900",
				"children": [
					{
						"text": "东城区",
						"id": "630127"
					},
					{
						"text": "西城区",
						"id": "630128"
					},
					{
						"text": "乌兰县",
						"id": "632821"
					},
					{
						"text": "都兰县",
						"id": "632822"
					},
					{
						"text": "察尔汗行委",
						"id": "630129"
					},
					{
						"text": "天峻县",
						"id": "632823"
					},
					{
						"text": "大柴旦行政区",
						"id": "632824"
					},
					{
						"text": "茫崖市",
						"id": "632825"
					}
				]
			}
		]
	},
	{
		"id": "640000",
		"text": "宁夏",
		"children": [
			{
				"text": "银川市",
				"id": "640100",
				"children": [
					{
						"text": "兴庆区",
						"id": "640104"
					},
					{
						"text": "西夏区",
						"id": "640105"
					},
					{
						"text": "金凤区",
						"id": "640106"
					},
					{
						"text": "永宁县",
						"id": "640121"
					},
					{
						"text": "贺兰县",
						"id": "640122"
					},
					{
						"text": "灵武市",
						"id": "640181"
					},
					{
						"text": "宁东镇",
						"id": "640123"
					}
				]
			},
			{
				"text": "石嘴山市",
				"id": "640200",
				"children": [
					{
						"text": "大武口区",
						"id": "640202"
					},
					{
						"text": "惠农区",
						"id": "640205"
					},
					{
						"text": "平罗县",
						"id": "640221"
					}
				]
			},
			{
				"text": "吴忠市",
				"id": "640300",
				"children": [
					{
						"text": "利通区",
						"id": "640302"
					},
					{
						"text": "红寺堡区",
						"id": "640303"
					},
					{
						"text": "盐池县",
						"id": "640323"
					},
					{
						"text": "同心县",
						"id": "640324"
					},
					{
						"text": "青铜峡市",
						"id": "640381"
					}
				]
			},
			{
				"text": "固原市",
				"id": "640400",
				"children": [
					{
						"text": "原州区",
						"id": "640402"
					},
					{
						"text": "西吉县",
						"id": "640422"
					},
					{
						"text": "隆德县",
						"id": "640423"
					},
					{
						"text": "泾源县",
						"id": "640424"
					},
					{
						"text": "彭阳县",
						"id": "640425"
					},
					{
						"text": "三营镇",
						"id": "640426"
					}
				]
			},
			{
				"text": "中卫市",
				"id": "640500",
				"children": [
					{
						"text": "沙坡头区",
						"id": "640502"
					},
					{
						"text": "中宁县",
						"id": "640521"
					},
					{
						"text": "海原县",
						"id": "640522"
					}
				]
			}
		]
	},
	{
		"id": "650000",
		"text": "新疆",
		"children": [
			{
				"text": "乌鲁木齐市",
				"id": "650100",
				"children": [
					{
						"text": "天山区",
						"id": "650102"
					},
					{
						"text": "沙依巴克区",
						"id": "650103"
					},
					{
						"text": "新市区",
						"id": "650104"
					},
					{
						"text": "水磨沟区",
						"id": "650105"
					},
					{
						"text": "头屯河区",
						"id": "650106"
					},
					{
						"text": "达坂城区",
						"id": "650107"
					},
					{
						"text": "米东区",
						"id": "650109"
					},
					{
						"text": "乌鲁木齐县",
						"id": "650121"
					},
					{
						"text": "农十二师",
						"id": "650111"
					}
				]
			},
			{
				"text": "克拉玛依市",
				"id": "650200",
				"children": [
					{
						"text": "克拉玛依区",
						"id": "650203"
					},
					{
						"text": "白碱滩区",
						"id": "650204"
					},
					{
						"text": "乌尔禾区",
						"id": "650205"
					}
				]
			},
			{
				"text": "吐鲁番地区",
				"id": "652100",
				"children": [
					{
						"text": "吐鲁番市",
						"id": "652101"
					},
					{
						"text": "鄯善县",
						"id": "652122"
					},
					{
						"text": "托克逊县",
						"id": "652123"
					},
					{
						"text": "吐哈石油",
						"id": "652124"
					}
				]
			},
			{
				"text": "哈密地区",
				"id": "652200",
				"children": [
					{
						"text": "哈密市",
						"id": "652201"
					},
					{
						"text": "巴里坤哈萨克自治县",
						"id": "652222"
					},
					{
						"text": "伊吾县",
						"id": "652223"
					},
					{
						"text": "三道岭",
						"id": "652224"
					}
				]
			},
			{
				"text": "昌吉回族自治州",
				"id": "652300",
				"children": [
					{
						"text": "昌吉市",
						"id": "652301"
					},
					{
						"text": "阜康市",
						"id": "652302"
					},
					{
						"text": "呼图壁县",
						"id": "652323"
					},
					{
						"text": "玛纳斯县",
						"id": "652324"
					},
					{
						"text": "奇台县",
						"id": "652325"
					},
					{
						"text": "吉木萨尔县",
						"id": "652327"
					},
					{
						"text": "木垒哈萨克自治县",
						"id": "652328"
					},
					{
						"text": "五家渠市",
						"id": "652329"
					},
					{
						"text": "芳草湖",
						"id": "652330"
					},
					{
						"text": "新湖",
						"id": "652331"
					}
				]
			},
			{
				"text": "博尔塔拉蒙古自治州",
				"id": "652700",
				"children": [
					{
						"text": "博乐市",
						"id": "652701"
					},
					{
						"text": "精河县",
						"id": "652722"
					},
					{
						"text": "温泉县",
						"id": "652723"
					},
					{
						"text": "阿拉山口市",
						"id": "652724"
					},
					{
						"text": "双河市",
						"id": "652725"
					}
				]
			},
			{
				"text": "巴音郭楞蒙古自治州",
				"id": "652800",
				"children": [
					{
						"text": "库尔勒市",
						"id": "652801"
					},
					{
						"text": "轮台县",
						"id": "652822"
					},
					{
						"text": "尉犁县",
						"id": "652823"
					},
					{
						"text": "若羌县",
						"id": "652824"
					},
					{
						"text": "且末县",
						"id": "652825"
					},
					{
						"text": "焉耆回族自治县",
						"id": "652826"
					},
					{
						"text": "和静县",
						"id": "652827"
					},
					{
						"text": "和硕县",
						"id": "652828"
					},
					{
						"text": "博湖县",
						"id": "652829"
					},
					{
						"text": "铁门关市",
						"id": "652830"
					}
				]
			},
			{
				"text": "阿克苏地区",
				"id": "652900",
				"children": [
					{
						"text": "阿克苏市",
						"id": "652901"
					},
					{
						"text": "温宿县",
						"id": "652922"
					},
					{
						"text": "库车县",
						"id": "652923"
					},
					{
						"text": "沙雅县",
						"id": "652924"
					},
					{
						"text": "新和县",
						"id": "652925"
					},
					{
						"text": "拜城县",
						"id": "652926"
					},
					{
						"text": "乌什县",
						"id": "652927"
					},
					{
						"text": "阿瓦提县",
						"id": "652928"
					},
					{
						"text": "柯坪县",
						"id": "652929"
					},
					{
						"text": "阿拉尔市",
						"id": "652930"
					}
				]
			},
			{
				"text": "克孜勒苏柯尔克孜自治州",
				"id": "653000",
				"children": [
					{
						"text": "阿图什市",
						"id": "653001"
					},
					{
						"text": "阿克陶县",
						"id": "653022"
					},
					{
						"text": "阿合奇县",
						"id": "653023"
					},
					{
						"text": "乌恰县",
						"id": "653024"
					}
				]
			},
			{
				"text": "喀什地区",
				"id": "653100",
				"children": [
					{
						"text": "喀什市",
						"id": "653101"
					},
					{
						"text": "疏附县",
						"id": "653121"
					},
					{
						"text": "疏勒县",
						"id": "653122"
					},
					{
						"text": "英吉沙县",
						"id": "653123"
					},
					{
						"text": "泽普县",
						"id": "653124"
					},
					{
						"text": "莎车县",
						"id": "653125"
					},
					{
						"text": "叶城县",
						"id": "653126"
					},
					{
						"text": "麦盖提县",
						"id": "653127"
					},
					{
						"text": "岳普湖县",
						"id": "653128"
					},
					{
						"text": "伽师县",
						"id": "653129"
					},
					{
						"text": "巴楚县",
						"id": "653130"
					},
					{
						"text": "塔什库尔干塔吉克自治县",
						"id": "653131"
					},
					{
						"text": "奎依巴格石油",
						"id": "653132"
					},
					{
						"text": "图木舒克市",
						"id": "653133"
					}
				]
			},
			{
				"text": "和田地区",
				"id": "653200",
				"children": [
					{
						"text": "和田市",
						"id": "653201"
					},
					{
						"text": "和田县",
						"id": "653221"
					},
					{
						"text": "墨玉县",
						"id": "653222"
					},
					{
						"text": "皮山县",
						"id": "653223"
					},
					{
						"text": "洛浦县",
						"id": "653224"
					},
					{
						"text": "策勒县",
						"id": "653225"
					},
					{
						"text": "于田县",
						"id": "653226"
					},
					{
						"text": "民丰县",
						"id": "653227"
					}
				]
			},
			{
				"text": "伊犁哈萨克自治州",
				"id": "654000",
				"children": [
					{
						"text": "伊宁市",
						"id": "654002"
					},
					{
						"text": "伊宁县",
						"id": "654021"
					},
					{
						"text": "察布查尔锡伯自治县",
						"id": "654022"
					},
					{
						"text": "霍城县",
						"id": "654023"
					},
					{
						"text": "巩留县",
						"id": "654024"
					},
					{
						"text": "新源县",
						"id": "654025"
					},
					{
						"text": "昭苏县",
						"id": "654026"
					},
					{
						"text": "特克斯县",
						"id": "654027"
					},
					{
						"text": "尼勒克县",
						"id": "654028"
					},
					{
						"text": "霍尔果斯市",
						"id": "654029"
					}
				]
			},
			{
				"text": "塔城地区",
				"id": "654200",
				"children": [
					{
						"text": "塔城市",
						"id": "654201"
					},
					{
						"text": "额敏县",
						"id": "654221"
					},
					{
						"text": "托里县",
						"id": "654224"
					},
					{
						"text": "裕民县",
						"id": "654225"
					},
					{
						"text": "和布克赛尔县",
						"id": "654226"
					},
					{
						"text": "沙湾县",
						"id": "654227"
					},
					{
						"text": "乌苏市",
						"id": "654228"
					},
					{
						"text": "石河子",
						"id": "654229"
					}
				]
			},
			{
				"text": "阿勒泰地区",
				"id": "654300",
				"children": [
					{
						"text": "阿勒泰市",
						"id": "654301"
					},
					{
						"text": "布尔津县",
						"id": "654321"
					},
					{
						"text": "富蕴县",
						"id": "654322"
					},
					{
						"text": "福海县",
						"id": "654323"
					},
					{
						"text": "哈巴河县",
						"id": "654324"
					},
					{
						"text": "青河县",
						"id": "654325"
					},
					{
						"text": "吉木乃县",
						"id": "654326"
					},
					{
						"text": "北屯市",
						"id": "654327"
					}
				]
			},
			{
				"text": "石河子市",
				"id": "659001",
				"children": [
					{
						"text": "石河子市",
						"id": "659001"
					},
					{
						"text": "莫索湾",
						"id": "659002"
					},
					{
						"text": "下野地",
						"id": "659003"
					}
				]
			},
			{
				"text": "奎屯",
				"id": "654400",
				"children": [
					{
						"text": "奎屯区",
						"id": "654401"
					},
					{
						"text": "团场",
						"id": "654402"
					},
					{
						"text": "独山子区",
						"id": "654404"
					}
				]
			}
		]
	}
]