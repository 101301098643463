<template>
  <div id="Form" ref="productform">
    <!--     <FirstScreenPrompt /> -->
    <van-form @submit="onSubmit">
      <!--       <div class="imgContainer">
        <img class="img" :src="productData.fullDescImgUrl"/>
      </div> -->
      <div class="title">入网信息<span style="font-size:13px">(根据国家实名制要求，请准确提供本人身份证信息)</span></div>
      <van-cell-group inset class="formContainer">
        <van-field placeholder="请输入您的姓名" v-model="formData.certName" name="certName" label="姓名"
          :rules="[{ required: true }]" />
        <van-field placeholder="请输入您的身份证号" v-model="formData.certId" name="certId" label="身份证"
          :rules="[{ required: true, pattern: /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/, message: '请输入正确的身份证号' }]" />
        <van-field label="联系方式" v-model="formData.linkPhone" name="linkPhone" placeholder="请输入您的电话号码"
          :rules="[{ required: true, pattern: /^([1]\d{10}|([\(（]?0[0-9]{2,3}[）\)]?[-]?)?([2-9][0-9]{6,7})+(\-[0-9]{1,4})?)$/, message: '请输入正确的电话号码' }]" />
        <!--        <VerificationCode v-if="operatorType == 3" @getCaptcha="getCaptcha" :linkPhone="formData.linkPhone"
                                  :certId="formData.certId"/>-->
      </van-cell-group>

      <div class="title">选择号码</div>
      <van-cell-group inset class="formContainer">
        <van-field label="选择手机号" placeholder="点击选择手机号" :rules="[{ required: true }]" :value="formData.newPhoneNum"
          @focus="noBomBox" @click="numberShow" is-link />
      </van-cell-group>

      <div class="title">收货信息</div>
      <van-cell-group inset class="formContainer">
        <van-field @click="showPicker" label="收货地址" placeholder="点击选择城市" :value="addressPickerShowValue"
          @focus="noBomBox" :rules="[{ required: true }]" is-link />
        <van-field v-model="formData.detailedAddress" label="详细地址" placeholder="街道/镇+村/小区/写字楼+门牌号"
          name="detailedAddress" :rules="[{ required: true }]" />
        <Readme :checkbox="checkbox" @handleCheckBoxStatus="handleCheckBoxStatus">
        </Readme>
      </van-cell-group>
      <!--      <UnicomDrag v-if="operatorType == 3" style="position: relative; width: 100%; display: flex; justify-content: center; margin-bottom: 20px"></UnicomDrag>-->
      <span style="display: flex; justify-content: center;">
        <van-button class="listenButton buttonAnimation" color="linear-gradient(to right, #0092FF, #00E8FF)" round
          type="primary" block :loading="isSubmitLoading" :disabled="isSubmitLoading" native-type="submit">免费领取 包邮到家
        </van-button>
      </span>
      <van-overlay class="overlay" :show="pickerShow || numberPickerShow" @click="show = false"
        @touchmove.native.stop.prevent />
    </van-form>
    <div class="numberPicker" v-show="numberPickerShow">
      <div v-if="isLoading" class="loadBackground">
        <van-loading type="spinner" color="#1989fa" />
      </div>
      <div class="topContainer">
        <van-icon name="close" class="cancel_icon" @click="cancelNumberShow" size="28px" />
      </div>
      <div class="phoneNumChooseContainer" ref="phoneNumChooseContainer">
        <div v-for="(item, index) in phoneNumList" class="singleNum" v-bind:class="{ active: false }"
          :key="item.phoneNumber" @click="choosePhoneNum(index)">
          <div class="number">{{ item.phoneNumber }}</div>
          <div class="fee">预存¥0,协议期0月</div>
        </div>
      </div>
      <div class="bottomButton">
        <van-button @click="getProductPhoneNum" style="width: 50%" type="default">换一批</van-button>
        <van-button style="width: 50%" @click="confirmPhoneNum" type="danger">确定</van-button>
      </div>
    </div>
    <van-picker ref="addressPicker" class="picker" @cancel="cancelPicker" @confirm="addressConfirm" round show-toolbar
      title="地区选择" :columns="provinceList" v-show="pickerShow" />

    <span style="display: flex; justify-content: center;">
      <van-button class="footButton buttonAnimation" v-show="isShowFootButton" round
        style="z-index: 100; font-size: 27px; font-weight: 600; height: 8vh" @click="routerForm" block
        color="linear-gradient(to right, #ff6034, #ee0a24)">免费领卡 包邮到家
      </van-button>
    </span>
  </div>
</template>

<script>
import Readme from '../../components/Readme'
/* import FirstScreenPrompt from './FirstScreenPrompt'; */
/* import VerificationCode from './VerificationCode';
import UnicomDrag from './UnicomDrag' */
import provinceListWithMoblie from "../../assets/data/provincewithMoblie.js";
import provinceListWithUnicom from "../../assets/data/provincewithUnicom.js";
import { saveOrder } from './unicomReq';
import { Notify } from 'vant';
import getBase64Params from '../../utils/getBase64Params.js';

export default {
  data() {
    return {
      isShowFootButton: false,
      operatorType: getBase64Params(this.$route.query.data).type,
      checkbox: false,
      pickerShow: false,
      numberPickerShow: false,
      isLoading: false,
      isSubmitLoading: false,
      productData: {},
      phoneNumList: [],
      addressPickerShowValue: "",
      choosePhoneNumIndex: "",
      formData: {
        certName: "",
        certId: "",
        newPhoneNum: "",
        linkPhone: "",
        detailedAddress: "",
        cityText: "",
        provinceText: "",
        regionText: '',
        captcha: '',
        userId: getBase64Params(this.$route.query.data).userId
      },
      provinceList: {
        1: provinceListWithMoblie,
        3: provinceListWithUnicom
      }[getBase64Params(this.$route.query.data).type],
    };
  },
  components: {
    /*     FirstScreenPrompt, */
    Readme,
    /* VerificationCode,
    UnicomDrag, */
    [Notify.Component.name]: Notify.Component,
  },
  created() {
    this.$axios
      .get("/api/product/personalProductInfo", {
        params: {
          userId: getBase64Params(this.$route.query.data).userId,
          productId: getBase64Params(this.$route.query.data).productId,
        },
      })
      .then((res) => {
        this.productData = res.data.data;
        console.log(this.productData)
      });
  },
  mounted() {
    var that = this
    this.intersectionObserver = new IntersectionObserver(
      function (entries) {
        if (entries[0].intersectionRatio <= 0) {
          that.isShowFootButton = true;
        } else {
          that.isShowFootButton = false;
        }
      });

    // 开始观察
    this.intersectionObserver.observe(
      document.querySelector('.listenButton')
    );
  },
  destroyed() {
    this.intersectionObserver.disconnect();
  },
  methods: {
    routerForm() {
      window.scrollTo({
        top: this.$refs.productform.offsetTop,
        behavior: "smooth"
      });
      /*  this.$router.push({
         name: "ProductForm",
         params: {
           productId: getBase64Params(this.$route.query.data).productId,
           type: getBase64Params(this.$route.query.data).type
         }
       }) */
    },
    handleCheckBoxStatus(bool) {
      this.checkbox = bool
    },
    /*    getCaptcha(val) {
          this.formData.captcha = val
        },*/
    addressConfirm(value) {
      this.pickerShow = false;
      const addressPickerData = this.$refs.addressPicker.getValues()
      this.addressPickerShowValue = `${value[0]} - ${value[1]} - ${value[2]}`;
      this.formData.provinceText = addressPickerData[0].text;
      this.formData.cityText = addressPickerData[1].text;
      this.formData.regionText = addressPickerData[2].text;
      this.formData.provinceCode = addressPickerData[0].id;
      this.formData.cityCode = addressPickerData[1].id;
      if (this.operatorType == 3) {
        this.formData.regionCode = addressPickerData[2].id;
      }
    },
    noBomBox() {
      document.activeElement.blur();
    },
    showPicker() {
      this.pickerShow = true;
    },
    cancelPicker() {
      this.pickerShow = false;
    },
    numberShow() {
      if (this.phoneNumList.length == 0) {
        this.getProductPhoneNum();
      }
      this.numberPickerShow = true;
    },
    cancelNumberShow() {
      this.numberPickerShow = false;
    },
    getProductPhoneNum() {
      this.choosePhoneNumIndex = "";
      this.isLoading = true;
      const reqUrl = {
        1: { method: 'get', url: '/api/cmcc/order/queryChooseNumberlist' },
        3: { method: 'post', url: '/api/cuccDynamci/getNumberData' }
      }[getBase64Params(this.$route.query.data).type]
      const params = {
        1: {
          params: {
            productCode: this.productData.productCode,
            productId: this.productData.productId,
          },
        },
        3: {
          productId: this.productData.productId
        }
      }[getBase64Params(this.$route.query.data).type]
      this.$axios[reqUrl.method](reqUrl.url, {
        ...params
      })
        .then((res) => {
          this.phoneNumList = res.data.data;
          this.isLoading = false;
        });
    },
    choosePhoneNum(index) {
      if (this.choosePhoneNumIndex !== "") {
        this.$refs.phoneNumChooseContainer.children[
          this.choosePhoneNumIndex
        ].className = "singleNum";
      }
      this.$refs.phoneNumChooseContainer.children[index].className =
        "singleNum active";
      this.choosePhoneNumIndex = index;
    },
    confirmPhoneNum() {
      this.formData.newPhoneNum = this.phoneNumList[this.choosePhoneNumIndex].phoneNumber;
      this.numberPickerShow = false;
    },
    onsubmitWithMoblie() {
      const {
        regionText,
        detailedAddress,
        cityText,
        certId,
        linkPhone,
        newPhoneNum,
        provinceText,
        certName,
        userId,
        provinceCode,
        cityCode
      } = this.formData
      const {
        productCode,
        productId,
        productName,
      } = this.productData
      return this.$axios.get(`/api/cmcc/order/checkAddress?provinceCode=${provinceCode}&address=${regionText + detailedAddress}&city=${cityText}&cityCode=${cityCode}&productCode=${productCode}&productId=${productId}&province=${provinceText}`).then((res) => {
        if (res.data.success === true) {
          this.$axios.post(`/api/cmcc/order/saveOrder?district=${regionText}&detailedAddress=${detailedAddress}&city=${cityText}&idCard=${certId}&linkPhone=${linkPhone}&orderPhone=${newPhoneNum}&productCode=${productCode}&productId=${productId}&productName=${productName}&province=${provinceText}&userName=${certName}&userId=${userId}`).then((res) => {
            this.isSubmitLoading = false
            if (res.data.success === true) {
              // eslint-disable-next-line no-undef
              // _baq.track("customer_effective", { assets_id: "1736782836613133" })
              this.$router.push({ path: "/submitSuccess" })
            } else {
              Notify(res.data.message);
            }
          })
        } else {
          Notify(res.data.message);
        }
      })
    },
    async onsubmitWithUnicom() {
      const self = this
      const {
        certName,
        certId,
        linkPhone,
        newPhoneNum,
        cityText,
        userId,
        detailedAddress,
        regionText,
        provinceText,
        provinceCode,
        cityCode,
        regionCode
      } = this.formData
      const {
        productId,
        productName,
      } = this.productData
      const saveOrderRes = await saveOrder({
        certName: certName,
        certNo: certId,
        contactNum: linkPhone,
        phoneNum: newPhoneNum,
        userId: userId,
        productId: productId,
        productName: productName,
        postAddr: detailedAddress,
        postCity: cityText,
        postDistrict: regionText,
        postProvince: provinceText,
        postProvinceCode: provinceCode,
        postCityCode: cityCode,
        postDistrictCode: regionCode,
        postName: certName,
      })
      if (saveOrderRes.data.success === true) {
        // eslint-disable-next-line no-undef
        // _baq.track("customer_effective", { assets_id: "1736782836613133" })
        self.$router.push({ path: "/submitSuccess" })
      } else {
        Notify(saveOrderRes.data.message);
      }
      return saveOrderRes.data
    },
    async onSubmit() {
      // eslint-disable-next-line no-undef
      _baq.track("form", { assets_id: "1736782836613133" })
      let userYear = parseInt(this.formData.certId.slice(6, 10), 10)
      if ((this.productData.minAgeLimit === 0 && this.productData.maxAgeLimit === 0) || (new Date().getFullYear() - userYear >= parseInt(this.productData.minAgeLimit, 10) && new Date().getFullYear() - userYear <= parseInt(this.productData.maxAgeLimit, 10))) {
        this.isSubmitLoading = true
        const submitEvent = { 1: this.onsubmitWithMoblie, 3: this.onsubmitWithUnicom }
        await submitEvent[getBase64Params(this.$route.query.data).type]()
        this.isSubmitLoading = false
      } else {
        Notify('客户年龄不符合办理要求');
        this.isSubmitLoading = false
      }
    }
  },
};
</script>

<style lang="less" scoped>
#Form {
  background: #fff;
  padding: 10px;
  box-shadow: 0 5px 10px 0 rgba(190, 221, 233, 0.3);
  border-radius: 16px;


  @keyframes scaleDraw {

    /*定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称*/
    0% {
      transform: scale(1);
      /*开始为原始大小*/
    }

    75% {
      transform: scale(1.1);
    }
  }

  .footButton {
    position: fixed;
    bottom: 10px;
    width: 90%;
  }

  .loadBackground {
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.6;
  }

  .picker {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 1000;
  }

  .overlay {
    z-index: 10;
  }

  .imgContainer {
    height: 400px;
    width: 100%;
    overflow: hidden;

    .img {
      width: 100%;
    }
  }

  .formContainer {
    margin: 10px 10px;
  }

  .buttonAnimation {
    animation: scaleDraw 1.5s ease-in-out infinite;
  }

  .listenButton {
    width: 90%;
    margin-bottom: 10px;
  }

  .title {
    color: #acacac;
    margin: 10px 0 0 15px;
    text-align: left;
    letter-spacing: 2px;
  }

  .numberPicker {
    overflow: hidden;
    height: 480px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background: white;
    z-index: 1000;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    transition: all 0.2s ease;
  }

  .cancel_icon {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .bottomButton {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .topContainer {
    position: relative;
    height: 10%;
  }

  .singleNum {
    width: 45%;
    height: 20%;
    border: solid 1px #d6d5d5;
    margin: 5px 0;
    border-radius: 5px;

    &.active {
      border: solid 1px #bb5151;
      background: rgb(247, 241, 242);
    }
  }

  .singleNum:active {
    width: 45%;
    height: 20%;
    border: solid 1px #bb5151;
    margin: 5px 0;
    background: rgb(247, 241, 242);
    border-radius: 5px;
  }

  .phoneNumChooseContainer {
    height: 300px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .number {
    text-align: center;
    padding: 5px;
    font-size: 18px;
    font-weight: 550;
  }

  .fee {
    text-align: center;
    color: #acacac;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: height 0.3s;
  }

  .fade-enter,
  .fade-leave-to

  /* .fade-leave-active, 2.1.8 版本以下 */
    {
    height: 0;
  }

  @media screen and (min-width: 992px) {
    .numberPicker {
      width: 500px;
    }

    .picker {
      width: 500px;
    }
  }
}
</style>
