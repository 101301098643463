<template>
    <div>
        <div class="background">
            <van-loading type="spinner" color="#1989fa" />
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    data() {
        return {}
    },
    methods: {},
    activated: {},
    deactivated: {}
}
</script>
<style lang="less" scoped>
.background {
    opacity: 0.7;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
