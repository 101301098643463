<template>
  <div id="app">
    <router-view />
    <div class="customerService">
      <svg style="height: 40px;">
        <use xlink:href="#icon-service" @click="customerServiceShow = true" />
      </svg>
      <div style="font-size: small;text-align: center;color:#acacac;">联系客服</div>
    </div>
    <van-overlay :show="customerServiceShow">
      <div class="wrapper" @click="customerServiceShow = false">
        <div class="block" style="padding: 5px;" @click.stop>
          <img class="customerServiceImg" v-if="photo" :src="photo" alt />
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
/* const {Base64} = require('js-base64'); */
import './icons/service.svg'
import getBase64Params from './utils/getBase64Params.js'
export default {
  name: 'App',
  data() {
    return {
      customerServiceShow: false,
      photo:false
    }
  },
   mounted(){
    this.$axios.get(`/api/user/serviceQrcodeImg?userId=${getBase64Params(this.$route.query.data).userId}`, {
    }).then((res) => {
        if(!res.data.data.fullServiceQrcodeImgUrl){
          this.photo=require('./img/customerService.png')
        }else{
        this.photo = res.data.data.fullServiceQrcodeImgUrl
        }
    })
   }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.customerService {
  width: 60px;
  height: 40px;
  flex-flow: wrap;
  border-radius: 50%;
  position: fixed;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 999;
}

div.van-overlay /deep/ {
  z-index: 1000;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 70vw;
  height: 70vw;
  background-color: #fff;
  border-radius: 10px;
}

.customerServiceImg {
  width: 90%;
  height: 90%;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (min-width: 992px) {
  #app {
    width: 500px;
    position: relative;
  }

  .customerService {
    right: calc((100vw - 500px) / 2);
  }

  .block {
    width: 400px;
    height: 400px;
    background-color: #fff;
  }

  body {
    display: flex;
    justify-content: center;
  }
}
</style>
