<template>
  <div id="About">
    <van-loading v-show="loading" style="position: absolute;top: 50%; left:50%; transform: translate(-50%, -50%);"
                 type="spinner" color="#1989fa"/>
    <div class="top">
      <svg class="icon">
        <use :xlink:href="icon"/>
      </svg>
    </div>
    <div>
      <div
          class="cardContainer"
          v-show="!loading"
          v-for="item in packageList"
          :key="item.productId"
      >
        <img class="card" :src="item.fullCrossImgUrl" @click="routeMore(item)"/>
      </div>
    </div>
    <svg class="phoneBackground">
      <use xlink:href="#icon-phone"/>
    </svg>
  </div>
</template>

<script>
import getBase64Params from '../utils/getBase64Params.js'
import "../icons/3.svg";
import "../icons/2.svg";
import "../icons/1.svg";
import "../icons/phone.svg";

export default {
  data() {
    return {
      packageList: [],
      loading: true
    }
  },
  created() {
    this.icon = "#icon-" + getBase64Params(this.$route.query.data).type
    this.$axios.get('/api/product/personalProductList', {
      params: {
        userId: getBase64Params(this.$route.query.data).userId,
        operators: getBase64Params(this.$route.query.data).type
      }
    }).then((res) => {
      this.packageList = res.data.data
      for (let item of this.packageList) {
        let image = new Image()
        image.src = item.fullImgUrl
      }
      this.loading = false
    })
  },
  methods: {
    routeMore(data) {
      this.$router.push({
        name: 'ProductDetail',
        params: {productId: data.productId, type: getBase64Params(this.$route.query.data).type}
      })
    }
  }
}
</script>

<style scoped>

#About {
  position: absolute;
  top: 0;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background: #e7f3f7;
  width: 100%;
  height: 100vh;
}

.cardContainer {
  width: 95vw;
  height: 47.5vw;
  margin: 4vh auto 0 auto;
  position: relative;
  border-radius: 10px;
  border: solid #acacac 1px;
  overflow: hidden;
  box-shadow: 1px 1px 30px -5px #96a4b4;
  z-index: 100;
}

.card {
  position: absolute;
  width: 100%;
  height: 100%;
}

.title {
  font-size: 5vw;
  font-weight: 550;
}

.phoneBackground {
  position: absolute;
  right: 0;
  bottom: 0;
  opacity: 0.5;
}

.top {
  position: relative;
  height: 15vh;
  box-shadow: 1px 10px 5px -10px #96a4b4;
}

.icon {
  height: 100%;
  width: 30%;
}

.getIcon {
  position: relative;
  z-index: 100;
}

@media screen and (min-width: 992px) {
  .cardContainer {
    width: 460px;
    height: 230px;
  }
}
</style>
