import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import './assets/ui/vant'
import axios from 'axios'
import { Base64 } from 'js-base64'
Vue.config.productionTip = false
Vue.prototype.$axios = axios

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    if (!to.query.data) {
        if (window.location.hash.replace('#/', '')) {
            var paramsBefore = window.location.hash.replace('#/', '').replace('data=', '').replace('%3D', '=')
            var params = paramsBefore.split("?")[1]
            var url = (new URL('http://test' + "?" + Base64.decode(params))).searchParams
            var obj = {}
            for (const [key, value] of url) {
                obj[key] = value
            }
            if (obj.userId) {
                // eslint-disable-next-line no-unused-vars
                var data = `userId=${obj.userId}`
                if (JSON.stringify(to.params) !== '{}') {
                    var string = ''
                    for (var key in to.params) {
                        string += "&" + key + "=" + to.params[key]
                    }
                    data += string
                }
                next({
                    path: to.path,
                    query: {
                        'data': Base64.encode(data)
                    }
                })
            }
        } else {
            next({
                path: to.path,
                query: {
                    'data': Base64.encode('userId=1')
                }
            })
        }
    } else {
        next()
    }
})

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')