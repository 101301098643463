import axios from 'axios'
export function checkIdentity(params) {
    return axios.post(`/api/cucc/checkIdentity`, params).then((res) => res)
}

export function getUnicomProkey(params) {
    return axios.get(`/api/cucc/getProkey`).then((getUnicomProkeyRes) => {
        return axios.post(`/api/cucc/appointNumber`, {
            proKey: getUnicomProkeyRes.data.data,
            ...params
        }).then((res) => res)
    })
}

export function checkCode(params) {
    return axios.post('/api/cucc/checkCode', params).then((res) => res)
}

export function saveOrder(params) {
    return axios.post(`/api/cuccDynamci/saveOrder`, params).then((res) => res)
}