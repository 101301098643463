import {
    Button,
    Field,
    Cell,
    CellGroup,
    Overlay,
    Picker,
    Icon,
    Form,
    Popup,
    Loading,
    Checkbox
}
from "vant";
import Vue from "vue";
Vue.use(Button)
Vue.use(Field)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Overlay)
Vue.use(Picker)
Vue.use(Icon)
Vue.use(Form)
Vue.use(Loading)
Vue.use(Checkbox)
Vue.use(Popup)